import React from 'react';
import {Create, SimpleForm} from 'react-admin';
import WorkflowDetails from "./WorkflowDetails";
import {useLocation} from "react-router";

const WorkflowCreate = (props) => {
    const location = useLocation();
    const { state } = location;
    console.log("state: ",state);
    return (
        <Create {...props} title={'workflow.create'} redirect="list">
            <SimpleForm defaultValues={state.state}>
                <WorkflowDetails />
            </SimpleForm>
        </Create>
    )
}

export default WorkflowCreate