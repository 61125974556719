import React from 'react';
import {Edit} from "react-admin";
import CustomerDetails from "./CustomerDetails";

const CustomerEdit = (props) => {
    return (
        <Edit {...props} title={'customer.edit'}>
            <CustomerDetails />
        </Edit>
    )

}
export default CustomerEdit