import * as React from "react";
import {
    TextField,
    List, ArrayField, SingleFieldList, ChipField, Datagrid, useRecordContext
} from 'react-admin';
import {Chip} from "@material-ui/core";

const SiteList = () => {

    /*const variant = (data) => {
        console.log(data)
        return data.active? 'filled' : 'outlined'
    }*/

    const CustomChipField = ({ source }) => {
        const record = useRecordContext();
        if (!record) return null;
        console.log(record)
        console.log(record.active)
        const variant = record.active ? 'filled' : 'outlined';
        console.log(variant)
        return <Chip label={record[source]} variant={variant} />;
    };

    return (
        <>
        <List>
            <Datagrid rowClick={"edit"} bulkActionButtons={false}>
                <TextField source="id" label={'site.id'}/>
                <TextField source="name" label="site.name"/>
                <TextField source="zipCode" label={'site.zipCode'}/>
                <TextField source="settlement" label="site.settlement"/>
                <TextField source="address" label="site.address"/>
                <ArrayField source="storages"  label="site.storage" >
                    <SingleFieldList>
                        <CustomChipField source="name" />
                    </SingleFieldList>
                </ArrayField>
            </Datagrid>
        </List>
        </>
    )
}

export default SiteList