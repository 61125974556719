import * as React from "react";
import { Admin, Resource, CustomRoutes } from 'react-admin';
import { Route } from "react-router-dom";
import i18nProvider from "./locales/locale";
//import dataProvider from "./components/dataProvider";
import authProvider from "./components/authProvider";
import LoginPage from "./components/LoginPage";
import Dashboard from "./pages/dashboard/Dashboard";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ChangePassword from "./pages/auth/ChangePassword";
import TvInfo from "./pages/tv/TvInfo";
import search from "./pages/search";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import WorkflowList from "./pages/workflow/WorkflowList";
import WorkflowEdit from "./pages/workflow/WorkflowEdit";
import WorkflowCreate from "./pages/workflow/WorkflowCreate";
import PartnerList from "./pages/partner/PartnerList";
import PartnerEdit from "./pages/partner/PartnerEdit";
import PartnerCreate from "./pages/partner/PartnerCreate";
import PersonIcon from "@mui/icons-material/Person";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import InputIcon from "@mui/icons-material/Input";
import OutputIcon from "@mui/icons-material/Output";
import IncomingList from "./pages/incoming/IncomingList";
import IncomingCreate from "./pages/incoming/IncomingCreate";
import IncomingShow from "./pages/incoming/IncomingShow";
import OutgoingList from "./pages/outgoing/OutgoingList";
import OutgoingCreate from "./pages/outgoing/OutgoingCreate";
import OutgoingShow from "./pages/outgoing/OutgoingShow";
import InventoryIcon from "@mui/icons-material/Inventory";
import InventoryList from "./pages/inventory/InventoryList";
import BadgeIcon from "@mui/icons-material/Badge";
import EmployeeList from "./pages/employee/EmployeeList";
import EmployeeEdit from "./pages/employee/EmployeeEdit";
import EmployeeCreate from "./pages/employee/EmployeeCreate";
import ProductIcon from "@mui/icons-material/DirectionsCar";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import SaleList from "./pages/sale/SaleList";
import SaleEdit from "./pages/sale/SaleEdit";
import SaleCreate from "./pages/sale/SaleCreate";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import SiteList from "./pages/site/SiteList";
import SiteEdit from "./pages/site/SiteEdit";
import SiteCreate from "./pages/site/SiteCreate";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import OrderList from "./pages/order/OrderList";
import OrderEdit from "./pages/order/OrderEdit";
import OrderCreate from "./pages/order/OrderCreate";
import ProductList from "./pages/product/ProductList";
import ProductEdit from "./pages/product/ProductEdit";
import ProductCreate from "./pages/product/ProductCreate";
import ProductShow from "./pages/product/ProductShow";
import ConstructionIcon from '@mui/icons-material/Construction';
import WorksheetList from "./pages/worksheet/WorksheetList";
import WorksheetEdit from "./pages/worksheet/WorksheetEdit";
import WorkflowShow from "./pages/workflow/WorkflowShow";
import OrderShow from "./pages/order/OrderShow";
import ShuffleIcon from '@mui/icons-material/Shuffle';
import ShuffleList from "./pages/shuffle/ShuffleList";
import ShuffleCreate from "./pages/shuffle/ShuffleCreate";
import ShuffleShow from "./pages/shuffle/ShuffleShow";
import customDataProvider from "./components/customDataprovider";
import CustomerList from "./pages/customer/CustomerList";
import CustomerEdit from "./pages/customer/CustomerEdit";
import CustomerCreate from "./pages/customer/CustomerCreate";
import StoreRequest from "./pages/storerequest";
import StoreRequestList from "./pages/storerequest/StoreRequestList";
import StoreRequestEdit from "./pages/storerequest/StoreRequestEdit";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CuriosityList from "./pages/curiosity/CuriosityList";
import CuriosityEdit from "./pages/curiosity/CuriosityEdit";
import CuriosityCreate from "./pages/curiosity/CuriosityCreate";
import CuriosityShow from "./pages/curiosity/CuriosityShow";
import Proposal from "./pages/proposal/Proposal"
import WebshopSearchList from "./pages/webshop-search/WebshopSearchList";
import QueryStatsIcon from '@mui/icons-material/QueryStats'

const App = () => {
    console.log('%c Hello World', 'color: orange; font-size: 2em;');

    return (
        <Admin dataProvider={customDataProvider}
               authProvider={authProvider}
               loginPage={LoginPage}
               i18nProvider={i18nProvider}
               dashboard={Dashboard}
               title='Glassmax Admin'
        >
            {permissions => (
                <>
                    <Resource name="search" options={{label: 'search.title'}} {...search} />
                    <Resource name="partner" options={{label: 'partner.title'}}
                              list={permissions === 'admin' || permissions === 'manager' ? PartnerList : null}
                              edit={permissions === 'admin' || permissions === 'manager' ? PartnerEdit : null}
                              create={permissions === 'admin' || permissions === 'manager' ? PartnerCreate : null}
                              icon={PersonIcon} />
                    <Resource name="product" options={{label: 'product.title'}}
                              list={permissions === 'admin' || permissions === 'manager' || permissions === 'storekeeper' ? ProductList : null}
                              edit={permissions === 'admin' ? ProductEdit : null}
                              create={permissions === 'admin' ? ProductCreate : null}
                              show={permissions === 'admin' ? ProductShow : null}
                              icon={ProductIcon}
                    />
                    <Resource name="site" options={{label: 'site.title'}}
                              list={permissions === 'admin' || permissions === 'storekeeper' ? SiteList : null}
                              edit={permissions === 'admin' ? SiteEdit : null}
                              //create={permissions === 'admin' ? SiteCreate : null}
                              icon={WarehouseIcon}
                    />
                    <Resource name="incoming" options={{label: 'incoming.title'}}
                              list={permissions === 'admin' || permissions === 'storekeeper' ? IncomingList : null}
                              //edit={permissions === 'admin' || permissions === 'storekeeper' ? IncomingEdit : null}
                              create={permissions === 'admin' || permissions === 'storekeeper' ? IncomingCreate : null}
                              show={permissions === 'admin' || permissions === 'storekeeper' ? IncomingShow : null}
                              icon={InputIcon}
                    />
                    <Resource name="outgoing" options={{label: 'outgoing.title'}}
                              list={permissions === 'admin' || permissions === 'manager'  || permissions === 'storekeeper' ? OutgoingList : null}
                              //edit={permissions === 'admin' || permissions === 'storekeeper' ? OutgoingEdit : null}
                              create={permissions === 'admin' || permissions === 'manager'  || permissions === 'storekeeper' ? OutgoingCreate : null}
                              show={permissions === 'admin' || permissions === 'manager'  || permissions === 'storekeeper' ? OutgoingShow : null}
                              icon={OutputIcon}
                    />
                    <Resource name="shuffle" options={{label: 'shuffle.title'}}
                              list={permissions === 'admin' || permissions === 'storekeeper' ? ShuffleList : null}
                        //edit={permissions === 'admin' || permissions === 'storekeeper' ? OutgoingEdit : null}
                              create={permissions === 'admin' || permissions === 'storekeeper' ? ShuffleCreate : null}
                              show={permissions === 'admin' || permissions === 'storekeeper' ? ShuffleShow : null}
                              icon={ShuffleIcon}
                    />
                    <Resource name="storerequest" options={{label: 'storeRequest.title'}}
                              list={permissions === 'admin' || permissions === 'manager' || permissions === 'storekeeper' ? StoreRequestList : null}
                              edit={permissions === 'admin' || permissions === 'manager' || permissions === 'storekeeper' ? StoreRequestEdit : null}
                              icon={OpenInNewIcon}
                    />
                    <Resource name="inventory" options={{label: 'inventory.title'}}
                              list={permissions === 'admin' || permissions === 'storekeeper' ? InventoryList : null}
                              icon={InventoryIcon}
                    />
                    <Resource name="b2ccuriosity" options={{label: 'curiosity.title'}}
                              list={permissions === 'admin' || permissions === 'manager' ? CuriosityList : null}
                              //edit={permissions === 'admin' || permissions === 'manager' ? CuriosityEdit : null}
                              //create={permissions === 'admin' || permissions === 'manager' ? CuriosityCreate : null}
                              show={permissions === 'admin' || permissions === 'manager' ? CuriosityShow : null}
                              icon={QuestionMarkIcon} />
                    <Resource name="customer" options={{label: 'customer.title'}}
                              list={permissions === 'admin' || permissions === 'manager' ? CustomerList : null}
                              edit={permissions === 'admin' || permissions === 'manager' ? CustomerEdit : null}
                              create={permissions === 'admin' || permissions === 'manager' ? CustomerCreate : null}
                              icon={PersonIcon} />
                    <Resource name="sale" options={{label: 'sale.title'}}
                              list={permissions === 'admin' || permissions === 'manager' ? SaleList : null}
                              edit={permissions === 'admin' || permissions === 'manager' ? SaleEdit : null}
                              create={permissions === 'admin' || permissions === 'manager' ? SaleCreate : null}
                              icon={PointOfSaleIcon}
                    />
                    <Resource name="order" options={{label: 'order.title'}}
                              list={permissions === 'admin' || permissions === 'manager' ? OrderList : null}
                              //edit={permissions === 'admin' || permissions === 'manager' ? OrderEdit : null}
                              //create={permissions === 'admin' || permissions === 'manager' ? OrderCreate : null}
                              show={permissions === 'admin' || permissions === 'manager' ? OrderShow : null}
                              icon={ShoppingCartIcon}
                    />
                    <Resource name="ws-search" options={{label: 'wsSearchLog.title'}}
                              list={permissions === 'admin' || permissions === 'manager' ? WebshopSearchList : null}
                              icon={QueryStatsIcon}
                    />
                    <Resource name="workflow" options={{label: 'workflow.title'}}
                              list={permissions === 'admin' || permissions === 'manager' || permissions === 'storekeeper' ? WorkflowList : null}
                              edit={permissions === 'admin' || permissions === 'manager' ? WorkflowEdit : null}
                              create={permissions === 'admin' || permissions === 'manager' ? WorkflowCreate : null}
                              show={permissions === 'admin' || permissions === 'manager' || permissions === 'storekeeper' ? WorkflowShow : null}
                              icon={AccountTreeIcon}
                    />
                    <Resource name="worksheet" options={{label: 'worksheet.title'}}
                              list={permissions === 'admin' || permissions === 'manager' || permissions === 'worker'? WorksheetList : null}
                              edit={permissions === 'admin' || permissions === 'worker' ? WorksheetEdit : null}
                              //create={permissions === 'admin' || permissions === 'worker' ? WorksheetCreate : null}
                              icon={ConstructionIcon}
                    />
                    <Resource name="employee" options={{label: 'employee.title'}}
                              list={permissions === 'admin' ? EmployeeList : null}
                              edit={permissions === 'admin' ? EmployeeEdit : null}
                              create={permissions === 'admin' ? EmployeeCreate : null}
                              icon={BadgeIcon}
                    />
                    {/*<Resource name="proposal" create={Proposal} hasList={false} />*/}
                    <CustomRoutes noLayout>
                        <Route path={"/tv-info"} element={<TvInfo/>}/>
                        <Route path={"/forgot-password"} element={<ForgotPassword/>} />
                        <Route path={"/change-password"} element={<ChangePassword/>} />
                    </CustomRoutes>
                    <CustomRoutes>
                        <Route path={"/proposal/:id"} element={<Proposal/>} />
                    </CustomRoutes>
                </>
            )}
        </Admin>
    );
}

export default App;
