import React from 'react';
import {
    TextField,
    List,
    DeleteButton,
    Datagrid,
    DateField, ReferenceField, TextInput
} from "react-admin";

const CustomerList = () => {

    const postFilters = [
        <TextInput source="name" label={'partner.name'} alwaysOn resettable />,
        <TextInput source="zipCode" label={'partner.zipCode'} alwaysOn resettable />,
        <TextInput source="settlement" label={'partner.settlement'} alwaysOn resettable />,
        <TextInput source="address" label={'partner.address'} alwaysOn resettable />,
        <TextInput source="taxNumber" label={'partner.taxNumber'} alwaysOn resettable />,
    ];

    return (
        <List filters={postFilters}>
            <Datagrid rowClick={"edit"} bulkActionButtons={false}>
                <TextField source="id" label={'customer.id'}/>
                <TextField source="name" label={'customer.name'}/>
                <TextField source="zipCode" label={'customer.zipCode'}/>
                <TextField source="settlement" label={'customer.settlement'}/>
                <TextField source="address" label={'customer.address'}/>
                <TextField source="taxNumber" label={'customer.taxNumber'}/>
                <TextField source="discount" label={'customer.discount'}/>
                <ReferenceField label={'customer.createdBy'} reference={"employee/getAll"} link={false} source={"createdBy"}>
                    <TextField source="name"/>
                </ReferenceField>
                <DateField source="createdAt" label={'customer.createdAt'} />
                <DeleteButton/>
            </Datagrid>
        </List>

    )
}

export default CustomerList