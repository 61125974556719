import React from 'react';
import {
    TextField,
    List,
    ListBase,
    Datagrid,
    ReferenceField,
    DateField, FunctionField, useTranslate, SelectField
} from "react-admin";
import {Typography} from "@mui/material";

const WorksheetList = () => {
    const translate = useTranslate()

    const postRowStyle = (record, index) => ({
        backgroundColor : record.status === 'NEW' ? '#fff' : record.status === 'PROCESS' ? '#ffd' : record.status === 'DONE' ? '#ddf' : record.status === 'PAID' ? '#dfd' : '#ddd'
    });


    const choices = [
        { id: 'R', name: 'workflow.category.replacement' },
        { id: 'F', name: 'workflow.category.fix' },
        { id: 'G', name: 'workflow.category.gluing'},
        { id: 'T', name: 'workflow.category.takeaway'},
        { id: 'D', name: 'workflow.category.delivery'},
    ];

    const categoryRender = (data) => {
        return translate(choices.find(choice => choice.id === data.category).name)
    }

    const bookingRender = () => (
        <span>
            <DateField source="bookingDate" />{' '}
            <TextField source="bookingTime" />
        </span>
    );


    const status = [
        { id: 'NEW', name: 'Új' },
        { id: 'PROCESS', name: 'Folyamatban' },
        { id: 'DONE', name: 'Elkészült'},
        { id: 'PAID', name: 'Fizetve'},
        { id: 'CLOSE', name: 'Lezárva'}, //refactor -> CLOSED
    ];

    return (<>
            <Typography variant="h5" component="h5">
                Folyamatban
            </Typography>
        <List storeKey="newandprocess" filter={{ status: ["NEW", "PROCESS", "DONE"] }}>
            <Datagrid rowClick={"edit"} bulkActionButtons={false} rowStyle={postRowStyle}>
                <SelectField source="status" label='Státusz' choices={status} />
                <TextField source="id" label={'product.id'}/>
                <FunctionField source="bookingDate" label="Időpont" render={bookingRender}/>
                <FunctionField source="category" label="workflow.categoryName" render={categoryRender}/>
                <TextField source="name" label={'product.name'}/>
                <TextField source="plateNumber" label="Rendszám"/>
                <TextField source="vin" label="Alvázszám"/>
                <TextField source="phone" label="Telefon"/>
                <TextField source="email" label="Email"/>
                <TextField source="euroCode" label="Eurokód"/>
                <TextField source="vehicleManufacturer" label={'product.vehicleManufacturer'}/>
                <TextField source="vehicleModel" label={'product.vehicleModel'}/>
                <TextField source="comment" label="Megjegyzés"/>
                <TextField source="odometer" label="KM óra állás"/>
                <TextField source="damage" label="Sérülés"/>
            </Datagrid>
        </List>

            <Typography variant="h5" component="h5">
                Fizetve
            </Typography>
    <ListBase storeKey="paidandclosed" filter={{ status: ["PAID", "CLOSE"] }}>
        <Datagrid rowClick={"edit"} bulkActionButtons={false} rowStyle={postRowStyle}>
            <SelectField source="status" label='Státusz' choices={status} />
            <TextField source="id" label={'product.id'}/>
            <DateField source="bookingDate" label="Időpont"/>
            <TextField source="name" label={'product.name'}/>
            <TextField source="plateNumber" label="Rendszám"/>
            <TextField source="vin" label="Alvázszám"/>
            <TextField source="phone" label="Telefon"/>
            <TextField source="email" label="Email"/>
            <TextField source="euroCode" label="Eurokód"/>
            <TextField source="vehicleManufacturer" label={'product.vehicleManufacturer'}/>
            <TextField source="vehicleModel" label={'product.vehicleModel'}/>
            <TextField source="comment" label="Megjegyzés"/>
            <TextField source="odometer" label="KM óra állás"/>
            <TextField source="damage" label="Sérülés"/>
        </Datagrid>
    </ListBase>
        </>
    )
}

export default WorksheetList