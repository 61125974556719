import React from 'react';
import {
    TextInput,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    FormDataConsumer,
    SimpleFormIterator,
    ArrayInput,
    AutocompleteInput,
    required, BooleanInput
} from "react-admin";
import {Box, Divider, Grid, Stack, Typography} from "@mui/material";

const SiteDetails = () => {
    return (
        <SimpleForm>
            <Stack direction="column">
            <Box component="div">
                <Typography variant="h5">Telephely</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <BooleanInput source="active" label={"site.active"} required fullWidth/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput source="name" label={"site.name"} required fullWidth/>
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput source="zipCode" label={"site.zipCode"} required fullWidth/>
                    </Grid>
                    <Grid item xs={8}>
                        <TextInput source="settlement" label={"site.settlement"} required fullWidth/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput source="address" label={"site.address"} required fullWidth/>
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <Box component="div">
                <Typography variant="h5">Raktárak</Typography>
                <ArrayInput source={"storages"} label="">
                    <SimpleFormIterator inline disableReordering disableClear disableRemove fullWidth>
                        <BooleanInput source={"active"} label={"site.active"}/>
                        <TextInput source={"name"} required={true} label={"site.name"}/>
                    </SimpleFormIterator>
                </ArrayInput>
            </Box>
            </Stack>
        </SimpleForm>
    )
}

export default SiteDetails