import englishMessages from 'ra-language-english';
import {mergeDeep} from "../tools/mergeDeep";

const data = {
    common: {
        columns: "Columns",
        columnSelected: "column selected"
    },
    partner : {
        title: "Partner",
        id: "ID",
        name: "Name",
        create: "Add new partner",
        zipCode: "Zip code",
        settlement: "Settlement",
        address: "Address",
        taxNumber: "Tax number",
        phone: "Phone",
        email: "Email",
        discount: "Discount %",
        registeredBy: "Registered by",
        webshopAccess: "Webshop access",
        createdAt: "Created",

        productUnits: "Product units",
        quantity: "Quantity",
        unit: "Unit",
        defaultPrice: "Default gross price",
        greaterThanZero: "Value must be greater than 0"
    },
    product : {
        title: "Products",
        id: "ID",
        name: "Name",
        create: "Add new product",
        mainCategory: "Main Category",
        subCategory: "Subcategory",
        vatGroup: "VAT group",
        price: "Price",
        productUnits: "Product units",
        quantity: "Quantity",
        unit: "Unit",
        defaultPrice: "Default gross price",
        greaterThanZero: "Value must be greater than 0",
        extra: "Extra",
        vat: "Vat",
        description: "Description"
    },
    site : {
        title: "Site",
        id: "ID",
        name: "Name",
        create: "New site",
        edit: "Edit site",
        zipCode: "Zip code",
        settlement: "Settlement",
        address: "Address",
        storage: "Storage"
    },


    priceListTemplate : {
        title: "Pricelist template",
        id: "ID",
        name: "Unique name for the pirce list template",
        create: "Create new price list template",
        generalData: "General data",
        managingProducts: "Adding / removing products",
        changePrices: "Change prices",
        addProductsToPriceList: "Add products to pricelist",
        noProductInPriceList: "Currently there aren't any products in the pricelist",
        edit: "Edit",
        saveAndActivate : "Save and activate",
        saveAsDraft : "Save as draft",
        successfulSave: "Saved Successfully",
        draft: "Draft",
        activated: "Activated",
        uniqueNameRequired: "Unique name required",
        state: "State",
        price: "Price",
        modify: "Edit",
        cancel: "Cancel"
    },
    businessUnits : {
        title: "BusinessUnits",
        id: "ID",
        name: "Name",
        create: "Create a new business unit",
        publicDomainName: "Public domain name",
        publicDomainType: "Public domain type",
        number: "Number",
        settlement: "Settlement",
        zipCode: "Zip Code",
        address: "Address"
    },
    technicalUsers : {
        title: "TUsers",
        id: "ID",
        name: "Name",
        create: "Add a new technical user",
        pinCode: "Pin code",
        pinCodeCreate: "Pin code (4 digits)",
        pinCodeAgain: "Pin code again (4 digits)",
        technicalUserData: "Technical user info",
        technicalUserRole: "User's roles",
        posSets: "Groups for the role",
        resetPIN: "Reset PIN",
        userTypeFilter: "User type filter",
        technical: "Technical user",
        registered: "Registered user",
        validation :{
            pinDontMatch: "Pin Codes don't match"
        }
    },
    search : {
        title: "Search glass",
        id: "ID",
        euroCode: "Eurokód",
        brand: "Jármű gyártó",
        model: "Model",
        endYear: "Year to",
        startYear: "Year from",
        description: "Description"
    },
    groups: {
        title: "Groups",
        cantDrag: "Can't be dragged to an element with a lower hierarchy",
        editNode: "Edit Group",
        addChild: "Add child",
        name: "Name",
        type: "Group type",
        pos: "POS",
        bar: "Bar",
        area: "Area",
        businessUnit: "BusinessUnit",
        editSuccessful : "Group has been successfully updated",
        deleteSuccessful : "Item has been deleted",
        poss: "POS-s",
        deleteUnsuccessful: "Group can't be deleted until it has a POS as a child",
        edit: "Click on the group to edit",
    },
    activePosSets: {
        title: "Active Business Unit",
        posSets: "Business Units",
        change: "Changing Active Business Units"
    },
    priceListToPosSets: {
        title: "Activate PriceList",
        priceLists: "Price Lists",
        priceList: "Price List"
    },
    device: {
        title: "Devices",
        posId: "POS ID",
        posName: "POS Name",
        deviceId: "Device ID",
        deviceName: "Device Name",
        remove: "Remove from POS"
    },
    pos: {
        title: "Create POS",
        add: "Create a POS",
        name: "POS name",
        edit: "Edit",
        cancel: "Cancel"
    },
    store: {
        title: "Store",
        add: "Új POS felvétele",
        name: "POS név",
        edit: "Módosítás",
        cancel: "Mégse"
    },
    sale: {
        title: "Sale",
        add: "Új POS felvétele",
        name: "POS név",
        edit: "Módosítás",
        cancel: "Mégse"
    },
    order: {
        title: "Order",
        create: "New Order",
        add: "Új POS felvétele",
        name: "POS név",
        edit: "Edit",
        cancel: "Cancel",
        qty: "Quantity",
        productName: "Product name",
        price: "Price",
        state: "State",
        createdAt: "Order date",
        addItemToOrder: "Add item to order"
    },
    Workflow: {
        title: "Workflow",
        add: "Új POS felvétele",
        name: "POS név",
        edit: "Módosítás",
        cancel: "Mégse"
    },
    setting: {
        title: "Settings",
        add: "Új POS felvétele",
        name: "POS név",
        edit: "Módosítás",
        cancel: "Mégse"
    },
    employee: {
        title: "Employees",
        create: "Add a new employee",
        add: "New employee",
        id: "ID",
        uuid: "UUID",
        name: "Employee name",
        email: "Employee email",
        emailVerified: "Email verified",
        permission: "Permission",
        edit: "Edit",
        cancel: "Cancel"
    }
}

const signUp  = {
    signUp : {
        login: "Login",
        forgotPassword: "Forgot password",
        changePassword: "Change password",
        registration: "Sign up",
        verification: "Verification",
        email: "Email",
        password: "Password",
        oldPassword: "Old password",
        newPassword: "New password",
        confirmPassword: "Confirm new password",
        verificationCodeRequest: "Verification code request",
        passwordChanged: "Password changed successful",
        save: "Save",
        givenName: "Given name",
        familyName: "Family name",
        phone: "Phone number",
        pin: "PIN",
        phoneHint: "Format: +xxxxxxxxxxx e.g.:+36201234567",
        pinHint: "It can only contain 4 digits",
        passwordHint: "Must contain at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character (^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ ` + = )",
        verificationCode: "Verification code",
        verificationCodeSent: "Verification code sent. You can find the code in your email account.",
        signIn: "Login",
        signUp: "Sign up",
        notAuthorizedException: "Incorrect username or password.",
        passwordEmpty: "The password cannot be empty.",
        passwordPolicy: "The password is incorrect. Must be at least 8 long and contain at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character(^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ ` + = ) ",
        accountExist: "There is already a user with this email address.",
        givenNameEmpty: "The given name cannot be empty.",
        familyNameEmpty: "The family name cannot be empty.",
        phoneNotValid: "Phone number format is incorrect. Correct format: +36xxxxxxxxx",
        pinEmpty: "PIN format is not correct. You can only enter numbers.",
        invalidVerificationCode: "Invalid verification code, please try again.",
        expiredVerificationCode: "Expired verification code, please try again.",
        expiredCodeException: "Expired verification code, please try again.",
        invalidCode: "Invalid code provided, please request a code again.",
        limitExceededException: "Attempt limit exceeded, please try after some time.",
        successfulRegistration: "Successful registration. You will find a verification code in your email account.",
        successfulVerification: "Verification successful. You can now log in to the site."
    }
}

const engMessages = mergeDeep(englishMessages, data, signUp);


export default engMessages;