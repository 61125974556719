import React from 'react';
import {Create, SimpleForm} from 'react-admin';
import ProductDetails from "./ProductDetails";

const ProductCreate = (props) => {
    return (
        <Create {...props} title={'product.create'}>
            <SimpleForm>
                <ProductDetails />
            </SimpleForm>
        </Create>
    )
}

export default ProductCreate