import * as React from "react";
import { useState } from "react";
import {useLogin, useNotify, Form, useTranslate, useRedirect} from "react-admin";
import { ThemeProvider } from "@material-ui/styles";
import {Button, Grid, TextField} from "@mui/material";
import {createTheme, makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    },
    form: {
        padding: theme.spacing(4)
    }
}));

const myTheme = createTheme({
    palette: {
        primary: {
            main: '#345435',
        },
        secondary: {
            main: '#f50057',
        },
    },
});


const LoginPage = ({ theme }) => {
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const login = useLogin();
    const notify = useNotify();
    const translate = useTranslate()
    const redirect = useRedirect()
    const submit = e => {
        //e.preventDefault();
        // will call authProvider.login({ email, password })
        login({ email, password }).catch(err => {
            console.log(err);
            notify('signUp.notAuthorizedException', { type: 'error' });
        });
    };

    const forgotPassword = e  => {
        redirect('/forgot-password');

    };

    return (
        <ThemeProvider theme={myTheme}>
            <div className={classes.root}>
            <Form className={classes.form} onSubmit={submit}>
                <h2>{translate('signUp.login')}</h2>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField required id="email" value={email} label={translate('signUp.email')} variant="filled" type="email"  onChange={e => setEmail(e.target.value)} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField required id="password" label={translate('signUp.password')} variant="filled" type="password" value={password} onChange={e => setPassword(e.target.value)} fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained" type="submit" color="primary">{translate('signUp.signIn')}</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="text" onClick={forgotPassword} color="secondary">{translate('signUp.forgotPassword')}</Button>
                    </Grid>
                </Grid>
            </Form>
            </div>
        </ThemeProvider>
    );
};

export default LoginPage;