import React, {useState} from 'react';
import {
    TextField,
    List,
    Datagrid,
    ArrayField,
    SingleFieldList,
    ChipField,
    ReferenceField,
    Pagination,
    ReferenceInput,
    SelectInput,
    TextInput,
    usePermissions,
    ShowButton,
    useNotify,
    useDataProvider,
    useRecordContext, FunctionField
} from "react-admin";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {Button} from "@mui/material";
import {Tooltip} from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";


const ProductList = () => {

    const { permissions } = usePermissions();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [file, setFile] = useState(undefined)
    const [isLoading, setLoading] = useState(false)

    const postFilters = [
        <TextInput source="productName" label={'product.name'} alwaysOn resettable />,
        <TextInput source="barCode" label={'product.barCode'} alwaysOn resettable />,
        <TextInput source="euroCode" label={'product.euroCode'} alwaysOn resettable />,
        <ReferenceInput source="glassManufacturerId" reference="product/glassManufacturer" alwaysOn perPage={100}>
            <SelectInput label={'product.glassManufacturer'} optionText="name" resettable />
        </ReferenceInput>,
        <TextInput source="vehicleManufacturerName" label={'product.vehicleManufacturer'} alwaysOn resettable />,
        <TextInput source="vehicleModelName" label={'product.vehicleModel'} alwaysOn resettable />,
    ];

    const renderEuroCode = (record)  => {
        if (record.euroCode == null) {
            return null
        }
        if (record.note == null) {
            return (<>
                <span style={{whiteSpace: "nowrap"}}>
                    <Tooltip title="Vágólapra másolás">
                        <ContentCopyIcon fontSize="'inherit'" style={{ cursor: 'pointer' }} onClick={() => {navigator.clipboard.writeText(record.euroCode)}}/>
                    </Tooltip>&nbsp;<TextField source="euroCode"/>
                </span></>);
        } else {
            return (<>
                <span style={{whiteSpace: "nowrap"}}>
                    <Tooltip title="Vágólapra másolás">
                        <ContentCopyIcon fontSize="'inherit'" style={{ cursor: 'pointer' }} onClick={() => {navigator.clipboard.writeText(record.euroCode)}}/>
                    </Tooltip>&nbsp;
                    <TextField source="euroCode"/>&nbsp;
                    <Tooltip title={record.note}>
                        <CommentOutlinedIcon fontSize="'inherit'" style={{ cursor: 'pointer' }}/>
                    </Tooltip>
                </span></>);
        }

    }

    function DownloadField(props) {
        const dataProvider = useDataProvider();
        const record = useRecordContext();
        const notify = useNotify();
        const [file, setFile] = useState(undefined)
        const [isLoading, setLoading] = useState(false)
        const download = (event, record) => {
            event.stopPropagation();
            event.preventDefault();
            console.log("product_id", record.id)
            dataProvider.get(`product/label/${record.id}`)
                .then(response => {
                    console.log("response: ", response)
                    const contentType = "application/pdf"
                    const base64Data = response.data.file
                    const fileName = record.euroCode+".pdf";
                    const linkSource = `data:${contentType};base64,${base64Data}`
                    setFile({file:linkSource, fileName: fileName})
                    setLoading(false)
                    let link = document.createElement('a');
                    link.href = linkSource;
                    link.download = fileName;
                    link.click();
                })
                .catch(reason => {
                    console.log(reason)
                    notify("businessUnits.csrGenerateError", {type: "error"})
                    setLoading(false)
                })
        }
        return (
            <Button startIcon={<FileDownloadIcon/>} onClick={(event) => download(event, record)}>Cimke</Button>
        );
    }

    return (
        <List pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />} perPage={100} filters={postFilters}>
            <Datagrid rowClick={"edit"} bulkActionButtons={false}>
                <TextField source="id" label={'product.id'}/>
                <TextField source="name" label={'product.name'}/>
                <TextField source="barcode" label={'product.barCode'}/>
                <FunctionField source="euroCode" label={'product.euroCode'} render={renderEuroCode}/>
                <ReferenceField label={'product.glassManufacturer'}  reference={"product/glassManufacturer"} link={false} source={"glassManufacturerId"}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label={'product.vehicleManufacturer'}  reference={"product/vehicleManufacturer"} link={false} source={"vehicleManufacturerId"}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label={'product.vehicleModel'}  reference={"product/vehicleModel"} link={false} source={"vehicleModelId"}>
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="yearFrom" label={'product.yearFrom'}/>
                <TextField source="yearTo" label={'product.yearTo'}/>
                {permissions === 'admin' && <TextField source="manufacturerPrice" label={'product.manufacturerPrice'}/>}
                <TextField source="wholesalePrice" label={'product.wholesalePrice'}/>
                <TextField source="price" label={'product.price'}/>
                <TextField source="discountPrice" label={'product.discountPrice'}/>
                <TextField source="wholesaleDiscountPrice" label={'product.wholesaleDiscountPrice'}/>
                <ArrayField source={"unit"} label={'product.extra'}>
                    <SingleFieldList linkType={false}>
                        <ChipField source={"unit"}/>
                    </SingleFieldList>
                </ArrayField>
                <ShowButton/>
                <DownloadField/>
            </Datagrid>
        </List>

    )
}

export default ProductList