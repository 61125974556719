import React from 'react';
import {
    TextField,
    List,
    DateField,
    FunctionField,
    ReferenceField,
    SimpleShowLayout,
    Show,
    Pagination,
    Datagrid as ReactAdminDataGrid,
    useTranslate, useRecordContext
} from "react-admin";
import {useParams} from "react-router";

const PostTitle = () => {
    const record = useRecordContext();
    // the record can be empty while loading
    if (!record) return null;
    return <span>Raktárközi mozgatás #{record.id}</span>;
};

const ShuffleShow = () => {
    const { id } = useParams(); // this component is rendered in the /books/:id path
    const translate = useTranslate()

    return (
        <Show>
            <SimpleShowLayout>
                <TextField source="note" label={'outgoing.note'}/>
                <ReferenceField label={'site.title'}  reference={"site"} link={false} source={"siteId"}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label={'store.title'}  reference={"storage"} link={false} source={"storeId"}>
                    <TextField source="name" />
                </ReferenceField>
                <DateField source="incomingDate" label="outgoing.incomingDate"/>
                <DateField source="orderDate" label="outgoing.orderDate"/>
                <DateField source="createdAt" label="outgoing.createdAt" showTime/>
                <ReferenceField label={'outgoing.createdBy'} reference={"employee/getAll"} link={false} source={"createdBy"}>
                    <TextField source="name"/>
                </ReferenceField>

                <List perPage={200} pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />} resource={"shuffle/product/"+id} actions={false} title={PostTitle}>
                    <ReactAdminDataGrid optimized={true} bulkActionButtons={false} >
                        <TextField source="id" label={'outgoing.id'} visibility={false}/>
                        <TextField source="name" label={'order.productName'}/>
                        <TextField source="euroCode" label={'product.euroCode'}/>
                        <TextField source="qty" label={'outgoing.qty'}/>
                        <TextField source="stand" label={'outgoing.stand'}/>
                        <TextField source="level" label={'outgoing.level'}/>
                        <TextField source="shelf" label={'outgoing.shelf'}/>
                    </ReactAdminDataGrid>
                </List>
            </SimpleShowLayout>
        </Show>
    );
}

export default ShuffleShow