import {Datagrid, List, TextField, FunctionField, Title, usePermissions, SelectField} from "react-admin";
import Paper from "@mui/material/Paper";
import {Typography} from "@mui/material";
import React from "react";

const Curiosity = () => {
    const { permissions } = usePermissions();

    const postRowStyle = (record, index) => ({
        backgroundColor : record.state === 'OFFER_SENT' ? '#eee' : 'white'
    });

    return (
        permissions === 'admin' &&
        <>
            <Title title=""/>
            <Paper key="1" elevation={6}>
                <Typography variant="h5" component="h5" sx={{textAlign: 'center'}}>Érdeklődők</Typography>
                <List resource='dashboard/curiosity' actions={false} title=" " pagination={false}>
                    <Datagrid
                        bulkActionButtons={false} rowStyle={postRowStyle}>
                        <TextField source="customerName" label={'curiosity.name'}/>
                        <SelectField source="size" choices={[
                            { id: 'BIG', name: 'Csere' },
                            { id: 'SMALL', name: 'Javítás' },
                        ]} label={'curiosity.service'} />
                        <TextField source="vehicleManufacturer" label={'curiosity.vehicleManufacturer'}/>
                        <TextField source="vehicleModel" label={'curiosity.vehicleModel'}/>

                    </Datagrid>
                </List>
            </Paper>
        </>
    )

}
export default Curiosity