import React from 'react';
import {Create} from 'react-admin';
import PartnerDetails from "./PartnerDetails";

const PartnerCreate = (props) => {
    return (
        <Create {...props} title={'partner.create'}>
            <PartnerDetails />
        </Create>
    )
}

export default PartnerCreate