import React, {useEffect, useState}  from 'react';
import {
    TextInput,
    FormDataConsumer,
    SelectInput,
    ImageInput,
    ImageField,
    DateField,
    TextField,
    useUnselectAll,
    SelectField,
    ReferenceInput,
    useRefresh, useRecordContext, FunctionField
} from "react-admin";
import {Button, Dialog, DialogContent, DialogTitle, Typography} from "@mui/material";
import {useFormContext, useFormState} from "react-hook-form";
import EditServiceDataGrid from "../../components/EditServiceDataGrid";
import {ProductServiceStoreList} from "../../components/ProductServiceStoreList";
import {UploadImageToS3} from "../../components/UploadImageToS3";


const WorksheetDetails = (formData) => {
    const record = useRecordContext();
    const unselectAll = useUnselectAll("product/storageId/"+5);
    const { setValue } = useFormContext();
    const refresh = useRefresh()
    const NoProducts = () => <Typography>Még nincs termék a listán</Typography>;
    const [open, setOpen] = useState(false);
    const openAddDialog = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false)
        unselectAll()
    }
    const formState = useFormState();
    const products = (formData.formData?.products) ? formData.formData.products : []
    const [filteredIds, setFilteredIds] = useState([])

    useEffect(() => {
        if(formData.formData?.products) {
            setFilteredIds(formData.formData.products.map(product => product.id))
        }
    }, [formData.products]);

    const bookingRender = () => (
        <span>
            <DateField source="bookingDate" />{' '}
            <TextField source="bookingTime" />
        </span>
    );


    const ListItems = () => {
        console.log("listitem", formData?.products)
        if(products && products.length > 0) {
            return (
                <EditServiceDataGrid rows={products} />
            )
        }else{
            return <div> Jelenleg nincs egy termék se a listában </div>
        }
    }

    const handleChange = (event, newValue) => {
        refresh()
    };

    const handleOdometer = event => {
        /*console.log('formdata', formData.category)
        if((formData.category === 'R' || formData.category === 'F') && event.target.value.length > 0){
            setValue('status', 'PROCESS')
        }*/
    }

    return (
            <>
            <FunctionField source="bookingDate" label="Időpont" render={bookingRender} disabled fullWidth />
            <TextInput source="plateNumber" label="Rendszám" inputProps={{maxLength: 255}} disabled fullWidth />
            <TextInput source="vin" label="Alvázszám" inputProps={{maxLength: 255}} disabled fullWidth />
            <TextInput source="euroCode" label="Eurokód"  inputProps={{maxLength: 255}} disabled style = {{width: 400}}/>
            <TextInput source="vehicleManufacturer" label={'product.vehicleManufacturer'} disabled fullWidth/>
            <TextInput source="vehicleModel" label={'product.vehicleModel'} disabled fullWidth/>
            <TextInput source="vehicleYear" label={'Évjárat'} disabled fullWidth/>
            <TextInput source="comment" label="Megjegyzés" disabled={record.status === 'PAID' || record.status === 'CLOSE'} multiline inputProps={{maxLength: 255}} fullWidth/>
            <TextInput source="odometer" label="KM óra állás" disabled={record.status === 'PAID' || record.status === 'CLOSE'}  inputProps={{maxLength: 255}} fullWidth onChange={handleOdometer} />
            <TextInput source="damage" label="Sérülés" disabled={record.status === 'PAID' || record.status === 'CLOSE'}  inputProps={{maxLength: 255}} fullWidth />

                <FormDataConsumer>
                {({ formData }) => (
                    (formData.status === 'PROCESS') &&
                    <>
                        <ProductServiceStoreList  formData={formData} />
                        <UploadImageToS3 formData={formData} />
                        <Typography variant="h6" component="h6">
                            Státusz
                        </Typography>

                        <SelectField source="status" choices={[
                            { id: 'DONE', name: 'Kész',},
                            { id: 'PROCESS', name: 'Folyamatban' ,disable: true}
                        ]} />
                    </>
                )}
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData }) => (
                        (formData.status === 'PAID') &&
                        <>
                            <Typography variant="h6" component="h6">
                                Státusz
                            </Typography>
                            <SelectField source="status" choices={[
                                { id: 'PAID', name: 'Fizetve' },
                                { id: 'CLOSED', name: 'Lezárás' }
                            ]} />
                        </>
                    )}
                </FormDataConsumer>
                <Dialog open={false}>

                </Dialog>
            </>
    )
}

export default WorksheetDetails