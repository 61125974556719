import React, {useState} from 'react';
import {
    TextField,
    List,
    Datagrid,
    Pagination,
    NumberField, useRecordContext, useDataProvider, useNotify
} from "react-admin";
import {Button} from "@mui/material";
import * as PropTypes from "prop-types";

function DownloadField(props) {
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    const notify = useNotify();
    const [file, setFile] = useState(undefined)
    const [isLoading, setLoading] = useState(false)
    const download = (record) => {
        console.log("invoice_nr", record.invoice_number)
        console.log("invoice_id", record.id)
        dataProvider.get(`sale/downloads/${record.id}`)
            .then(response => {
                console.log("response: ", response)
                const contentType = "application/pdf"
                const base64Data = response.data.file
                const fileName = record.invoice_number+".pdf";
                const linkSource = `data:${contentType};base64,${base64Data}`
                setFile({file:linkSource, fileName: fileName})
                setLoading(false)
                let link = document.createElement('a');
                link.href = linkSource;
                link.download = fileName;
                link.click();
            })
            .catch(reason => {
                console.log(reason)
                notify("businessUnits.csrGenerateError", {type: "error"})
                setLoading(false)
            })
    }
    return (
        <Button onClick={() => download(record)}>Letöltés</Button>
    );
}

DownloadField.propTypes = {
    onClick: PropTypes.any,
    children: PropTypes.node
};

const SaleList = () => {
    return (
        <List pagination={<Pagination rowsPerPageOptions={[25]} />} perPage={25}>
            <Datagrid bulkActionButtons={false}>
                <TextField source="id" label={'ID'} sortable={false}/>
                <TextField source="invoice_number" label={'Számlaszám'} sortable={false}/>
                <TextField source="invoice_date" label={'Kelt'} sortable={false}/>
                <TextField source="partner.name" label={'Partner'} sortable={false}/>
                <TextField source="partner.taxcode" label={'Adoszám'} sortable={false}/>
                <TextField source="partner.address.country_code" label={''} sortable={false}/>
                <TextField source="partner.address.post_code" label={'Irsz'} sortable={false}/>
                <TextField source="partner.address.city" label={'Település'} sortable={false}/>
                <TextField source="partner.address.address" label={'Cím'} sortable={false}/>
                <NumberField source="summary.net_amount_local" label={'Nettó végösszeg'} sortable={false} locales="hu-HU" options={{ style: 'currency', currency: 'HUF' }}/>
                <NumberField source="summary.vat_amount_local" label={'Áfa'} sortable={false} locales="hu-HU" options={{ style: 'currency', currency: 'HUF' }}/>
                <NumberField source="summary.gross_amount_local" label={'Bruttó végösszeg'} sortable={false} locales="hu-HU" options={{ style: 'currency', currency: 'HUF' }}/>
                <DownloadField />
            </Datagrid>
        </List>
    )
}

export default SaleList