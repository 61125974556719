import React from 'react';
import {
    TextInput,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    FormDataConsumer,
    SimpleFormIterator,
    ArrayInput,
    AutocompleteInput,
    required, TextField
} from "react-admin";
import {AutocompleteSimpleInputRequied} from "../../components/AutocompleteSimpleInputRequied";

const StoreRequestDetails = () => {
    return (
        <SimpleForm>
            <TextInput disabled source="id" label={'product.id'}/>
            <TextInput disabled source="siteName" label={'site.title'}/>
            <TextInput disabled source="storageName" label={'site.storage'}/>
            <TextInput disabled source="stand" label={'inventory.stand'}/>
            <TextInput disabled source="level" label={'inventory.level'}/>
            <TextInput disabled source="shelf" label={'inventory.shelf'}/>
            <TextInput disabled source="productName" label={'product.name'}/>
            <TextInput disabled source="barCode" label={'product.barCode'}/>
            <TextInput disabled source="euroCode" label={'product.euroCode'}/>
            <TextInput disabled source="glassManufacturerName" label={'product.glassManufacturer'}/>
            <TextInput disabled source="vehicleManufacturerName" label={'product.vehicleManufacturer'}/>
            <TextInput disabled source="vehicleModelName" label={'product.vehicleModel'}/>
            <TextInput disabled source="yearFrom" label={'product.yearFrom'}/>
            <TextInput disabled source="yearTo" label={'product.yearTo'}/>
            <TextInput disabled source="description" label={'product.description'}/>
            <TextInput disabled source="price" label={'product.price'}/>
            <TextInput disabled source="qty" label={'inventory.qty'}/>
            <TextInput source="comment" label={'storeRequest.comment'}/>

        </SimpleForm>
    )
}

export default StoreRequestDetails