import * as React from "react";
import {
    Datagrid,
    TextField,
    TextInput,
    ReferenceInput,
    SelectInput,
    List,
    DeleteButton,
    TopToolbar,
    DownloadButton,
    SavedQueriesList,
    useDataProvider,
    useRecordContext,
    useNotify,
    SelectField,
    FunctionField, DateField, useListContext
} from 'react-admin';
import {Button, Card, CardContent, Grid, Tab, Tabs, Typography} from "@mui/material";
import {StoreRequestBulkAction} from "../../components/StoreRequestBulkAction"
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {useState} from "react";
import {Tooltip} from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";


const StoreRequestList = () => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [file, setFile] = useState(undefined)
    const [isLoading, setLoading] = useState(false)

    const postFilters = [
        <ReferenceInput source="siteId" reference="site" alwaysOn >
            <SelectInput label={"site.title"} optionText="name" resettable />
        </ReferenceInput>,
        <ReferenceInput source="storageId" reference="storage" alwaysOn perPage={100} resettable sx={{ width: '80px' }} >
            <SelectInput label={"site.storage"} optionText="name" resettable />
        </ReferenceInput>,
        <TextInput source="stand" label={'inventory.stand'} alwaysOn resettable sx={{ width: '80px' }} />,
        <TextInput source="level" label={'inventory.level'} alwaysOn resettable sx={{ width: '80px' }} />,
        <TextInput source="shelf" label={'inventory.shelf'} alwaysOn resettable sx={{ width: '80px' }} />,
        <TextInput source="productName" label={'product.name'} alwaysOn resettable/>,
        <TextInput source="barCode" label={'product.barCode'} alwaysOn resettable sx={{ width: '180px' }} />,
        <TextInput source="euroCode" label={'product.euroCode'} alwaysOn resettable sx={{ width: '180px' }} />,

        <ReferenceInput source="glassManufacturerId" reference="product/glassManufacturer" alwaysOn perPage={100}>
            <SelectInput label={'product.glassManufacturer'} optionText="name" resettable />
        </ReferenceInput>,
        <TextInput source="vehicleManufacturerName" label={'product.vehicleManufacturer'} alwaysOn />,
        <TextInput source="vehicleModelName" label={'product.vehicleModel'} alwaysOn />,
   ];

    const ListActions = () => {
        const { sort } = useListContext();  // Lekérjük a rendezés információit
        const { field, order } = sort;      // A rendezés mező és irány
        return(
            <TopToolbar>
                <Button startIcon={<FileDownloadIcon/>} onClick={() =>
                    customExport(field, order)}>Letöltés</Button>
            </TopToolbar>
        );
    }

    function customExport(field, order) {
        const sort = field + ',' + order;
        dataProvider.get('storerequest/download', {sort: sort} )//+ field ? field : '' +"," + order ? order : '')
            .then(response => {
                console.log("response: ", response)
                const contentType = "application/pdf"
                const base64Data = response.data.file
                const fileName = "Raktár kikérő "+new Date().toISOString()+".pdf";
                const linkSource = `data:${contentType};base64,${base64Data}`
                setFile({file:linkSource, fileName: fileName})
                setLoading(false)
                let link = document.createElement('a');
                link.href = linkSource;
                link.download = fileName;
                link.click();
            })
            .catch(reason => {
                console.log(reason)
                notify("businessUnits.csrGenerateError", {type: "error"})
                setLoading(false)
            })

        //javascript goes here to retrieve data from server
    }
    /*const SongFilterSidebar = () => (
        <Card>
            <CardContent>
                <SavedQueriesList />
                <FilterList label="Record Company" >
                    <FilterListItem label="Yes" value={{ has_newsletter: true }} />
                    <FilterListItem label="No" value={{ has_newsletter: false }} />
                </FilterList>
                <FilterList label="Released" >
                    <FilterListItem label="Tests" value={{ category: 'tests' }} />
                    <FilterListItem label="News" value={{ category: 'news' }} />
                    <FilterListItem label="Deals" value={{ category: 'deals' }} />
                    <FilterListItem label="Tutorials" value={{ category: 'tutorials' }} />
                </FilterList>

            </CardContent>
        </Card>
    );*/

    const renderEuroCode = (record)  => {
        if (record.note == null) {
            return (<>
                <span style={{whiteSpace: "nowrap"}}>
                    <Tooltip title="Vágólapra másolás">
                        <ContentCopyIcon fontSize="'inherit'" style={{ cursor: 'pointer' }} onClick={() => {navigator.clipboard.writeText(record.euroCode)}}/>
                    </Tooltip>&nbsp;<TextField source="euroCode"/>
                </span></>);
        } else {
            return (<>
                <span style={{whiteSpace: "nowrap"}}>
                    <Tooltip title="Vágólapra másolás">
                        <ContentCopyIcon fontSize="'inherit'" style={{ cursor: 'pointer' }} onClick={() => {navigator.clipboard.writeText(record.euroCode)}}/>
                    </Tooltip>&nbsp;
                    <TextField source="euroCode"/>&nbsp;
                    <Tooltip title={record.note}>
                        <CommentOutlinedIcon fontSize="'inherit'" style={{ cursor: 'pointer' }}/>
                    </Tooltip>
                </span></>);
        }

    }

    return (<>
        <List filters={postFilters} actions={<ListActions/>} exporter={false}>
             <Datagrid rowClick={"edit"} bulkActionButtons={<StoreRequestBulkAction  />}>
                 <SelectField source="from" label={''} choices= {[
                     { id: 'INTERNAL_REQUEST', name: 'Raktár kikérő' },
                     { id: 'WEBSHOP_ORDER', name: 'Webshop rendelés' },
                     { id: 'INTERNAL_ORDER', name: 'Belső rendelés' }
                 ]} />
                <TextField source="id" label={'product.id'}/>
                <TextField source="siteName" label={'site.title'}/>
                <TextField source="storageName" label={'site.storage'}/>
                <TextField source="stand" label={'inventory.stand'} sortable={false}/>
                <TextField source="level" label={'inventory.level'} sortable={false}/>
                <TextField source="shelf" label={'inventory.shelf'} sortable={false}/>
                <TextField source="productName" label={'product.name'} sortable={false}/>
                <TextField source="barCode" label={'product.barCode'} sortable={false}/>
                <FunctionField source="euroCode" label={'product.euroCode'} render={renderEuroCode} sortable={false}/>
                <TextField source="glassManufacturerName" label={'product.glassManufacturer'} sortable={false}/>
                <TextField source="vehicleManufacturerName" label={'product.vehicleManufacturer'} sortable={false}/>
                <TextField source="vehicleModelName" label={'product.vehicleModel'} sortable={false}/>
                <TextField source="yearFrom" label={'product.yearFrom'} sortable={false}/>
                <TextField source="yearTo" label={'product.yearTo'} sortable={false}/>
                <TextField source="description" label={'product.description'} sortable={false}/>
                <TextField source="price" label={'product.price'} sortable={false}/>
                <TextField source="qty" label={'inventory.qty'} sortable={false}/>
                 <TextField source="partnerName" label={'partner.name'} sortable={false}/>
                 <DateField source="deliveryDate" label={'storeRequest.deliveryDate'} sortable={false}/>
                 <TextField source="comment" label={'storeRequest.comment'} sortable={false}/>
                 <DeleteButton/>
            </Datagrid>
        </List>
        </>
    )
}

export default StoreRequestList