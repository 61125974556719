import React from 'react';
import {
    TextInput,
    SimpleForm,
    RadioButtonGroupInput,
    required
} from "react-admin";

const EmployeeDetails = () => {
    return (
        <SimpleForm>
            <TextInput source="name" label={"employee.name"} validate={required()} inputProps={{maxLength: 255}} style = {{width: 400}}/>
            <TextInput source="email" label={"employee.email"} validate={required()} inputProps={{maxLength: 255}} style = {{width: 400}}/>

            <RadioButtonGroupInput source="permission" label={"employee.permission"} validate={required()} choices={[
                { id: 'admin', name: 'Adminisztrátor' },
                { id: 'manager', name: 'Aszisztens' },
                { id: 'storekeeper', name: 'Raktáros' },
                { id: 'worker', name: 'Szervízes' },
            ]} />
        </SimpleForm>
    )
}

export default EmployeeDetails