import Paper from "@mui/material/Paper";
import {Datagrid, TextField, Title, usePermissions, List, useListContext} from "react-admin";
import {Typography, Button, Toolbar} from "@mui/material";
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';


const MinimumQuantityGlass = () => {
    const { permissions } = usePermissions();
    //const { page, hasPreviousPage, hasNextPage, setPage, setPerPage } = useListContext();

    //const sort = { field: 'id', order: 'DESC' };
    /*const { data, total, isLoading } = useGetList('dashboard/minimum-quantity', {

        sort,
    });*/
    const PostPagination = () => {
        const { page, hasPreviousPage, hasNextPage, setPage, setPerPage } = useListContext();
        //setPerPage(25)

        if (!hasPreviousPage && !hasNextPage) return null;
        return (
            <Toolbar>
                {hasPreviousPage &&
                    <Button
                        key="previous"
                        onClick={() => setPage(page - 1)}
                        startIcon={<ChevronLeft />}
                    >
                    </Button>
                }
                {hasNextPage &&
                    <Button
                        key="next"
                        onClick={() => setPage(page + 1)}
                        startIcon={<ChevronRight />}
                    >
                    </Button>
                }
            </Toolbar>
        );
    }

    return (
        permissions === 'admin' &&
        <>
            <Title title=""/>
            <Paper key="0" elevation={6}>
                <Typography variant="h5" component="h5" sx={{textAlign: 'center'}}>Minimum mennyiség (Kiegészítő)</Typography>
                <List resource='dashboard/minimum-quantity-accessory' actions={false} title=" " perPage={25} pagination={<PostPagination/>}>
                    <Datagrid
                        bulkActionButtons={false}>
                        <TextField source="euroCode" label="Eurokód"/>
                        <TextField source="qty" label="Mennyiség"/>
                    </Datagrid>
                </List>
            </Paper>
        </>
    )
}
export default MinimumQuantityGlass