import * as React from 'react';
import {DataGrid, huHU} from '@mui/x-data-grid';
import {useFormContext} from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {useState} from "react";
import EditIcon from "@mui/icons-material/Edit";
import {number, minValue, useTranslate, Form, useNotify} from "react-admin";
import {Button, Dialog, DialogContent, TextField} from "@mui/material";


export default function EditServiceDataGrid({rows}) {

    const notify = useNotify()
    const theme = createTheme(
        {
            palette: {
                primary: { main: '#1976d2' },
            },
        },
        huHU,
    );

    const columns = [
        {
            field: 'id',
            headerName: 'id',
            hide: true
            //valueGetter: (params) => params.row.product.euroCode
        },
        {
            field: 'name',
            headerName: 'Termék neve',
            width: 250,
            //valueGetter: (params) => params.row.product.euroCode
        },
        {
            field: 'euroCode',
            headerName: 'Euro kód',
            width: 180,
            //valueGetter: (params) => params.row.product.euroCode
        },
        {
            field: 'glassManufacturer',
            headerName: 'Üveg gyártó',
        },
        {
            field: 'vehicleManufacturer',
            headerName: 'Jármű gyártó',
        },
        {
            field: 'vehicleModel',
            headerName: 'Model',
        },
        {
            field: 'yearFrom',
            headerName: 'Évtől',
        },
        {
            field: 'yearTo',
            headerName: 'Évig',
        },
        /*{
            field: 'price',
            headerName: 'Ár',
            type: "number",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return '';
                }else{
                    return `${params.value} Ft`
                }
            }

        },*/
        {
            field: "action",
            headerName: "Törlés",
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const newRows = rows.filter(row => {
                        return row.id !== params.id
                    })
                    setValue('products', newRows)

                };

                return <Button style={{color: "#1976d2"}} onClick={onClick}><DeleteIcon/></Button>;
            }
        },

    ];

    const { setValue } = useFormContext();
    const [openQty, setOpenQty] = useState(false)
    const [newQty, setNewQty] = useState(null)
    const [editValue, setEditValue] = useState(null)
    const translate = useTranslate()

    const processRowUpdate = React.useCallback(
        async (newRow) => {
            const newRows = rows.map(r => (r.id === newRow.id) ? {...r, qty: newRow.qty, stand: newRow.stand, level: newRow.level, shelf: newRow.shelf} : r )
            setValue('products', newRows)
        },
        [],
    );

    const handleClickQty = (event) => {
        //if()
        const newRows = rows.map(row => {
            if(row.id !== editValue.id){
                return row
            }else{
                console.log("qqq",row, newQty)
                return {...row, qty: parseInt(newQty)}
            }
        })
        setValue('products', newRows)
        setOpenQty(false)

    };

    const onProcessRowUpdateError = (error) => {
        console.log(error)
    }

    const handleKeypressQty = e => {
        //it triggers by pressing the enter key
        if (e.key === "Enter") {
            handleClickQty(e)
        }
    };


    const handleDialogClose = () => {
        setOpenQty(false)
        setNewQty(null)
    }


    const handleTextChangeQty = (event) => {
        if(event.target.value<0){
            notify('hiba', {type:'error'})
        }{
            setNewQty(event.target.value)
        }

    }

    const QtyCheck = () => {
        return !newQty || newQty <= 0 || newQty > editValue.oldQty;
    }

    return (
        <React.Fragment>
            <Dialog open={openQty} onClose={handleDialogClose} >
                <DialogContent>
                    <h2>Kivételezendő mennyiség</h2>
                    <TextField label={translate("outgoing.qty")} type={"number"} autoFocus
                               defaultValue={0}  onWheel={(e) => e.target.blur()}
                               /*onKeyPress={handleKeypressQty} */ onChange={handleTextChangeQty} fullWidth />
                    <div style={{marginTop: "16px", display:"flex", justifyContent: "space-between"}}>
                        <Button style={{paddingRight: "8px", color: "white"}} variant={"contained"} color={"primary"} disabled={QtyCheck()} onClick={handleClickQty}>{translate("outgoing.modify")}</Button>
                        <Button variant={"outlined"} color={"secondary"} onClick={handleDialogClose}>{translate("outgoing.cancel")}</Button>
                    </div>
                </DialogContent>
            </Dialog>

            <div style={{ height: 500, width: 1700 }}>
                <ThemeProvider theme={theme} >
                    <DataGrid
                        rowsPerPageOptions={[5, 10, 20, 100]}
                        rows={rows}
                        columns={columns}
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                        processRowUpdate={processRowUpdate}
                        onProcessRowUpdateError={onProcessRowUpdateError}
                    />
                </ThemeProvider>
            </div>
        </React.Fragment>
    );
}