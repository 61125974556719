import React from 'react';
import {
    TextField,
    List,
    Datagrid,
    DateField,
    ShowButton,
    DeleteButton, TextInput
} from "react-admin";

const OrderList = () => {

    const postFilters = [
        <TextInput source="name" label={'partner.name'} alwaysOn resettable />,
        <TextInput source="zipCode" label={'partner.zipCode'} alwaysOn resettable />,
        <TextInput source="settlement" label={'partner.settlement'} alwaysOn resettable />,
        <TextInput source="address" label={'partner.address'} alwaysOn resettable />,
        <TextInput source="taxNumber" label={'partner.taxNumber'} alwaysOn resettable />,
    ];


    return (
        <List filters={postFilters}>
            <Datagrid rowClick={"edit"} bulkActionButtons={false}>
                <TextField source="id" label={'product.id'}/>
                <DateField source="createdAt" label={'partner.createdAt'} />
                <TextField source="partnerId" label={'partner.id'} visibility={false}/>
                <TextField source="name" label={'partner.name'}/>
                <TextField source="zipCode" label={'partner.zipCode'}/>
                <TextField source="settlement" label={'partner.settlement'}/>
                <TextField source="address" label={'partner.address'}/>
                <TextField source="taxNumber" label={'partner.taxNumber'}/>
                <TextField source="phone" label={'partner.phone'}/>
                <TextField source="email" label={'partner.email'}/>
                <TextField source="discount" label={'partner.discount'}/>
                <ShowButton/>
                <DeleteButton/>
            </Datagrid>
        </List>

    )
}

export default OrderList