import React from 'react';
import {Create} from 'react-admin';
import CustomerDetails from "./CustomerDetails";

const CustomerCreate = (props) => {
    return (
        <Create {...props} title={'customer.create'}>
            <CustomerDetails />
        </Create>
    )
}

export default CustomerCreate