import {useNotify, useTranslate, Form, useRedirect} from "react-admin";
import {useState} from "react";
import {Grid, Stack, TextField} from "@mui/material";
import Button from "@material-ui/core/Button";
import {Auth} from "aws-amplify";
import {makeStyles} from "@material-ui/core/styles";
import {Divider} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    },
    form: {
        padding: theme.spacing(4)
    }
}));


const ForgotPassword= () => {
    const classes = useStyles()
    const translate = useTranslate()
    const notify = useNotify()
    const redirect = useRedirect()
    const [email, setEmail] = useState("")
    const [code, setCode] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const handleSubmitForgot = e => {
        console.log(email);
        Auth.forgotPassword(email)
            .then(data => {
                console.log(data);
                notify('signUp.verificationCodeSent', { type: 'success' })
            })
            .catch(err => {
                console.log(err);
                if(err.message.includes('Invalid verification')){
                    notify('signUp.invalidVerificationCode', { type: 'error' })
                }
            });

    };

    const handleSubmitForgotSubmit = e => {
        console.log(email);
        Auth.forgotPasswordSubmit(email, code, newPassword)
            .then(data => {
                console.log(data);
                redirect("/")
            })
            .catch(err => {
                console.log(err);
                if(err.message.includes('Invalid verification')){
                    notify('signUp.invalidVerificationCode', { type: 'error' })
                }
                if(err.message.includes('Password does not conform to policy', { type: 'error' })){
                    notify('signUp.passwordPolicy', { type: 'error' })
                }
                if(err.code==='ExpiredCodeException'){
                    notify('signUp.expiredCodeException', { type: 'error' })
                }
                if(err.code==='LimitExceededException'){
                    notify('signUp.limitExceededException', { type: 'error' })
                }
            });
    };


    return(
 <Stack className={classes.root} divider={<Divider orientation="horizontal" flexItem />}
        spacing={2} >
        <Form onSubmit={handleSubmitForgot} className={classes.form}>
            <h2>{translate('signUp.forgotPassword')}</h2>
            <Grid rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                    <TextField required id="email" label={translate('signUp.email')} variant="filled" type="email" value={email} onChange={e => setEmail(e.target.value)}  style = {{width: 400}}/>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" type="submit" color="primary" >{translate('signUp.verificationCodeRequest')}</Button>
                </Grid>
            </Grid>
        </Form>
        <Form onSubmit={handleSubmitForgotSubmit}>
        <Grid rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}>
                <TextField required id="code" label={translate('signUp.verificationCode')} variant="filled" type="text" value={code} onChange={e => setCode(e.target.value)}  style = {{width: 400}}/>
            </Grid>
            <Grid item xs={12}>
                <TextField required id="newPassword" label={translate('signUp.newPassword')} variant="filled" type="password" value={newPassword} inputProps={{ inputMode: 'text', pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$' }} helperText={translate('signUp.passwordHint')} onChange={e => setNewPassword(e.target.value)}  style = {{width: 400}}/>
            </Grid>
            <Grid item xs={12}>
                <TextField required id="confirmPassword" label={translate('signUp.confirmPassword')} variant="filled" type="password" value={confirmPassword} inputProps={{ inputMode: 'text', pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$' }} onChange={e => setConfirmPassword(e.target.value)}  style = {{width: 400}}/>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" type="submit" color="primary">{translate('signUp.save')}</Button>
            </Grid>
        </Grid>
    </Form>
</Stack>
    )

}

export default ForgotPassword