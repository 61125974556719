import React from 'react';
import {Create, SimpleForm} from 'react-admin';
import SaleDetails from "./SaleDetails";

const SaleCreate = (props) => {
    const validateForm = (values) => {
        const errors = {};
        let regex = /^[0-9]{8}-[0-9]{1}-[0-9]{2}$/;

        if (values.taxType === 'HAS_TAX_NUMBER') {
            if (!values.taxNumber || !regex.test(values.taxNumber)) {
                errors.taxNumber = 'Az adószámot ki kell kitölteni. A helyes formátum xxxxxxxx-x-xx';
            }
        } else if (values.taxType === 'NO_TAX_NUMBER') {
            if (values.taxNumber && values.taxNumber.length > 0) {
                errors.taxNumber = 'Az adószámot nem kell kitölteni.';
            }
        } else if (values.taxType === 'FOREIGN') {
            if (!values.taxNumber) {
                errors.taxNumber = 'Az adószámot ki kell kitölteni.';
            }
        }
        if (!values.name) {
            errors.name = 'A mező ktöltése kötelező.';
        }
        if (!values.postCode) {
            errors.postCode = 'A mező ktöltése kötelező.';
        }
        if (!values.city) {
            errors.city = 'A mező ktöltése kötelező.';
        }
        if (!values.address) {
            errors.address = 'A mező ktöltése kötelező.';
        }
        if (!values.fulfillmentDate) {
            errors.fulfillmentDate = 'A mező ktöltése kötelező.';
        }
        if (!values.dueDate) {
            errors.dueDate = 'A mező ktöltése kötelező.';
        }
        if (!values.paymentMethod) {
            errors.paymentMethod = 'A mező ktöltése kötelező.';
        }
        if (values.dueDate < new Date().toISOString().substr(0, 10)) {
            errors.dueDate = 'Nem helyes dátum.';
        }
        if (values.fulfillmentDate < new Date().toISOString().substr(0, 10)) {
            errors.fulfillmentDate = 'Nem helyes dátum.';
        }
        /*if (!values.hasOwnProperty("products")) {
            notify("Terméket fel kell venni.")
            errors.products = 'Terméket fel kell venni.';
        }*/

        return errors;
    };

    return (
        <Create {...props} title={'sale.create'} redirect="list">
            <SimpleForm validate={validateForm}>
            <SaleDetails />
            </SimpleForm>
        </Create>
    )
}

export default SaleCreate