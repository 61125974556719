import * as React from "react";
import {
    Datagrid,
    TextField,
    DateField,
    List,
    ReferenceField,
    ShowButton,
    useTranslate
} from 'react-admin';

const IncomingList = () => {

    const translate = useTranslate()

    return (
        <List>
            <Datagrid rowClick={"edit"} bulkActionButtons={false} /*expand={ProductPanel}*/>
                <TextField source="id" label={'outgoing.id'}/>
                <TextField source="note" label={'outgoing.note'}/>
                <ReferenceField label={'site.title'}  reference={"site"} link={false} source={"siteId"}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label={'store.title'}  reference={"storage"} link={false} source={"storeId"}>
                    <TextField source="name" />
                </ReferenceField>
                <DateField source="incomingDate" label="outgoing.incomingDate"/>
                <DateField source="orderDate" label="outgoing.orderDate"/>
                <DateField source="createdAt" label="outgoing.createdAt" showTime/>
                <ReferenceField label={'outgoing.createdBy'} reference={"employee/getAll"} link={false} source={"createdBy"}>
                    <TextField source="name"/>
                </ReferenceField>
                <ShowButton/>
            </Datagrid>
        </List>
    )
}

export default IncomingList