import * as React from "react";
import {useTranslate, useDataProvider, useNotify, Title, usePermissions} from "react-admin";
import {Card, CardContent, CardHeader, Typography} from '@mui/material';
import {Masonry} from "@mui/lab";
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import {useMediaQuery} from "@material-ui/core";
import MinimumQuantityGlass from "./item/MinimumQuantityGlass";
import MinimumQuantityAccessory from "./item/MinimumQuantityAccessory";
import Top10SaleQuantity from "./item/Top10SaleQuantity";
import Curiosity from "./item/Curiosity";

const Dashboard = () => {

    const { permissions } = usePermissions();
    const isSmall = useMediaQuery('(max-width:1024px)');

    const heights = [150, 30, 90, 70, 110, 150, 130, 80, 50, 90, 100, 150, 30, 50, 80];

    const Item = styled(Paper)(({theme}) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(0.5),
        textAlign: 'center',
        //color: theme.palette.text.secondary,
    }));

    return (
        <>
            <Title title="Glassmax Dashboard" />
            <Masonry columns={isSmall? 2: 3 }  spacing={{ xs: 1, sm: 2, md: 3 }}>
                <MinimumQuantityGlass/>
                <MinimumQuantityAccessory/>
                <Top10SaleQuantity/>
                <Curiosity/>
                <Paper key="2" sx={{height: 90}}>
                    Teszt szöveg
                </Paper>
            </Masonry>
        </>
    );
}
export default Dashboard;