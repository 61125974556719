import { Typography} from "@mui/material";
import {CreateButton, useTranslate} from "react-admin";

export const EmptyPage = ({basePath, text, createEnabled}) => {
    const translate = useTranslate()
    return(
        <div style={{position: "absolute", top:0, bottom: 0, left: 0, right: 0, width: "500px", height: "500px", margin: "auto"}}>
            <div style={{display: "flex", flexDirection: "column", justifyContent : "center"}}>
                <img src="empty.png" style={{marginTop: "46px"}} alt="" />
                <Typography style={{textAlign: "center"}} variant="h4">
                    {translate(text)}
                </Typography>
                {createEnabled ?
                    <CreateButton basePath={basePath}/>
                    :
                    null
                }
            </div>
        </div>

    );
}