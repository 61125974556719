import React, {useState} from 'react';
import {
    TextField,
    List,
    EmailField,
    BooleanField,
    Datagrid,
    DateField,
    SelectField, useDataProvider, useRecordContext, useNotify, useRefresh, TextInput
} from "react-admin";
import {Button} from "@mui/material";
import * as PropTypes from "prop-types";

function OfferButton(props) {
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    const refresh = useRefresh();
    const notify = useNotify();
    const [file, setFile] = useState(undefined)
    const [isLoading, setLoading] = useState(false)
    const download = (record) => {
         dataProvider.get(`b2ccuriosity/offer/${record.id}`)
            .then(response => {
                notify("curiosity.setOfferSentState", {type: "info"})
                refresh();
            })
            .catch(reason => {
                console.log(reason)
                notify("curiosity.setOfferSentStateError", {type: "error"})
            })
    }
    return (
        <Button onClick={() => download(record)}>Ajánlat elküldve</Button>
    );
}


OfferButton.propTypes = {
    onClick: PropTypes.any,
    children: PropTypes.node
};

const CuriosityList = () => {

    const postRowStyle = (record, index) => ({
        backgroundColor : record.state === 'OFFER_SENT' ? '#eee' : 'white'
    });

    const postFilters = [
        <TextInput source="customerName" label={'curiosity.name'} alwaysOn resettable />,
        <TextInput source="customerPhone" label={'curiosity.phone'} alwaysOn resettable />,
        <TextInput source="customerEmail" label={'curiosity.email'} alwaysOn resettable />,
        <TextInput source="plateNumber" label={'curiosity.plateNumber'} alwaysOn resettable />,
    ];

    return (
        <List filters={postFilters}>
            <Datagrid rowClick={"show"} bulkActionButtons={false} rowStyle={postRowStyle}>
                <BooleanField source="isCompany" label={'curiosity.isCompany'}/>
                <TextField source="customerName" label={'curiosity.name'}/>
                <TextField source="customerPhone" label={'curiosity.phone'}/>
                <EmailField source="customerEmail" label={'curiosity.email'}/>
                <TextField source="vehicleManufacturer" label={'curiosity.vehicleManufacturer'}/>
                <TextField source="vehicleModel" label={'curiosity.vehicleModel'}/>
                <TextField source="vehicleYear" label={'curiosity.vehicleYear'}/>
                <TextField source="plateNumber" label={'curiosity.plateNumber'}/>
                <TextField source="vin" label={'curiosity.vin'}/>
                <SelectField source="position" choices={[
                    { id: 'FIRST', name: 'Első' },
                    { id: 'BACK', name: 'Hátsó' },
                    { id: 'SIDE', name: 'Oldalsó' },
                ]} label={'curiosity.position'} />
                <SelectField source="size" choices={[
                    { id: 'BIG', name: 'Csere' },
                    { id: 'SMALL', name: 'Javítás' },
                ]} label={'curiosity.service'} />
                <SelectField source="location" choices={[
                    { id: 'SITE', name: 'Műhely' },
                    { id: 'CUSTOMER', name: 'Ügyfélnél' },
                ]} label={'curiosity.location'}/>
                <TextField source="customerPostcode" label={'curiosity.postcode'}/>
                <TextField source="customerSettlement" label={'curiosity.settlement'}/>
                <TextField source="customerAddress" label={'curiosity.address'}/>
                <BooleanField source="casco" label={'curiosity.casco'}/>
                <TextField source="insurance" label={'curiosity.insurance'}/>
                <DateField source="createdAt" label={'curiosity.createdAt'} showTime={true} />
                <SelectField source="state" label={'Állapot'} choices={[
                    { id: 'NEW', name: '' },
                    { id: 'OFFER_SENT', name: 'Ajánlat elküldve' },
                ]} />
            </Datagrid>
        </List>

    )
}

export default CuriosityList