import * as React from "react";
import {BooleanField, Datagrid, DateField, List, NumberField, SelectField, TextField, TextInput} from "react-admin";

const WebshopSearchList = () => {
    const postFilters = [
        <TextInput source="partnerName" label={'wsSearchLog.partnerName'} alwaysOn resettable sx={{ width: '280px' }} />
    ]

    return (
        <List filters={postFilters} >
            <Datagrid rowClick={false} >
                <TextField source="id" label={'wsSearchLog.id'} sortable={false}/>
                <TextField source="partnerName" label={'wsSearchLog.partnerName'} sortable={false}/>
                <DateField source="createdAt" showTime label={'wsSearchLog.createdAt'} sortable={false}/>
                <TextField source="euroCode" label={'wsSearchLog.euroCode'} sortable={false}/>
                <TextField source="brand" label={'wsSearchLog.brand'} sortable={false}/>
                <TextField source="model" label={'wsSearchLog.model'} sortable={false}/>
                <SelectField source="type" label={'wsSearchLog.type'} choices={[
                    { id: 'WS', name: 'Szélvédő' },
                    { id: 'BG', name: 'Karosszéria üveg' },
                    { id: 'BL', name: 'Hátsó üveg' },
                ]} sortable={false}/>
                {/*<TextField source="year" label={'wsSearchLog.year'} sortable={false} />*/}
                <BooleanField source="isAccessory" label={'wsSearchLog.isAccessory'} sortable={false}/>
                <BooleanField source="isStore" label={'wsSearchLog.isStore'} sortable={false}/>
                <BooleanField source="isSale" label={'wsSearchLog.isSale'} sortable={false}/>
                <NumberField source="resultCount" label={'wsSearchLog.resultCount'} sortable={false}/>
            </Datagrid>
        </List>
    )
}

export default WebshopSearchList;