import {Amplify, Auth} from 'aws-amplify';

Amplify.configure({
    Auth: {
        // REQUIRED - Amazon Cognito Region
        region: process.env.REACT_APP_AWS_REGION,

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEBCLIENT_ID,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: process.env.REACT_APP_AWS_MANDATORY_SIGN_IN,

    }
});
/*function getMe() {
    return new Promise((resolve, reject) => {
        dataProvider.get("auth/me").then(
        value => {
            localStorage.setItem('permission', JSON.stringify( value.data.privileges.map(it => it.id)))
            resolve(value.data.privileges.map(it => it.id));
        }
    ).catch(reason=>{
            reject(reason);
        })
    }
    )
}*/


const authProvider = {
    login: ({email, password}) => {
        return new Promise((resolve, reject) => {
            Auth.signIn(email, password).then(user => {
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    Auth.completeNewPassword(user, password)
                        .then(user => {
                            console.log("Challenge name: ", user.challengeName);
                            Auth.currentSession()
                                .then(res => {
                                    console.log("res",  res)
                                    let accessToken = res.getAccessToken()
                                    let jwt = accessToken.getJwtToken()
                                    localStorage.setItem('username', email)
                                    localStorage.setItem('token', jwt)
                                    localStorage.setItem('permission', res.getIdToken().decodePayload()['cognito:groups'])
                                    console.log('token', localStorage.getItem('token'))
                                    Auth.currentAuthenticatedUser().then(user => {
                                        console.log("User ",user);
                                    })

                                    resolve(res);
                                }).catch(reason => {
                                reject(reason);
                            })
                        });
                }else {
                    console.log("Challenge name: ", user.challengeName);
                        Auth.currentSession()
                            .then(res => {
                                console.log("res",  res)
                                let accessToken = res.getAccessToken()
                                let jwt = accessToken.getJwtToken()
                                //console.log(`myAccessToken: ${JSON.stringify(accessToken)}`)
                                //console.log(`myJwt: ${jwt}`)
                                localStorage.setItem('username', email)
                                localStorage.setItem('token', jwt)
                                localStorage.setItem('permission', res.getIdToken().decodePayload()['cognito:groups'])
                                console.log('token', localStorage.getItem('token'))
                                Auth.currentAuthenticatedUser().then(user => {
                                    console.log("User ",user);
                                })

                                resolve(res);
                            }).catch(reason => {
                            reject(reason);
                        })
                    }
            }
            ).catch(reason => {
                reject(reason);
            });
        });

    },
    logout: () => {
        localStorage.removeItem('username');
        localStorage.removeItem('token');
        localStorage.removeItem('permission');
        return Promise.resolve();
    },
    checkAuth: () =>
        localStorage.getItem('username') ? Promise.resolve() : Promise.reject()
            ,
    checkError: (error) => {
        const status = error.status;
        if (status === 401 /*|| status === 403*/) {
            localStorage.removeItem('username');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getIdentity: () =>
        Promise.resolve({
            id: 'user',
            fullName: localStorage.getItem('username'),
        }),
    getPermissions: async () => {
        console.log("Perm: ", localStorage.getItem('permission'))
        return Promise.resolve(localStorage.getItem('permission'));
        //await getMe()
        //return Promise.resolve(JSON.parse(localStorage.getItem('permission')))
    },

};

export default authProvider;
