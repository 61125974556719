import * as React from "react";
import {
    Button,
    Datagrid,
    downloadCSV,
    TextField,
    TextInput,
    ReferenceInput,
    SelectInput,
    List,
    FilterListItem, FilterList, SavedQueriesList, FunctionField, useDataProvider, useListContext
} from 'react-admin';
import {Card, CardContent} from "@mui/material";
import {Tooltip} from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import jsonExport from 'jsonexport/dist'; // CSV konvertáláshoz

const InventoryExportButton = () => {
    const { filterValues, sort } = useListContext(); // A szűrési és rendezési feltételek
    const dataProvider = useDataProvider();

    const handleExport = async () => {
        try {
            // Adatok lekérése az aktuális szűrési feltételekkel
            const { data } = await dataProvider.getList('inventory', {
                filter: filterValues,
                sort,
                pagination: { page: 1, perPage: 5000 }, // Állítsd be a kívánt rekordmennyiséget
            });

            // CSV konvertálás
            jsonExport(data, (err, csv) => {
                if (err) {
                    console.error('Hiba történt az exportálás során:', err);
                    return;
                }
                downloadCSV(csv, 'inventory_export'); // Fájl letöltése
            });
        } catch (error) {
            console.error('Hiba az exportálás során:', error);
        }
    };

    return (
        <Button startIcon={<FileDownloadIcon />} label="Exportálás" onClick={handleExport} />
    );
};

const InventoryList = () => {

    const postFilters = [
        <ReferenceInput source="siteId" reference="site" alwaysOn perPage={100}>
            <SelectInput label={"site.title"} optionText="name" resettable />
        </ReferenceInput>,
        <ReferenceInput source="storageId" reference="storage" alwaysOn perPage={100} resettable sx={{ width: '80px' }} >
            <SelectInput label={"site.storage"} optionText="name" resettable />
        </ReferenceInput>,
        <TextInput source="stand" label={'inventory.stand'} alwaysOn resettable sx={{ width: '80px' }} />,
        <TextInput source="level" label={'inventory.level'} alwaysOn resettable sx={{ width: '80px' }} />,
        <TextInput source="shelf" label={'inventory.shelf'} alwaysOn resettable sx={{ width: '80px' }} />,
        <TextInput source="productName" label={'product.name'} alwaysOn resettable/>,
        <TextInput source="barCode" label={'product.barCode'} alwaysOn resettable sx={{ width: '180px' }} />,
        <TextInput source="euroCode" label={'product.euroCode'} alwaysOn resettable sx={{ width: '180px' }} />,

        <ReferenceInput source="glassManufacturerId" reference="product/glassManufacturer" alwaysOn perPage={100} >
            <SelectInput label={'product.glassManufacturer'} optionText="name" resettable />
        </ReferenceInput>,
        <TextInput source="vehicleManufacturerName" label={'product.vehicleManufacturer'} alwaysOn />,
        <TextInput source="vehicleModelName" label={'product.vehicleModel'} alwaysOn />,
   ];


    const SongFilterSidebar = () => (
        <Card>
            <CardContent>
                <SavedQueriesList />
                <FilterList label="Record Company" >
                    <FilterListItem label="Yes" value={{ has_newsletter: true }} />
                    <FilterListItem label="No" value={{ has_newsletter: false }} />
                </FilterList>
                <FilterList label="Released" >
                    <FilterListItem label="Tests" value={{ category: 'tests' }} />
                    <FilterListItem label="News" value={{ category: 'news' }} />
                    <FilterListItem label="Deals" value={{ category: 'deals' }} />
                    <FilterListItem label="Tutorials" value={{ category: 'tutorials' }} />
                </FilterList>

            </CardContent>
        </Card>
    );

    const renderEuroCode = (record)  => {
        if (record.note == null) {
            return (<>
                <span style={{whiteSpace: "nowrap"}}>
                    <Tooltip title="Vágólapra másolás">
                        <ContentCopyIcon fontSize="'inherit'" style={{ cursor: 'pointer' }} onClick={() => {navigator.clipboard.writeText(record.euroCode)}}/>
                    </Tooltip>&nbsp;<TextField source="euroCode"/>
                </span></>);
        } else {
            return (<>
                <span style={{whiteSpace: "nowrap"}}>
                    <Tooltip title="Vágólapra másolás">
                        <ContentCopyIcon fontSize="'inherit'" style={{ cursor: 'pointer' }} onClick={() => {navigator.clipboard.writeText(record.euroCode)}}/>
                    </Tooltip>&nbsp;
                    <TextField source="euroCode"/>&nbsp;
                    <Tooltip title={record.note}>
                        <CommentOutlinedIcon fontSize="'inherit'" style={{ cursor: 'pointer' }}/>
                    </Tooltip>
                </span></>);
        }

    }


    return (
        <List filters={postFilters} actions={<InventoryExportButton />}>
             <Datagrid rowClick={"edit"} bulkActionButtons={false}>
                <TextField source="id" label={'product.id'}/>
                <TextField source="siteName" label={'site.title'}/>
                <TextField source="storageName" label={'site.storage'}/>
                <TextField source="stand" label={'inventory.stand'}/>
                <TextField source="level" label={'inventory.level'}/>
                <TextField source="shelf" label={'inventory.shelf'}/>
                <TextField source="productName" label={'product.name'}/>
                <TextField source="barCode" label={'product.barCode'}/>
                <FunctionField source="euroCode" label={'product.euroCode'} render={renderEuroCode}/>
                <TextField source="glassManufacturerName" label={'product.glassManufacturer'}/>
                <TextField source="vehicleManufacturerName" label={'product.vehicleManufacturer'}/>
                <TextField source="vehicleModelName" label={'product.vehicleModel'}/>
                <TextField source="yearFrom" label={'product.yearFrom'}/>
                <TextField source="yearTo" label={'product.yearTo'}/>
                <TextField source="description" label={'product.description'}/>
                <TextField source="price" label={'product.price'}/>
                <TextField source="qty" label={'inventory.qty'}/>
            </Datagrid>
        </List>

    )
}

export default InventoryList