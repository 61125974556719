import React, {useState} from 'react';
import {
    TextField,
    List,
    Datagrid as ReactAdminDataGrid,
    TextInput,
    Pagination,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    useDataProvider,
    FunctionField
} from "react-admin";
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import {DialogActions, DialogContentText, Button, Dialog, DialogContent, DialogTitle, Tooltip} from "@mui/material";
import {DataGrid, huHU} from "@mui/x-data-grid";
import {createTheme} from "@mui/material/styles";
import {ThemeProvider} from "@material-ui/styles";

const SearchList = (...form) => {
    const dataProvider = useDataProvider();
    const [optBrand, setOptBrand] = useState(null);
    const [open, setOpen] = useState(false);
    const [dialogData, setDialogData] = useState([]);
    const [substitutionCodes, setSubstitutionCodes] = useState([])

    const theme = createTheme(
        {
            palette: {
                primary: { main: '#1976d2' },
            },
        },
        huHU,
    );

    const handleClickOpen =  async (substitutionCodes) => {
        console.log(substitutionCodes)
        setSubstitutionCodes(substitutionCodes)
        const fetchData = async ()  => {
            try {
                const { data } = await dataProvider.getList('search/substitution', {
                    pagination: {page: 1, perPage: 500},
                    sort: {field: 'ag_euro_code', order: 'ASC'},
                    filter: {substitutionCode: substitutionCodes},
                });
                console.log("Data fetched: ", data);
                // Open the dialog once data is fetched
                setDialogData(data);
                setOpen(true);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
        fetchData()
    };

    const handleClose = () => {
        setOpen(false);
    };

    const changeBrand = event => {
        if (event) {
            if (event.target.value != null) {
                setOptBrand(event.target.value);
            } else {
                setOptBrand(null);
            }
        } else {
        }
    }

    const choices = [
        {id: 'WS', name: 'Első szélvédő'},
        {id: 'BL', name: 'Hátsó szélvédő'},
        {id: 'BG', name: 'Karosszéria üveg', disable: true},
    ];

    const postFilters = [
        <TextInput source="euroCode" label={'search.euroCode'} alwaysOn resettable/>,
        <ReferenceInput source="brand" reference="search/brand" alwaysOn perPage={500}>
            <SelectInput label={'search.brand'} optionText="name" onChange={changeBrand} resettable/>
        </ReferenceInput>,

        <ReferenceInput source="model" reference="search/model" filter={{brand: optBrand}} alwaysOn perPage={500}>
            <SelectInput label={'search.model'} optionText="name" resettable/>
        </ReferenceInput>,
        <SelectInput source="type" label={'search.type'} optionText="name" choices={choices} resettable alwaysOn/>,
        <BooleanInput source="isStore" label={'search.isStore'} alwaysOn/>,
        <BooleanInput source="isAccessory" label={'search.accessory'} alwaysOn/>,
        <TextInput source="partnerCode" label={'search.partnerCode'} alwaysOn resettable/>,
    ];

    const postRowStyle = (record, index) => ({
        backgroundColor: record.qty != null ? '#efe' : record.vehicleManufacturer == null ? 'white' : '#eee'
    });

    const renderReservedQty = (record) => (
        <>{record.reservedQty === 0 ? "" : record.reservedQty}</>
    );

    const renderEuroCode = (record) => {
        if (record.agEuroCode == null) {
            return null
        }
        if (record.note == null) {
            return (<>
                <span style={{whiteSpace: "nowrap"}}>
                    <Tooltip title="Vágólapra másolás">
                        <ContentCopyIcon fontSize="'inherit'" style={{cursor: 'pointer'}} onClick={() => {
                            navigator.clipboard.writeText(record.agEuroCode)
                        }}/>
                    </Tooltip>&nbsp;<TextField source="agEuroCode"/>
                </span></>);
        } else {
            return (<>
                <span style={{whiteSpace: "nowrap"}}>
                    <Tooltip title="Vágólapra másolás">
                        <ContentCopyIcon fontSize="'inherit'" style={{cursor: 'pointer'}} onClick={() => {
                            navigator.clipboard.writeText(record.agEuroCode)
                        }}/>
                    </Tooltip>&nbsp;
                    <TextField source="agEuroCode"/>&nbsp;
                    <Tooltip title={record.note}>
                        <CommentOutlinedIcon fontSize="'inherit'" style={{cursor: 'pointer'}}/>
                    </Tooltip>
                </span></>);
        }

    }


    const renderSubstitutionCode = (record) => {


        if (!record || !record.substitutionCodes || record.substitutionCodes.length === 0) {
            return (renderEuroCode(record));
        }

        return (
            <span style={{whiteSpace: "nowrap"}}>
                <Tooltip title="Vágólapra másolás">
                    <ContentCopyIcon fontSize="inherit" style={{cursor: 'pointer'}} onClick={() => {
                        navigator.clipboard.writeText(record.agEuroCode)
                    }}/>
                </Tooltip>&nbsp;
                <TextField source="agEuroCode"/>&nbsp;
                <Tooltip title="Helyettesítő kódok">
                    <MultipleStopIcon fontSize="'inherit'" onClick={() => handleClickOpen(record.substitutionCodes)}
                                      style={{cursor: 'pointer'}}/>
                </Tooltip>
                {
                    record.note != null ? <>&nbsp;<Tooltip title={record.note}>
                        <CommentOutlinedIcon fontSize="inherit" style={{cursor: 'pointer'}}/>
                    </Tooltip></> : null
                }
        </span>
        );
    }

    const columns = [
        {
            field: 'id',
            headerName: 'id',
            hide: true
            //valueGetter: (params) => params.row.product.euroCode
        },
        {
            field: 'agEuroCode',
            headerName: 'Eurokód',
            width: 170,
            renderCell: (params) => {
                return (
                    <span>
                <Tooltip title="Vágólapra másolás">
                    <ContentCopyIcon fontSize="inherit" style={{cursor: 'pointer'}} onClick={() => {
                        navigator.clipboard.writeText(params.value)
                    }}/>
                </Tooltip>&nbsp;{params.value}
            </span>
                );
            }
        },
        {
            field: 'agBrand',
            headerName: 'Jármű gyártó',
            width: 150,
            //valueGetter: (params) => params.row.product.euroCode
        },
        {
            field: 'agModel',
            headerName: 'Model',
            width: 180,
            //valueGetter: (params) => params.row.product.euroCode
        },
        {
            field: 'agStartYear',
            headerName: 'Évtől',
        },
        {
            field: 'agEndYear',
            headerName: 'Évig',
        },
        {
            field: 'agDescription',
            headerName: 'Leírás',
        },
        {
            field: 'vehicleManufacturer',
            headerName: 'Jármű gyártó',
        },
        {
            field: 'vehicleModel',
            headerName: 'Model',
        },
        {
            field: 'yearFrom',
            headerName: 'Évtől',
        },
        {
            field: 'yearTo',
            headerName: 'Évig',
        },
        {
            field: 'glassManufacturer',
            headerName: 'Üveg gyártó',
        },
        {
            field: 'price',
            headerName: 'Kisker ár (bruttó)',
        },
        {
            field: 'wholesalePrice',
            headerName: 'Nagyker ár (nettó)',
        },
        {
            field: 'wholesaleDiscountPrice',
            headerName: 'Kedvezményes nagyker ár (nettó)',
        },
        {
            field: 'storage',
            headerName: 'Raktár',
        },
        {
            field: 'stand',
            headerName: 'Állvány',
        },
        {
            field: 'level',
            headerName: 'Szint',
        },
        {
            field: 'shelf',
            headerName: 'Polc',
        },
        {
            field: 'qty',
            headerName: 'Mennyiség'
        },
        {
            field: 'reservedQty',
            headerName: 'Foglalt'
        }
    ];

    return (<>
            <List filters={postFilters} filterDefaultValues={{isX: false}} perPage={50}
                  pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} debounce={2000} />}>
                <ReactAdminDataGrid bulkActionButtons={false} rowStyle={postRowStyle}>
                    <TextField source="id" label={'search.id'}/>
                    <FunctionField source="agEuroCode" label={'search.euroCode'} render={renderSubstitutionCode}/>
                    <TextField source="agBrand" label={'search.brand'}/>
                    <TextField source="agModel" label={'search.model'}/>
                    <TextField source="agStartYear" label={'search.startYear'}/>
                    <TextField source="agEndYear" label={'search.endYear'}/>
                    <TextField source="agDescription" label={'search.description'}/>
                    <TextField source="vehicleManufacturer" label={'search.brand'}/>
                    <TextField source="vehicleModel" label={'search.model'}/>
                    <TextField source="yearFrom" label={'search.startYear'}/>
                    <TextField source="yearTo" label={'search.endYear'}/>
                    <TextField source="glassManufacturer" label={'search.glass'}/>
                    <TextField source="price" label={'search.price'}/>
                    <TextField source="wholesalePrice" label={'search.wholesalePrice'}/>
                    <TextField source="wholesaleDiscountPrice" label={'search.wholesaleDiscountPrice'}/>
                    <TextField source="storage" label={'search.storage'}/>
                    <TextField source="stand" label={'search.stand'}/>
                    <TextField source="level" label={'search.level'}/>
                    <TextField source="shelf" label={'search.shelf'}/>
                    <TextField source="qty" label={'search.qty'}/>
                    <FunctionField source="reservedQty" label={'search.reservedQty'} render={renderReservedQty}/>
                </ReactAdminDataGrid>
            </List>
            <Dialog
                maxWidth={false}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">Helyettesítő termékek</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {substitutionCodes.join(', ')}
                    </DialogContentText>
                    <div style={{ height: 500, width: 1800 }}>
                    <ThemeProvider theme={theme} >
                    <DataGrid rowsPerPageOptions={[5, 10, 20, 100]}
                              rows={dialogData}
                              columns={columns}
                              disableSelectionOnClick />
                    </ThemeProvider>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Bezárás
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default SearchList