import React from 'react';
import {Edit, SimpleForm, Toolbar, SaveButton} from "react-admin";
import ProductDetails from "./ProductDetails";

const PostEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton/>
    </Toolbar>
);

const ProductEdit = (props) => {
    return (
        <Edit {...props} title={'product.edit'}>
            <SimpleForm toolbar={<PostEditToolbar />}>
                <ProductDetails isEdit={true} />
            </SimpleForm>
        </Edit>
    )

}
export default ProductEdit