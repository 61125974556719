import React from 'react';
import {Create} from 'react-admin';
import ShuffleDetails from "./ShuffleDetails";

const ShuffleCreate = (props) => {
    return (
        <Create {...props} title={'outgoing.create'}>
            <ShuffleDetails />
        </Create>
    )
}

export default ShuffleCreate