import React, {useState} from 'react';
import {
    TextInput,
    SimpleForm,
    SelectInput,
    FormDataConsumer,
    required,
    DateInput,
    RadioButtonGroupInput,
    minValue,
    useNotify,
    List,
    Pagination,
    TextField,
    useUnselectAll,
    SearchInput, useRecordContext, useDataProvider
} from "react-admin";
import {ProductToInvoiceList} from "../../components/ProductToInvoiceList";
import {Box, Button, Dialog, DialogContent, DialogTitle, Grid, Tab, Typography} from "@mui/material";
import {useFormContext} from "react-hook-form";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {Datagrid as ReactAdminDataGrid} from "ra-ui-materialui/dist/cjs/list/datagrid/Datagrid";
import {ProductSelectInvoiceBulkAction} from "../../components/ProductSelectInvoiceBulkAction";
import {ProductSelectInvoiceUnStockableBulkAction} from "../../components/ProductSelectInvoiceUnStockableBulkAction";

const SaleDetails = () => {
    const notify = useNotify();
    const { setValue } = useFormContext();
    const [open, setOpen] = useState(false);

    const unselectAll = useUnselectAll('customer');
    const [filteredIds, setFilteredIds] = useState([])
    const filters =  [<SearchInput source="q" alwaysOn resettable={true}/>]

    const openDialog = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false)
    }

    const NoCustomers = () => <Typography>Még nincsenek törzsvásárlók</Typography>;

    function SelectField(props) {
        const record = useRecordContext();
        const select = (record) => {
            console.log("customer_id", record.id)
            setValue("name", record.name)
            setValue("postCode", record.zipCode)
            setValue("city", record.settlement)
            setValue("address", record.address)
            if(record.taxNumber){
                setValue("taxType", 'HAS_TAX_NUMBER')
                setValue("taxNumber", record.taxNumber)
            }else{
                setValue("taxType", 'NO_TAX_NUMBER')
            }
            setValue("discount", record.discount)
            setOpen(false)
        }
        return (
            <Button onClick={() => select(record)}>Kiválasztás</Button>
        );
    }
    const taxType = [
        { id: 'HAS_TAX_NUMBER', name: 'Adószámmal rendelkezik (Belföldi ÁFA alany)' },
        { id: 'NO_TAX_NUMBER', name: 'Adószámmal nem rendelkezik (Természetes személy)' },
        { id: 'FOREIGN', name: 'Külföldi, egyéb' },
    ];

    const paymentMethod = [
        { id: 'cash', name: 'Készpénz' },
        { id: 'bankcard', name: 'Bankkártya' },
        { id: 'wire_transfer', name: 'Átutalás' },
    ];

    const paymentMethodChange = (payment) => {
        const now = new Date();
        if (payment.target.value === 'wire_transfer') {
            now.setDate(now.getDate()+8)
            setValue('dueDate',now)
      }
      else {
            setValue('dueDate',now)
      }
    }


    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Box sx={{ width: '50%' }}>
                        <h2>Vevő adatok</h2>
                        <Button variant={"contained"}  onClick={openDialog}>Törzsvásárló kiválasztása</Button>
                        <TextInput source="name" label={"partner.name"} validate={required()} inputProps={{maxLength: 255}} fullWidth/>
                        <TextInput source="postCode" label={"partner.zipCode"} validate={required()} inputProps={{maxLength: 255}} fullWidth/>
                        <TextInput source="city" label={"partner.settlement"} validate={required()} inputProps={{maxLength: 255}} fullWidth/>
                        <TextInput source="address" label={"partner.address"} validate={required()} inputProps={{maxLength: 255}} fullWidth/>
                        <RadioButtonGroupInput source="taxType" choices={taxType} defaultValue={'HAS_TAX_NUMBER'} row={false} label={"Partner adózási típusa"} validate={required()} />
                        <TextInput source="taxNumber" label={"partner.taxNumber"} inputProps={{maxLength: 255}} fullWidth/>
                        <TextInput source="discount" label={"customer.discount"}  defaultValue={0} inputProps={{maxLength: 255}} fullWidth/>
                        <Typography>A kedvezmény csak a termékekre vonatkozik a szolgáltatásra nem. A kedvezményes ár csak a számlán látható.</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ width: '50%' }}>
                        <h2>Alap adatok</h2>
                        <DateInput source="createDate" label="Kelt" validate={required()} disabled defaultValue={new Date()}  fullWidth/>
                        <DateInput source="fulfillmentDate" label="Teljesítés időpontja" validate={required()} defaultValue={new Date()} fullWidth/>
                        <DateInput source="dueDate" label="Fizetési határidő" validate={required()} defaultValue={new Date()} fullWidth/>
                        <SelectInput source="paymentMethod" choices={paymentMethod} label="Fizetési mód" defaultValue="cash" validate={required()} onChange={paymentMethodChange} fullWidth/>

                        <TextInput source="note" multiline fullWidth label={"incoming.note"}/>
                    </Box>
                </Grid>
            </Grid>
            <h2>Termék/szolgáltatás hozzáadása a számlához</h2>
            <FormDataConsumer>
                {({ formData }) => (
                    <ProductToInvoiceList formData={formData} />
                )
                }
            </FormDataConsumer>
            <Dialog open={open} fullWidth={true} scroll={"paper"} maxWidth={"xl"} onClose={handleClose} >
                <DialogTitle id="alert-dialog-title">
                    Törzsvásárló hozzáadása a számlához
                </DialogTitle>
                <DialogContent>
                    <List perPage={50} empty={<NoCustomers />} pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />} resource={"customer"} filters={filters} filter={{excludedIds: filteredIds}}>
                        <ReactAdminDataGrid optimized={true} bulkActionButtons={false} >
                            <TextField source="id" label={'customer.id'}/>
                            <TextField source="name" label={'customer.name'}/>
                            <TextField source="zipCode" label={'customer.zipCode'}/>
                            <TextField source="settlement" label={'customer.settlement'}/>
                            <TextField source="address" label={'customer.address'}/>
                            <TextField source="taxNumber" label={'customer.taxNumber'}/>
                            <TextField source="discount" label={'customer.discount'}/>
                            <SelectField>Kiválasztás</SelectField>
                        </ReactAdminDataGrid>
                    </List>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default SaleDetails