import React from 'react';
import {
    TextInput,
    BooleanInput,
    SimpleForm,
    required,
} from "react-admin";
import {Button} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const PartnerDetails = () => {
    return (
        <SimpleForm>
            <TextInput source="name" label={"partner.name"} validate={required()} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
            <TextInput source="zipCode" label={"partner.zipCode"} validate={required()} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
            <TextInput source="settlement" label={"partner.settlement"} validate={required()} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
            <TextInput source="address" label={"partner.address"} validate={required()} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
            <TextInput source="taxNumber" label={"partner.taxNumber"} validate={required()} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
            <TextInput source="phone" label={"partner.phone"} validate={required()} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
            <TextInput source="email" label={"partner.email"} validate={required()} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
            <TextInput source="discount" label={"partner.discount"} validate={required()} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
            <BooleanInput source="webshopAccess" label={"partner.webshopAccess"}/>
        </SimpleForm>
    )
}

export default PartnerDetails