import React from 'react';
import {Create} from 'react-admin';
import EmployeeDetails from "./EmployeeDetails";

const EmployeeCreate = (props) => {
    return (
        <Create {...props} title={'employee.create'}>
            <EmployeeDetails />
        </Create>
    )
}

export default EmployeeCreate