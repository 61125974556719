import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import StoreRequestList from "./StoreRequestList";
import StoreRequestCreate from "./StoreRequestCreate";
import StoreRequestEdit from "./StoreRequestEdit";

const StoreRequest =  {
    list: StoreRequestList,
    //create: StoreRequestCreate,
    //edit: StoreRequestEdit,
    icon: OpenInNewIcon
}
export default StoreRequest