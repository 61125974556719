import * as React from "react";
import {
    Button, useDataProvider,
    useListContext, useTranslate, useUnselectAll,
} from 'react-admin';
import {useFormContext} from "react-hook-form";
import {randomNumberBetween} from "@mui/x-data-grid/utils/utils";

export const ProductSelectBulkAction = ({current, setOpen}) => {
    const { selectedIds, data} = useListContext();
    const { setValue } = useFormContext();
    const translate = useTranslate()
    const unselectAll = useUnselectAll('product');
    const dataProvider = useDataProvider()

    function generateRandom() {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    async function getManufacturer(id) {
        await dataProvider.get("product/glassManufacturer/" + id, {size: 200}).then(x => {
            console.log(x.data.manufacturer)
            return x.data.manufacturer
        })
    }

    const updateMany = () => {
        dataProvider.get("product", {size: 50}).then(x => {
            const filteredData = x.data.content.filter(item => selectedIds.includes(item.id))
            const aa = filteredData.map((x) => {  return {productId: x.id, name: x.name, euroCode: x.euroCode, /*manufacturer: getManufacturer(x.manufacturer),*/ price: x.price, id: generateRandom() }})
            setValue('products', current.concat(aa))
        })

        setOpen(false)
        unselectAll()
    }


    return (
        <Button
            label= "Termék hozzáadása raktárhoz"
            onClick={updateMany}
        />
    );
};