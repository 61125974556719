import React from 'react';
import {Edit} from "react-admin";
import StoreRequestDetails from "./StoreRequestDetails";

const StoreRequestEdit = (props) => {
    return (
        <Edit {...props} title={'storeRequest.title'}>
            <StoreRequestDetails />
        </Edit>
    )

}
export default StoreRequestEdit