import React from 'react';
import {
    TextField,
    List,
    DateField,
    FunctionField,
    ReferenceField,
    SimpleShowLayout,
    Show,
    Pagination,
    Datagrid as ReactAdminDataGrid,
    useTranslate, useRecordContext
} from "react-admin";
import {useParams} from "react-router";

const PostTitle = () => {
    const record = useRecordContext();
    // the record can be empty while loading
    if (!record) return null;
    return <span>Bevételezés #{record.id}</span>;
};


const IncomingShow = () => {
    const { id } = useParams(); // this component is rendered in the /books/:id path
    const translate = useTranslate()

    const choices = [
        { id: 'P', name: 'incoming.category.partner' },
        { id: 'R', name: 'incoming.category.return' },
        { id: 'S', name: 'incoming.category.storeCorrection' },
        { id: 'O', name: 'incoming.category.other' },
    ];

    const categoryRender = (data) => {
        return translate(choices.find(choice => choice.id === data.category).name)
    }

    return (
        <Show>
            <SimpleShowLayout>
                <FunctionField source="category" label="incoming.categoryName" render={categoryRender}/>
                <TextField source="note" label={'incoming.note'}/>
                <ReferenceField label={'site.title'}  reference={"site"} link={false} source={"siteId"}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label={'store.title'}  reference={"storage"} link={false} source={"storeId"}>
                    <TextField source="name" />
                </ReferenceField>
                <DateField source="incomingDate" label="incoming.incomingDate"/>
                <DateField source="orderDate" label="incoming.orderDate"/>
                <DateField source="createdAt" label="incoming.createdAt" showTime/>
                <ReferenceField label={'incoming.createdBy'} reference={"employee/getAll"} link={false} source={"createdBy"}>
                    <TextField source="name"/>
                </ReferenceField>

                <List perPage={200} pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />} resource={"incoming/product/"+id} actions={false} title={PostTitle}>
                    <ReactAdminDataGrid optimized={true} bulkActionButtons={false} >
                        <TextField source="id" label={'incoming.id'} />
                        <TextField source="name" label={'order.productName'}/>
                        <TextField source="euroCode" label={'product.euroCode'}/>
                        <TextField source="qty" label={'incoming.qty'}/>
                        <TextField source="stand" label={'incoming.stand'}/>
                        <TextField source="level" label={'incoming.level'}/>
                        <TextField source="shelf" label={'incoming.shelf'}/>
                    </ReactAdminDataGrid>
                </List>
            </SimpleShowLayout>
        </Show>
    );
}

export default IncomingShow