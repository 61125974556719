import React from 'react';
import {Edit} from "react-admin";
import SaleDetails from "./SaleDetails";

const SaleEdit = (props) => {
    return (
        <Edit {...props} title={'products.create'}>
            <SaleDetails />
        </Edit>
    )

}
export default SaleEdit