import * as React from 'react';
import {DataGrid, huHU} from '@mui/x-data-grid';
import {useFormContext} from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {useState} from "react";
import EditIcon from "@mui/icons-material/Edit";
import {
    number,
    minValue,
    useTranslate,
    List,
    useNotify,
    ReferenceField,
    Pagination,
    useDataProvider
} from "react-admin";
import {Button, Dialog, DialogContent, DialogTitle, Grid, TextField, Typography} from "@mui/material";
import {Datagrid as ReactAdminDataGrid} from "ra-ui-materialui/dist/cjs/list/datagrid/Datagrid";
import {ProductSelectIncomingBulkAction} from "./ProductSelectIncomingBulkAction";
import {useMediaQuery} from "@material-ui/core";


export default function EditIncomingDataGrid({rows}) {

    const notify = useNotify()
    const theme = createTheme(
        {
            palette: {
                primary: { main: '#1976d2' },
            },
        },
        huHU,
    );

    const columns = [
        {
            field: 'id',
            headerName: 'id',
            //hide: true
            //valueGetter: (params) => params.row.product.euroCode
        },
        {
            field: 'productId',
            headerName: 'productId',
            //hide: true
            //valueGetter: (params) => params.row.product.euroCode
        },
        {
            field: 'name',
            headerName: 'Termék neve',
            width: 250,
            //valueGetter: (params) => params.row.product.euroCode
        },
        {
            field: 'euroCode',
            headerName: 'Euro kód',
            width: 180,
            //valueGetter: (params) => params.row.product.euroCode
        },
        {
            field: 'glassManufacturer',
            headerName: 'Üveg gyártó',
        },
        {
            field: 'vehicleManufacturer',
            headerName: 'Jármű gyártó',
        },
        {
            field: 'vehicleModel',
            headerName: 'Model',
        },
        {
            field: 'yearFrom',
            headerName: 'Évtől',
        },
        {
            field: 'yearTo',
            headerName: 'Évig',
        },
        {
            field: 'price',
            headerName: 'Ár',
            type: "number",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return '';
                }else{
                    return `${params.value} Ft`
                }
            }

        },
        {
            field: 'stand',
            headerName: 'Állvány',
            //valueGetter: (params) => params.row.product.manufacturer
        },
        {
            field: 'level',
            headerName: 'Szint',
            //valueGetter: (params) => params.row.product.manufacturer
        },
        {
            field: 'shelf',
            headerName: 'Polc',
            //valueGetter: (params) => params.row.product.manufacturer
        },
        {
            field: 'qty',
            headerName: 'Bevételezendő mennyiség',
            editable: true
            //valueGetter: (params) => params.row.product.manufacturer
        },
        {
            field: "editAction",
            headerName: "Módosítás",
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    setOpen(true)
                    //setReferenceUrl('inventory/productId'+params.row.productId)
                    updateMany(params.row.productId)
                    setEditValue(params.row)
                };

                return <Button style={{color: "#1976d2"}} onClick={onClick}><EditIcon/></Button>;
            }
        },
        {
            field: "action",
            headerName: "Törlés",
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const newRows = rows.filter(row => {
                        return row.id !== params.id
                    })
                    setValue('products', newRows)

                };

                return <Button style={{color: "#1976d2"}} onClick={onClick}><DeleteIcon/></Button>;
            }
        },

    ];

    const columnsDialog = [
        {
            field: 'id',
            headerName: 'id',
            hide: true
        },
        {
            field: 'site',
            headerName: 'Telephely',

        },
        {
            field: 'store',
            headerName: 'Raktár',

        },
        {
            field: 'qty',
            headerName: 'Mennyiség',

        },
        {
            field: 'stand',
            headerName: 'Állvány',
         },
        {
            field: 'level',
            headerName: 'Szint',
        },
        {
            field: 'shelf',
            headerName: 'Polc',
        },
    ];

    const { setValue } = useFormContext();
    const [open, setOpen] = useState(false)
    const [newQty, setNewQty] = useState(null)
    const [newStand, setNewStand] = useState(null)
    const [newLevel, setNewLevel] = useState(null)
    const [newShelf, setNewShelf] = useState(null)
    const [editValue, setEditValue] = useState(null)
    const translate = useTranslate()
    const [referenceUrl, setReferenceUrl] = useState(null)
    const [dialodRows, setDialodRows] = useState(null)
    const dataProvider = useDataProvider()

    const updateMany = (productId) => {
        dataProvider.get('inventory/productId/'+productId, {size: 50}).then(x => {
            //const filteredData = x.data.content.filter(item => selectedIds.includes(item.id))
            const aa = x.data.content.map((x) => {  return {productId: x.productId, name: x.name, euroCode: x.euroCode,
                glassManufacturer: x.glassManufacturer, vehicleManufacturer: x.vehicleManufacturer, vehicleModel: x.vehicleModel,
                yearFrom: x.yearFrom, yearTo: x.yearTo, price: x.price, oldQty: x.qty, stand: x.stand, level: x.level, shelf: x.shelf, id: x.id }})
            setDialodRows(aa)
        })

    }


    const processRowUpdate = React.useCallback(
        async (newRow) => {
            const newRows = rows.map(r => (r.id === newRow.id) ? {...r, qty: newRow.qty, stand: newRow.stand, level: newRow.level, shelf: newRow.shelf} : r )
            setValue('products', newRows)
        },
        [],
    );

    const handleClickQty = (event) => {
        //if()
        const newRows = rows.map(row => {
            if(row.id !== editValue.id){
                return row
            }else{
                console.log("qqq",row, newQty)
                return {...row, qty: parseInt(newQty), stand: newStand, level: newLevel, shelf: newShelf}
            }
        })
        setValue('products', newRows)
        setOpen(false)

    };

    const onProcessRowUpdateError = (error) => {
        console.log(error)
    }

    const handleKeypressQty = e => {
        //it triggers by pressing the enter key
        if (e.key === "Enter") {
            handleClickQty(e)
        }
    };


    const handleDialogClose = () => {
        setOpen(false)
        setNewQty(null)
    }


    const handleTextChangeQty = (event) => {
        if(event.target.value<0){
            notify('hiba', {type:'error'})
        }{
            setNewQty(event.target.value)
        }

    }

    const handleTextChangeStand= (event) => {
            setNewStand(event.target.value)
    }

    const handleTextChangeLevel = (event) => {
            setNewLevel(event.target.value)
    }

    const handleTextChangeShelf = (event) => {
            setNewShelf(event.target.value)
    }


    const QtyCheck = () => {
        return !newQty || newQty <= 0 || newQty > editValue.oldQty;
    }


    const NoProducts = () => <Typography>Még nincs termék a listán</Typography>;
    const fullWidth = 'lg';
    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleDialogClose} fullWidth={fullWidth}>
                <DialogTitle >
                    Bevételezendő adatok
                </DialogTitle>
                <DialogContent style={{width: 700 }}>
                <div>

                    <Grid container spacing={2}>
                        {/*<Grid xs={12}>
                            <div style={{ height: 200, width: 700 }}>
                                <DataGrid
                                    rows={dialodRows}
                                    columns={columnsDialog}
                                />
                            </div>
                        </Grid>*/}
                        <Grid xs={12}>
                            <TextField label={translate("incoming.qty")} type={"number"} autoFocus
                                       defaultValue={0}  onWheel={(e) => e.target.blur()}
                                /*onKeyPress={handleKeypressQty} */ onChange={handleTextChangeQty} />
                        </Grid>
                        <Grid xs={3}>
                            <TextField label={translate("incoming.stand")} onWheel={(e) => e.target.blur()}
                                /*onKeyPress={handleKeypressQty} */ onChange={handleTextChangeStand}  />
                        </Grid>
                        <Grid xs={3}>
                            <TextField label={translate("incoming.level")} onWheel={(e) => e.target.blur()}
                                /*onKeyPress={handleKeypressQty} */ onChange={handleTextChangeLevel}  />
                        </Grid>
                        <Grid xs={3}>
                            <TextField label={translate("incoming.shelf")} onWheel={(e) => e.target.blur()}
                                /*onKeyPress={handleKeypressQty} */ onChange={handleTextChangeShelf}  />
                        </Grid>
                    </Grid>




                    <div style={{marginTop: "16px", display:"flex", justifyContent: "space-between"}}>
                        <Button style={{paddingRight: "8px", color: "white"}} variant={"contained"} color={"primary"} disabled={QtyCheck()} onClick={handleClickQty}>{translate("incoming.modify")}</Button>
                        <Button variant={"outlined"} color={"secondary"} onClick={handleDialogClose}>{translate("incoming.cancel")}</Button>
                    </div>
                </div>
                </DialogContent>
            </Dialog>

            <div style={{ height: 500, width: 1700 }}>
                <ThemeProvider theme={theme} >
                    <DataGrid
                        rowsPerPageOptions={[5, 10, 20, 100]}
                        rows={rows}
                        columns={columns}
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                        processRowUpdate={processRowUpdate}
                        onProcessRowUpdateError={onProcessRowUpdateError}
                    />
                </ThemeProvider>
            </div>
        </React.Fragment>
    );
}