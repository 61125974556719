import dataProvider from "./dataProvider";

const customDataProvider = {
    ...dataProvider,
    update: (resource, params) => {
        if (resource !== 'worksheet') {
            // fallback to the default implementation
            return dataProvider.update(resource, params);
        }

        /**
         * For posts update only, convert uploaded image in base 64 and attach it to
         * the `picture` sent property, with `src` and `title` attributes.
         */

            // Freshly dropped pictures are File objects and must be converted to base64 strings
        let newPictures;
        let formerPictures;
        if (params.data.pictures != null) {
            newPictures = params.data.pictures.filter(
                p => p.rawFile instanceof File
            );
            formerPictures = params.data.pictures.filter(
                p => !(p.rawFile instanceof File)
            );
        }
        else {
            newPictures = [];
            formerPictures = [];
        }

        return Promise.all(newPictures.map(convertFileToBase64))
            .then(base64Pictures =>
                base64Pictures.map((picture64, index) => ({
                    src: picture64,
                    title: newPictures[index].title,
                }))
            )
            .then(transformedNewPictures =>
                dataProvider.update(resource, {
                    id: params.id,
                    data: {
                        ...params.data,
                        pictures: [
                            ...transformedNewPictures,
                            ...formerPictures,
                        ],
                    },
                })
            );
    },
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file.rawFile);
    });

export default customDataProvider;