import React, {useEffect, useState} from 'react';
import {
    TextField,
    List,
    TextInput,
    SearchInput, useTranslate, useUnselectAll, Pagination, ReferenceField,
} from 'react-admin';
import {Box, Button, Card, CircularProgress, Dialog, DialogContent, DialogTitle, Tab, Typography} from '@mui/material';
import {Datagrid as ReactAdminDataGrid} from "react-admin";

import EditServiceDataGrid from "./EditServiceDataGrid";
import AddIcon from "@mui/icons-material/Add";
import {ProductSelectServiceBulkAction} from "./ProductSelectServiceBulkAction";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {ProductSelectInvoiceBulkAction} from "./ProductSelectInvoiceBulkAction";
import {ProductSelectInvoiceUnStockableBulkAction} from "./ProductSelectInvoiceUnStockableBulkAction";


export const ProductServiceStoreList = (formData) => {
    const translate = useTranslate()
    const unselectAll = useUnselectAll("product/storageId/"+5);
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [open, setOpen] = useState(false);
    const openAddDialog = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false)
        unselectAll()
    }

    const NoProducts = () => <Typography>Még nincs termék a listán</Typography>;

    const products = (formData.formData?.products) ? formData.formData.products : []
    const [filteredIds, setFilteredIds] = useState([])
    const [filteredUnStockableIds, setFilteredUnStockableIds] = useState([])

    useEffect(() => {
        if(formData.formData.products) {
            setFilteredIds(formData.formData.products.map(product => product.id))
        }
    }, [products]);

    const ListItems = () => {
        if(products && products.length > 0) {
            return (
                <EditServiceDataGrid rows={products} />
            )
        }else{
            return <div> Jelenleg nincs egy termék se a listában </div>
        }
    }

    const filters =  [<SearchInput source="q" alwaysOn resettable={true}/>,
        <TextInput source="stand" label={'incoming.stand'} alwaysOn resettable={true}/>,
        <TextInput source="level" label={'incoming.level'} alwaysOn resettable={true}/>,
        <TextInput source="shelf" label={'incoming.shelf'} alwaysOn resettable={true}/>]
    return (
        <Box>
            <Button style={{marginBottom: "16px", color: "white"}} variant={"contained"} color={"primary"} onClick={openAddDialog}>
                <AddIcon />  Termék beépítése
            </Button>
            <Dialog open={open} fullWidth={true} scroll={"paper"} maxWidth={"xl"} onClose={handleClose} >
                <DialogTitle id="alert-dialog-title">
                    Termék beépítése
                </DialogTitle>
                <DialogContent>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="tabs product/service">
                                <Tab label="Termékek" value="1" />
                                <Tab label="Szolgáltatások" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <div style={{maxHeight: "700px"}}>
                                <List perPage={50} empty={<NoProducts />} pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />} resource={"product/storageId/5"} filters={filters} filter={{excludedIds: filteredIds}}>
                                    <ReactAdminDataGrid optimized={true} bulkActionButtons={<ProductSelectServiceBulkAction current={products} setOpen={setOpen} formData={formData} />} >
                                        <TextField source="id" label={'product.id'}/>
                                        <TextField source="name" label={'product.name'}/>
                                        <TextField source="euroCode" label={'product.euroCode'}/>
                                        <TextField source="glassManufacturer" label={'product.glassManufacturer'}/>
                                        <TextField source="vehicleManufacturer" label={'product.vehicleManufacturer'}/>
                                        <TextField source="vehicleModel" label={'product.vehicleModel'}/>
                                        <TextField source="yearFrom" label={'product.yearFrom'}/>
                                        <TextField source="yearTo" label={'product.yearTo'}/>
                                    </ReactAdminDataGrid>
                                </List>
                            </div>
                        </TabPanel>
                        <TabPanel value="2">
                            <div style={{maxHeight: "700px"}}>
                                <List perPage={50} empty={<NoProducts />} pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />} resource={"product/unstockable"} filters={filters} filter={{excludedIds: filteredUnStockableIds}}>
                                    <ReactAdminDataGrid optimized={true} bulkActionButtons={<ProductSelectInvoiceUnStockableBulkAction current={products} setOpen={setOpen} formData={formData} />} >
                                        <TextField source="id" label={'product.id'}/>
                                        <TextField source="name" label={'product.name'}/>
                                        <TextField source="euroCode" label={'product.euroCode'}/>
                                        <TextField source="glassManufacturer" label={'product.glassManufacturer'}/>
                                        <TextField source="vehicleManufacturer" label={'product.vehicleManufacturer'}/>
                                        <TextField source="vehicleModel" label={'product.vehicleModel'}/>
                                        <TextField source="yearFrom" label={'product.yearFrom'}/>
                                        <TextField source="yearTo" label={'product.yearTo'}/>
                                    </ReactAdminDataGrid>
                                </List>
                            </div>
                        </TabPanel>
                    </TabContext>

                </DialogContent>
            </Dialog>
            <ListItems />
        </Box>
    );
};