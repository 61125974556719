import {useNotify, useTranslate, Form, useRedirect} from "react-admin";
import {useState} from "react";
import {Grid, Stack, TextField} from "@mui/material";
import Button from "@material-ui/core/Button";
import {Auth} from "aws-amplify";
import {makeStyles} from "@material-ui/core/styles";
import {Divider} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    },
    form: {
        padding: theme.spacing(4)
    }
}));


const ChangePassword= () => {
    const classes = useStyles()
    const translate = useTranslate()
    const notify = useNotify()
    const redirect = useRedirect()
    const token = localStorage.getItem("token")
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const handleSubmit = e => {
        console.log(token);
        //Auth.currentSession().then(user => {
        Auth.currentAuthenticatedUser().then(user => {
            console.log(user);
            Auth.changePassword(user, oldPassword, newPassword)
                .then(data => {
                    console.log(data);
                    notify("signUp.passwordChanged")
                    redirect("/")})
                .catch(err => {
                    console.log(err);
                    if(err.code === 'NotAuthorizedException'){
                        notify('signUp.notAuthorizedException', { type: 'error' })
                    }
                    if(err.message.includes('Password does not conform to policy', { type: 'error' })){
                        notify('signUp.passwordPolicy', { type: 'error' })
                    }
                    if(err.code==='ExpiredCodeException'){
                        notify('signUp.expiredCodeException', { type: 'error' })
                    }
                    if(err.code==='LimitExceededException'){
                        notify('signUp.limitExceededException', { type: 'error' })
                    }
                })
        }).then(data => {
            console.log('success with data: ' + data);
        }).catch(err => {
            console.log(err);
        })};


    return(
 <Stack className={classes.root} divider={<Divider orientation="horizontal" flexItem />}
        spacing={2} >
        <Form onSubmit={handleSubmit}>
            <h2>{translate('signUp.changePassword')}</h2>
        <Grid rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}>
                <TextField required id="oldPassword" label={translate('signUp.oldPassword')} variant="filled" type="password" value={oldPassword} onChange={e => setOldPassword(e.target.value)}  style = {{width: 400}}/>
            </Grid>
            <Grid item xs={12}>
                <TextField required id="newPassword" label={translate('signUp.newPassword')} variant="filled" type="password" value={newPassword} inputProps={{ inputMode: 'text', pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$' }} helperText={translate('signUp.passwordHint')} onChange={e => setNewPassword(e.target.value)}  style = {{width: 400}}/>
            </Grid>
            <Grid item xs={12}>
                <TextField required id="confirmPassword" label={translate('signUp.confirmPassword')} variant="filled" type="password" value={confirmPassword} inputProps={{ inputMode: 'text', pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$' }} onChange={e => setConfirmPassword(e.target.value)}  style = {{width: 400}}/>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" type="submit" color="primary">{translate('signUp.save')}</Button>
            </Grid>
        </Grid>
    </Form>
</Stack>
    )

}

export default ChangePassword