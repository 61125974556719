import React, {useEffect, useState} from 'react';
import {
    TextField,
    List,
    SearchInput, useTranslate, useUnselectAll, Pagination, ReferenceField,
} from 'react-admin';
import {Box, Button, Card, CircularProgress, Dialog, DialogContent, DialogTitle, Typography} from '@mui/material';
import {Datagrid as ReactAdminDataGrid} from "react-admin";
import {DataGrid, huHU} from '@mui/x-data-grid';
import EditIncomingDataGrid from "./EditIncomingDataGrid";
import AddIcon from '@mui/icons-material/Add';
import {ProductSelectIncomingBulkAction} from "./ProductSelectIncomingBulkAction";

import {EmptyPage} from "./EmptyPage";


export const ProductToStoreList = (formData) => {
    const translate = useTranslate()
    const unselectAll = useUnselectAll('product/incoming');

    const [open, setOpen] = useState(false);
    const openAddDialog = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false)
        unselectAll()
    }

    const NoProducts = () => <Typography>Még nincs termék a listán</Typography>;

    const products = (formData.formData?.products) ? formData.formData.products : []
    const [filteredIds, setFilteredIds] = useState([])

    useEffect(() => {
        if(formData.formData.products) {
            setFilteredIds(formData.formData.products.map(product => product.id))
        }
    }, [products]);

    const ListItems = () => {
        if(products && products.length > 0) {
            return (
                <EditIncomingDataGrid rows={products} />
            )
        }else{
            return <div> Jelenleg nincs egy termék se a listában </div>
        }
    }

    const filters =  [<SearchInput source="q" alwaysOn resettable={true}/>]
    return (
        <Box>
            <Button style={{marginBottom: "16px", color: "white"}} variant={"contained"} color={"primary"} onClick={openAddDialog}>
                <AddIcon />  Termék hozzáadása a raktárhoz
            </Button>
            <Dialog open={open} fullWidth={true} scroll={"paper"} maxWidth={"xl"} onClose={handleClose} >
                <DialogTitle id="alert-dialog-title">
                    Termék hozzáadása a raktárhoz
                </DialogTitle>
                <DialogContent>
                    <div style={{maxHeight: "700px"}}>

                        <List perPage={50} empty={<NoProducts />} pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />} resource={"product/incoming"} filters={filters} filter={{excludedIds: filteredIds}}>
                            <ReactAdminDataGrid optimized={true} bulkActionButtons={<ProductSelectIncomingBulkAction current={products} setOpen={setOpen} />} >
                                <TextField source="id" label={'product.id'}/>
                                <TextField source="name" label={'product.name'}/>
                                <TextField source="euroCode" label={'product.euroCode'}/>
                                <TextField source="glassManufacturer" label={'product.glassManufacturer'}/>
                                <TextField source="vehicleManufacturer" label={'product.vehicleManufacturer'}/>
                                <TextField source="vehicleModel" label={'product.vehicleModel'}/>
                                <TextField source="yearFrom" label={'product.yearFrom'}/>
                                <TextField source="yearTo" label={'product.yearTo'}/>
                                <TextField source="price" label={'product.price'}/>
                            </ReactAdminDataGrid>
                        </List>
                    </div>
                </DialogContent>
            </Dialog>
            <ListItems />
        </Box>
    );
};