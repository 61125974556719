import * as React from "react";
import {
    Button, useDataProvider,
    useListContext, useNotify, useRefresh, useTranslate, useUnselectAll,
} from 'react-admin';
import {useFormContext} from "react-hook-form";
import {randomNumberBetween} from "@mui/x-data-grid/utils/utils";

export const StoreRequestBulkAction = ({current, setOpen, formData}) => {
    const { selectedIds, data} = useListContext();
    const refresh = useRefresh();
    const notify = useNotify();
    const translate = useTranslate()
    const unselectAll = useUnselectAll('storerequest');
    const dataProvider = useDataProvider()

    function generateRandom() {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    /*async function getManufacturer(id) {
        await dataProvider.get("product/glassManufacturer/" + id, {size: 200}).then(x => {
            console.log(x.data.manufacturer)
            return x.data.manufacturer
        })
    }*/

    const updateMany = () => {
        //dataProvider.get('product/incoming', {size: 50}).then(x => {
            const filteredData = data.filter(item => selectedIds.includes(item.id))
            const result = []
            console.log('filtered data: ', filteredData)
            const aa = filteredData.forEach((x) => {  result.push(x.id)})
                dataProvider.create('storerequest', {data: {id: selectedIds}})
                    .then(response => {
                        notify("A termék bekerült az Üzlet raktárba")
                        refresh()
                        unselectAll()
                    })
                    .catch(reason => {
                        console.log(reason)
                        notify("businessUnits.csrGenerateError", {type: "error"})
                    })
    }


    return (
        <Button
            label= "Termék áthelyzése az Üzlet raktárba"
            onClick={updateMany}
        />
    );
};