import * as React from "react";
import {
    Button, useDataProvider,
    useListContext, useTranslate, useUnselectAll,
} from 'react-admin';
import {useFormContext} from "react-hook-form";
import {randomNumberBetween} from "@mui/x-data-grid/utils/utils";

export const ProductSelectServiceBulkAction = ({current, setOpen, formData}) => {
    const { selectedIds, data} = useListContext();
    const { setValue } = useFormContext();
    const translate = useTranslate()
    const unselectAll = useUnselectAll("product/storageId/"+5);

    function generateRandom() {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    /*async function getManufacturer(id) {
        await dataProvider.get("product/glassManufacturer/" + id, {size: 200}).then(x => {
            console.log(x.data.manufacturer)
            return x.data.manufacturer
        })
    }*/

    const updateMany = () => {
        console.log("data", data)
        console.log("selected ids", selectedIds)
        const filteredData = data.filter(item => selectedIds.includes(item.id))
        const aa = filteredData.map((x) => {  return {productId: x.productId, name: x.name, euroCode: x.euroCode,
                glassManufacturer: x.glassManufacturer, vehicleManufacturer: x.vehicleManufacturer, vehicleModel: x.vehicleModel,
                yearFrom: x.yearFrom, yearTo: x.yearTo, price: x.price, oldQty: x.qty, stand: x.stand, level: x.level, shelf: x.shelf, id: x.id }})
        console.log("aa", aa)
        setValue('products', current.concat(aa))
        console.log("fdpr", formData.products)
        setOpen(false)
        unselectAll()
    }


    return (
        <Button
            label= "Termék hozzáadása a listához"
            onClick={updateMany}
        />
    );
};