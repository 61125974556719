import React from 'react';
import {Edit, SaveButton, SimpleForm, Toolbar, useRecordContext} from "react-admin";
import WorkflowDetails from "./WorkflowDetails";
import {useFormContext} from "react-hook-form";
import SaveIcon from "@mui/icons-material/Save";

const WorkflowEdit = (props) => {

    const AlwaysEnableToolbar = () => {
        let record = useRecordContext()
        if(!record){
            record = {}
        }
        const { setValue } = useFormContext()
        const handleSaveAndPaidClick = (e) => {
            setValue("status", "PAID")
        }
        const handleSaveAndCloseClick = (e) => {
            setValue("status", "CLOSE")
        }

        return (
            <Toolbar {...props} >
                <>
                    <SaveButton label={"ra.action.save"} icon={<SaveIcon />} variant={"contained"} color={"primary"} type={"button"}
                                alwaysEnable={true}/>
                    {(record.status === "DONE") ?
                        <SaveButton variant={"outlined"} sx={{marginLeft: '8px'}} label={'workflow.saveAndPaid'} onClick={handleSaveAndPaidClick}
                                    alwaysEnable={true}></SaveButton>
                        :
                        null
                    }
                    {(record.status === "PAID") ?
                        <SaveButton variant={"outlined"} sx={{marginLeft: '8px'}} label={'workflow.close'} onClick={handleSaveAndCloseClick}
                                    alwaysEnable={true}></SaveButton>
                        :
                        null
                    }
                </>
            </Toolbar>
        );
    }



    return (
        <Edit {...props} title={'workflow.edit'} redirect="list">
            <SimpleForm toolbar={<AlwaysEnableToolbar />}>
                <WorkflowDetails />
            </SimpleForm>
        </Edit>
    )

}
export default WorkflowEdit