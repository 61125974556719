import React from 'react';
import {
    TextInput,
    FormDataConsumer,
    DateInput,
    SelectInput,
    List,
    TextField,
    required,
    SelectField,
    ReferenceInput,
    useRefresh,
    RadioButtonGroupInput,
    Button,
    Pagination,
    SearchInput,
    useDataProvider, useRecordContext, useNotify, FunctionField
} from "react-admin";
import {Dialog, DialogContent, DialogTitle, Typography} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {useState} from "react";
import {Datagrid as ReactAdminDataGrid} from "react-admin";
import {Tooltip} from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import dataProvider from "../../components/dataProvider";

const WorkflowDetails = (props) => {
    const refresh = useRefresh()
    const [delivery, setDelivery] = useState(false);
    const [openStore, setOpenStore] = useState(false);
    const [qEuroCode, setQEuroCode] = useState('');
    const [timeChoice, setTimeChoice] = useState([
        { id: '08:30', name: '08:30' },
        { id: '09:50', name: '09:50' },
        { id: '11:05', name: '11:05' },
        { id: '13:00', name: '13:00' },
        { id: '14:15', name: '14:15' },
        { id: '15:30', name: '15:30' },
    ]);

    const openStoreModelDialog = () => {
        setOpenStore(true);
    };
    const handleCloseStore = () => {
        setOpenStore(false)
    }
    const NoProducts = () => <Typography>Még nincs termék a listán</Typography>;
    const filters =  [<SearchInput source="q" alwaysOn resettable={true}/>,
        <TextInput source="stand" label={'incoming.stand'} alwaysOn resettable={true}/>,
        <TextInput source="level" label={'incoming.level'} alwaysOn resettable={true}/>,
        <TextInput source="shelf" label={'incoming.shelf'} alwaysOn resettable={true}/>]

    const phoneFormatRegex = /^\+36\d{9}$/;

    const phoneValidate = (value) => {
        if (phoneFormatRegex.test(value)){
            return undefined
        }
        return "Hibás telefonszám formátum"
    }

    const choices = [
        { id: 'R', name: 'workflow.category.replacement' },
        { id: 'F', name: 'workflow.category.fix' },
        { id: 'G', name: 'workflow.category.gluing'},
        { id: 'T', name: 'workflow.category.takeaway'},
        { id: 'D', name: 'workflow.category.delivery'},
    ];
    const handleEuroCodeChange = (event, newValue) => {
        console.log("sss", event.target.value)
        setQEuroCode(event.target.value)
    };
    const handleChange = (event, newValue) => {
        refresh()
    };

    function StoreField(props) {
        const dataProvider = useDataProvider();
        const record = useRecordContext();
        const notify = useNotify();
        const [file, setFile] = useState(undefined)
        const [isLoading, setLoading] = useState(false)
        const download = (record) => {
            dataProvider.create('workflow/store', {data: {inventoryId: record.id}})
                .then(response => {
                    notify("A termék bekerült a raktár kikérőbe")
                    setOpenStore(false)
                })
                .catch(reason => {
                    console.log(reason)
                    notify("businessUnits.csrGenerateError", {type: "error"})
                    setLoading(false)
                })
        }
        return (
            <Button onClick={() => download(record)}>Raktár kikérőbe</Button>
        );
    }

    const renderEuroCode = (record)  => {
        if (record.note == null) {
            return (<>
                <span style={{whiteSpace: "nowrap"}}>
                    <Tooltip title="Vágólapra másolás">
                        <ContentCopyIcon fontSize="'inherit'" style={{ cursor: 'pointer' }} onClick={() => {navigator.clipboard.writeText(record.euroCode)}}/>
                    </Tooltip>&nbsp;<TextField source="euroCode"/>
                </span></>);
        } else {
            return (<>
                <span style={{whiteSpace: "nowrap"}}>
                    <Tooltip title="Vágólapra másolás">
                        <ContentCopyIcon fontSize="'inherit'" style={{ cursor: 'pointer' }} onClick={() => {navigator.clipboard.writeText(record.euroCode)}}/>
                    </Tooltip>&nbsp;
                    <TextField source="euroCode"/>&nbsp;
                    <Tooltip title={record.note}>
                        <CommentOutlinedIcon fontSize="'inherit'" style={{ cursor: 'pointer' }}/>
                    </Tooltip>
                </span></>);
        }

    }

    const renderReservedQty = (record) => (
        <>{record.reservedQty === 0? "" :record.reservedQty }</>
    );

    const handleDateChange = async (event) => {
        try {
            const aa= await dataProvider.get("workflow/freetime/"+event.target.value)
            setTimeChoice(aa.data)
        }
        catch (error) {
            console.error("freeTime error: ", error);
        }

    }

    const handleCategoryChange = (event) => {
        if (event.target.value === 'D'){
            setDelivery(true);
        }
        else {
            setDelivery(false);
        }
    }

    return (
        <>
            <RadioButtonGroupInput source="category" choices={choices} row={false} label={"workflow.categoryName"} validate={required()} onChange={handleCategoryChange} />
            <div style={{ display: 'flex' }}>
                <DateInput source="bookingDate" label="Időpont" validate={required()} style = {{width: 400}} onChange={handleDateChange} />
                <SelectInput source="bookingTime" label="" choices={timeChoice}/>
            </div>
            <TextInput source="name" label="Név" validate={required()} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
            <TextInput source="plateNumber" label="Rendszám" format={(value) => value && value.toUpperCase()} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
            <TextInput source="vin" label="Alvázszám" format={(value) => value && value.toUpperCase()} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
            <TextInput source="phone" label="Telefon" validate={[required(), phoneValidate]} inputProps={{maxLength: 255}} placeholder="+36201234567"  style = {{width: 400}} />
            <TextInput source="email" label="Email" inputProps={{maxLength: 255}} type="email"  style = {{width: 400}}/>
            <div style={{ display: 'flex' }}>
            <TextInput source="euroCode" label="Eurokód"  inputProps={{maxLength: 255}}  style = {{width: 400}} onChange={handleEuroCodeChange}/>
                <Button style={{padding: "10px", marginBottom: "10px"}} variant={"text"} color={"primary"} onClick={openStoreModelDialog}>
                    <AddIcon /> Raktár kikérő
                </Button>
            </div>
            <FormDataConsumer>
                {({ formData }) => (
                    formData.vehicleManufacturer == null &&
                <ReferenceInput source={"vehicleManufacturerId"}  reference={'product/vehicleManufacturer'} perPage={100}>
                    <SelectInput optionText={"name"} label={"product.vehicleManufacturer"} style = {{width: 400}} onChange={handleChange}/>
                </ReferenceInput>
                )}
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData }) => (
                    formData.vehicleManufacturerId && formData.vehicleModel == null &&
                   <ReferenceInput source={"vehicleModelId"}  reference={'product/vehicleModel'} filter={{vehicleManufacturerId : formData.vehicleManufacturerId}} key={formData.vehicleManufacturerId} perPage={100}>
                        <SelectInput label={"product.vehicleModel"} optionText={"name"} style = {{width: 400}} />
                    </ReferenceInput>
                )}
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData }) => (
                    formData.vehicleManufacturer &&
            <TextInput source="vehicleManufacturer" label="product.vehicleManufacturer" inputProps={{maxLength: 255}} style = {{width: 400}}/>
                )}
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData }) => (
                    formData.vehicleModel &&
            <TextInput source="vehicleModel" label="product.vehicleModel" inputProps={{maxLength: 255}} style = {{width: 400}}/>
                )}
            </FormDataConsumer>
            <TextInput source="vehicleYear" label="Évjárat" inputProps={{maxLength: 255}} style = {{width: 400}}/>
            <TextInput source="comment" label="Megjegyzés" multiline inputProps={{maxLength: 255}} style = {{width: 400}}/>
            { delivery &&
                <>
                    <TextInput source="deliveryName" label="Kiszállítási név" inputProps={{maxLength: 255}} style = {{width: 400}}/>
                    <TextInput source="deliveryPostcode" label="Kiszállítási írányítószám" multiline inputProps={{maxLength: 255}} style = {{width: 400}}/>
                    <TextInput source="deliverySettlement" label="Kiszállítási település" inputProps={{maxLength: 255}} style = {{width: 400}}/>
                    <TextInput source="deliveryAddress" label="Kiszállítási cím" multiline inputProps={{maxLength: 255}} style = {{width: 400}}/>
                </>
            }
                <Typography variant="h6" component="h6">
                    Státusz
                </Typography>
                <SelectField source="status" choices={[
                    { id: 'NEW', name: 'Új' },
                    { id: 'PROCESS', name: 'Folyamatban' },
                    { id: 'DONE', name: 'Elkészült' },
                    { id: 'PAID', name: 'Fizetve' },
                    { id: 'CLOSED', name: 'Lezárva' }
                ]} />
            <Dialog open={openStore} scroll={"paper"} fullWidth={true} onClose={handleCloseStore} maxWidth={"xl"}>
                <DialogTitle id="alert-dialog-title">
                    Raktár kikérő
                </DialogTitle>
                <DialogContent>
                    <List perPage={50} empty={<NoProducts />} actions={false} pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />} resource={"inventory"} filter={{euroCode: qEuroCode}}>
                        <ReactAdminDataGrid  bulkActionButtons={false}>
                            <TextField source="id" label={'product.id'}/>
                            <TextField source="siteName" label={'site.title'}/>
                            <TextField source="storageName" label={'site.storage'}/>
                            <TextField source="stand" label={'inventory.stand'}/>
                            <TextField source="level" label={'inventory.level'}/>
                            <TextField source="shelf" label={'inventory.shelf'}/>
                            <TextField source="productName" label={'product.name'}/>
                            <TextField source="barCode" label={'product.barCode'}/>
                            <FunctionField source="euroCode" label={'product.euroCode'} render={renderEuroCode}/>
                            <TextField source="glassManufacturerName" label={'product.glassManufacturer'}/>
                            <TextField source="vehicleManufacturerName" label={'product.vehicleManufacturer'}/>
                            <TextField source="vehicleModelName" label={'product.vehicleModel'}/>
                            <TextField source="yearFrom" label={'product.yearFrom'}/>
                            <TextField source="yearTo" label={'product.yearTo'}/>
                            <TextField source="description" label={'product.description'}/>
                            <TextField source="price" label={'product.price'}/>
                            <TextField source="qty" label={'inventory.qty'}/>
                            <FunctionField source="reservedQty" label={'inventory.reservedQty'} render={renderReservedQty}/>
                            <StoreField/>
                        </ReactAdminDataGrid>
                    </List>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default WorkflowDetails