import React from 'react';
import {Edit} from "react-admin";
import PartnerDetails from "./PartnerDetails";

const PartnerEdit = (props) => {
    return (
        <Edit {...props} title={'partner.edit'}>
            <PartnerDetails />
        </Edit>
    )

}
export default PartnerEdit