import React, {useEffect, useState} from 'react';
import {
    TextField,
    List,
    TextInput,
    SearchInput, useTranslate, useUnselectAll, Pagination, ReferenceField,
} from 'react-admin';
import {Box, Button, Card, CircularProgress, Dialog, DialogContent, DialogTitle, Typography} from '@mui/material';
import {Datagrid as ReactAdminDataGrid} from "react-admin";
import {DataGrid, huHU} from '@mui/x-data-grid';
import EditOutgoingDataGrid from "./EditOutgoingDataGrid";
import RemoveIcon from '@mui/icons-material/Remove';
import {ProductSelectOutgoingBulkAction} from "./ProductSelectOutgoingBulkAction";

import {EmptyPage} from "./EmptyPage";


export const ProductFromStoreList = (formData) => {
    const translate = useTranslate()
    const unselectAll = useUnselectAll("product/storageId/"+formData.formData.storeId);

    const [open, setOpen] = useState(false);
    const openAddDialog = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false)
        unselectAll()
    }

    const NoProducts = () => <Typography>Még nincs termék a listán</Typography>;

    const products = (formData.formData?.products) ? formData.formData.products : []
    const [filteredIds, setFilteredIds] = useState([])

    useEffect(() => {
        if(formData.formData.products) {
            setFilteredIds(formData.formData.products.map(product => product.id))
        }
    }, [products]);

    const ListItems = () => {
        if(products && products.length > 0) {
            return (
                <EditOutgoingDataGrid rows={products} />
            )
        }else{
            return <div> Jelenleg nincs egy termék se a listában </div>
        }
    }

    const filters =  [<SearchInput source="q" alwaysOn resettable={true}/>,
        <TextInput source="stand" label={'incoming.stand'} alwaysOn resettable={true}/>,
        <TextInput source="level" label={'incoming.level'} alwaysOn resettable={true}/>,
        <TextInput source="shelf" label={'incoming.shelf'} alwaysOn resettable={true}/>]
    return (
        <Box>
            <Button style={{marginBottom: "16px", color: "white"}} variant={"contained"} color={"primary"} onClick={openAddDialog}>
                <RemoveIcon />  Termék hozzáadása a kivételezéshez
            </Button>
            <Dialog open={open} fullWidth={true} scroll={"paper"} maxWidth={"xl"} onClose={handleClose} >
                <DialogTitle id="alert-dialog-title">
                    Termék kivételezése a raktárból
                </DialogTitle>
                <DialogContent>
                    <div style={{maxHeight: "700px"}}>

                        <List perPage={50} empty={<NoProducts />} pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />} resource={"product/storageId/"+formData.formData.storeId} filters={filters} filter={{excludedIds: filteredIds}}>
                            <ReactAdminDataGrid optimized={true} bulkActionButtons={<ProductSelectOutgoingBulkAction current={products} setOpen={setOpen} formData={formData} />} >
                                <TextField source="id" label={'product.id'}/>
                                <TextField source="name" label={'product.name'}/>
                                <TextField source="euroCode" label={'product.euroCode'}/>
                                <TextField source="glassManufacturer" label={'product.glassManufacturer'}/>
                                <TextField source="vehicleManufacturer" label={'product.vehicleManufacturer'}/>
                                <TextField source="vehicleModel" label={'product.vehicleModel'}/>
                                <TextField source="yearFrom" label={'product.yearFrom'}/>
                                <TextField source="yearTo" label={'product.yearTo'}/>
                                <TextField source="price" label={'product.price'}/>
                                <TextField source="qty" label={'incoming.qty'}/>
                                <TextField source="stand" label={'incoming.stand'}/>
                                <TextField source="level" label={'incoming.level'}/>
                                <TextField source="shelf" label={'incoming.shelf'}/>
                            </ReactAdminDataGrid>
                        </List>
                    </div>
                </DialogContent>
            </Dialog>
            <ListItems />
        </Box>
    );
};