import {
    Datagrid, TextField, ReferenceField, useGetList, useRefresh, FunctionField, useTranslate,
} from 'react-admin';
import {useEffect, useState} from "react";
import React from "react";

const TvInfo = (...props) => {
    const sort = { field: 'id', order: 'DESC' };
    const { data, total, isLoading } = useGetList('tv-info', {
        pagination: { page: 1, perPage: 10 },
        sort,
    });

    const refresh = useRefresh()
    const translate = useTranslate()

    useEffect(() => {
        const interval = setInterval(() => {
            refresh();
        }, 60000); // 60000 ms = 1 perc

        return () => {
            clearInterval(interval);
        };
    }, []); // Az üres tömb a dependency list, így csak a komponens mount-olásakor fut le egyszer

    const choices = [
        { id: 'R', name: 'workflow.category.replacement' },
        { id: 'F', name: 'workflow.category.fix' },
        { id: 'G', name: 'workflow.category.gluing'},
        { id: 'T', name: 'workflow.category.takeaway'},
    ];

    const process = [
        { id: 'NEW', name: 'workflow.status.new' },
        { id: 'PROCESS', name: 'workflow.status.process' },
        { id: 'DONE', name: 'workflow.status.done'},
        { id: 'PAID', name: 'workflow.status.paid'},
        { id: 'CLOSE', name: 'workflow.status.close'},
    ];

    const categoryRender = (data) => {
        return translate(choices.find(choice => choice.id === data.category).name)
    }

    const processRender = (data) => {
        return translate(process.find(proces => proces.id === data.status).name)
    }

    return (
<>
        <Datagrid             data={data}
                              total={total}
                              isLoading={isLoading}
                              sort={sort}
                              bulkActionButtons={false}>
            <FunctionField source="category" label="workflow.categoryName" render={categoryRender}/>
            <FunctionField source="status" label="workflow.categoryName" render={processRender}/>
            <TextField source="name" label={'product.name'}/>
            <TextField source="plateNumber" label="Rendszám"/>
            <TextField source="vin" label="Alvázszám"/>
            <TextField source="phone" label="Telefon"/>
            <TextField source="email" label="Email"/>
            <TextField source="euroCode" label="Eurokód"/>
                <TextField source="vehicleManufacturer" label="Gyártó"/>
                <TextField source="vehicleModel" label="Model" />
            <TextField source="comment" label="Megjegyzés"/>
            <TextField source="odometer" label="KM óra állás"/>
        </Datagrid>
</>
    )

}

export default TvInfo