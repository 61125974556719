import React from 'react';
import {Edit} from "react-admin";
import SiteDetails from "./SiteDetails";

const SiteEdit = (props) => {
    return (
        <Edit {...props} title={'site.edit'}>
            <SiteDetails />
        </Edit>
    )

}
export default SiteEdit