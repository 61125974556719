import {Datagrid, List, TextField, FunctionField, Title, usePermissions} from "react-admin";
import Paper from "@mui/material/Paper";
import {Typography} from "@mui/material";

const Top10SaleQuantity = () => {
    const { permissions } = usePermissions();

    return (
        permissions === 'admin' &&
        <>
            <Title title=""/>
            <Paper key="1" elevation={6}>
                <Typography variant="h5" component="h5" sx={{textAlign: 'center'}}>TOP 10 legtöbbett értékesített termék</Typography>
                <List resource='dashboard/top-10-sale-quantity' actions={false} title=" " pagination={false}>
                    <Datagrid
                        bulkActionButtons={false}>
                        <TextField source="euroCode" label="Eurokód"/>
                        <FunctionField render={record => record.qty * -1 } label="Mennyiség"/>
                    </Datagrid>
                </List>
            </Paper>
        </>
    )

}
export default Top10SaleQuantity