import React, {useEffect, useState} from "react";
import { useParams } from "react-router";
import {
    BooleanField, Button, DateField, ImageField, List, Pagination, ReferenceField,
    required, SelectField,
    Show,
    SimpleShowLayout,
    TextField, useRedirect,
    useTranslate,
    Datagrid as ReactAdminDataGrid, CreateButton, useShowContext, useRecordContext, TopToolbar, Title, RichTextField
} from "react-admin";
import ArticleIcon from '@mui/icons-material/Article';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {Stack} from "@mui/material";



const CuriosityShow = (props) => {
    const { id } = useParams(); // this component is rendered in the /books/:id path
    const translate = useTranslate()
    const redirect = useRedirect();

    const CustomTitle = () =>  {
        const record = useRecordContext();
        return(
            <Title title={`Érdeklődő ${record?.customerName || ''}`} />
        )
    }

    const handleProposal = () => {
        redirect('/proposal/'+id);
    }

    const CustomButton = () => {
        const record = useRecordContext();

        const handleClick = () => {
            if (record) {
                redirect("create", "workflow", null, null, { state: {
                        category: record.size === 'BIG' ? 'R' : 'F',
                        name: record.customerName,
                        phone: record.customerPhone,
                        email: record.customerEmail,
                        plateNumber: record.plateNumber,
                        vin: record.vin,
                        vehicleManufacturer: record.vehicleManufacturer,
                        vehicleModel: record.vehicleModel,
                        vehicleYear: record.vehicleYear
                    } });
            } else {
                console.log('No record found');
            }
        };

        return (
            <Button onClick={handleClick} label="Másolás az időpontfoglaláshoz" startIcon={<AccessTimeIcon/>} />
        );
    };

    const PostShowActions = () => (
        <TopToolbar>
            <Button label="Ajánlat készítés" onClick={handleProposal} startIcon={<ArticleIcon/>} />
            <CustomButton />
            {/* Más gombok itt */}
        </TopToolbar>
    );

    return (
        <Show actions={<PostShowActions/>} title={<CustomTitle/>}>
            <SimpleShowLayout>
                <TextField source="offerNumber" label={"curiosity.offerNumber"} validate={required()} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
                <BooleanField source="isCompany" label={'curiosity.isCompany'} validate={required()} />
                <TextField source="customerName" label={"curiosity.name"} validate={required()} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
                <TextField source="customerPhone" label={"curiosity.phone"} validate={required()} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
                <TextField source="customerEmail" label={"curiosity.email"} validate={required()} type="email" inputProps={{maxLength: 255}}  style = {{width: 400}}/>
                <TextField source="vehicleManufacturer" label={"curiosity.vehicleManufacturer"} validate={required()} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
                <TextField source="vehicleModel" label={"curiosity.vehicleModel"} validate={required()} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
                <TextField source="vehicleYear" label={"curiosity.vehicleYear"} validate={required()} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
                <TextField source="plateNumber" label={"curiosity.plateNumber"} validate={required()} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
                <TextField source="vin" label={"curiosity.vin"} validate={required()} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
                <SelectField source="position" choices={[
                    { id: 'FIRST', name: 'Első' },
                    { id: 'BACK', name: 'Hátsó' },
                    { id: 'SIDE', name: 'Oldalsó' },
                ]} label={'curiosity.position'} validate={required()} />
                <SelectField source="size" choices={[
                    { id: 'BIG', name: 'Csere' },
                    { id: 'SMALL', name: 'Javítás' },
                ]} label={'curiosity.service'} validate={required()} />
                <SelectField source="location" choices={[
                    { id: 'SITE', name: 'Műhely' },
                    { id: 'CUSTOMER', name: 'Ügyfélnél' },
                ]} label={'curiosity.location'} validate={required()} />
                <TextField source="customerPostcode" label={"curiosity.postcode"} validate={required()} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
                <TextField source="customerSettlement" label={"curiosity.settlement"} validate={required()} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
                <TextField source="customerAddress" label={"curiosity.address"} validate={required()} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
                <BooleanField source="casco" label={'curiosity.casco'} validate={required()} />
                <TextField source="insurance" label={"curiosity.insurance"} validate={required()} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
                <ImageField source="pictures" src="url" title="desc" label="Feltöltött képek" />
                <List perPage={200} pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />} resource={"proposal/"+id} actions={false} empty={false} title={<></>}>
                    <ReactAdminDataGrid optimized={true} bulkActionButtons={false} >
                        <RichTextField source="proposalText" label={'Ajánlat szövege'}/>
                        <DateField showTime source="createdAt" label={'Ajánlatkészítés időpontja'}/>
                        <DateField source="validUntil" label={'Ajánlat érvényessége'}/>
                        <ReferenceField label={'Készítette'} reference={"employee/getAll"} link={false} source={"createdBy"}>
                            <TextField source="name"/>
                        </ReferenceField>
                    </ReactAdminDataGrid>
                </List>
            </SimpleShowLayout>
        </Show>
    )
}

export default CuriosityShow