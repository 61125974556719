import React from 'react';
import {Create} from 'react-admin';
import OutgoingDetails from "./OutgoingDetails";

const OutgoingCreate = (props) => {
    return (
        <Create {...props} title={'outgoing.create'}>
            <OutgoingDetails />
        </Create>
    )
}

export default OutgoingCreate