import React, {useEffect, useState} from 'react';
import {
    TextField,
    List,
    TextInput,
    SearchInput, useTranslate, useUnselectAll, Pagination, ReferenceField,
} from 'react-admin';
import {Box, Tab, Button, Card, CircularProgress, Dialog, DialogContent, DialogTitle, Typography} from '@mui/material';
import {Datagrid as ReactAdminDataGrid} from "react-admin";
import {DataGrid, huHU} from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import {EmptyPage} from "./EmptyPage";
import {ProductSelectInvoiceBulkAction} from "./ProductSelectInvoiceBulkAction";
import EditInvoiceDataGrid from "./EditInvoiceDataGrid";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {ProductSelectInvoiceUnStockableBulkAction} from "./ProductSelectInvoiceUnStockableBulkAction";


export const ProductToInvoiceList = (formData) => {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const translate = useTranslate()
    const unselectAll = useUnselectAll('product/incoming');

    const [open, setOpen] = useState(false);
    const [total, setTotal] = useState(0);
    const openAddDialog = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false)
        unselectAll()
    }

    const calculateTotal = () => {
        if(formData.formData.products) {
            let sum = 0;
            for (let i = 0; i < formData.formData.products.length; i++) {
                const elem = formData.formData.products[i];
                let qty = elem.qty;
                let unitPrice = elem.price;
                if (qty == null){
                    qty = 0;
                }
                if (unitPrice == null){
                    unitPrice = 0;
                }
                sum += qty * unitPrice;
            }
            setTotal(sum)
        }
    }

    const NoProducts = () => <Typography>Még nincs termék a listán</Typography>;

    const products = (formData.formData?.products) ? formData.formData.products : []
    const [filteredIds, setFilteredIds] = useState([])
    const [filteredUnStockableIds, setFilteredUnStockableIds] = useState([])

    useEffect(() => {
        if(formData.formData.products) {
            setFilteredIds(formData.formData.products.filter( product => typeof product.id === "number").map(product => product.id))
            setFilteredUnStockableIds(formData.formData.products.filter( product => typeof product.id === "string").map(product => product.id))
            calculateTotal()
        }
    }, [products]);

    const ListItems = () => {
        if(products && products.length > 0) {
            return (
                <>
                    <EditInvoiceDataGrid rows={products} />
                    <Typography variant={"h4"}>Összesen: {total} Ft</Typography>
                </>
            )
        }else{
            return <div> Jelenleg nincs egy termék se a listában </div>
        }
    }

    const filters =  [<SearchInput source="q" alwaysOn resettable={true}/>,
        <TextInput source="stand" label={'incoming.stand'} alwaysOn resettable={true}/>,
        <TextInput source="level" label={'incoming.level'} alwaysOn resettable={true}/>,
        <TextInput source="shelf" label={'incoming.shelf'} alwaysOn resettable={true}/>]
    return (
        <Box>
            <Button style={{marginBottom: "16px", color: "white"}} variant={"contained"} color={"primary"} onClick={openAddDialog}>
                <AddIcon />  Termék/szolgáltatás hozzáadása a számlához
            </Button>
            <Dialog open={open} fullWidth={true} scroll={"paper"} maxWidth={"xl"} onClose={handleClose} >
                <DialogTitle id="alert-dialog-title">
                    Termék/szolgáltatás hozzáadása a számlához
                </DialogTitle>
                <DialogContent>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="tabs product/service">
                                <Tab label="Termékek" value="1" />
                                <Tab label="Szolgáltatások" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <div style={{maxHeight: "700px"}}>
                                <List perPage={50} empty={<NoProducts />} pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />} resource={"product/storageId/5"} filters={filters} filter={{excludedIds: filteredIds}}>
                                    <ReactAdminDataGrid optimized={true} bulkActionButtons={<ProductSelectInvoiceBulkAction current={products} setOpen={setOpen} formData={formData} />} >
                                        <TextField source="id" label={'product.id'}/>
                                        <TextField source="name" label={'product.name'}/>
                                        <TextField source="euroCode" label={'product.euroCode'}/>
                                        <TextField source="glassManufacturer" label={'product.glassManufacturer'}/>
                                        <TextField source="vehicleManufacturer" label={'product.vehicleManufacturer'}/>
                                        <TextField source="vehicleModel" label={'product.vehicleModel'}/>
                                        <TextField source="yearFrom" label={'product.yearFrom'}/>
                                        <TextField source="yearTo" label={'product.yearTo'}/>
                                        <TextField source="price" label={'product.price'}/>
                                        <TextField source="qty" label={'incoming.qty'}/>
                                    </ReactAdminDataGrid>
                                </List>
                            </div>
                        </TabPanel>
                        <TabPanel value="2">
                            <div style={{maxHeight: "700px"}}>
                                <List perPage={50} empty={<NoProducts />} pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />} resource={"product/unstockable"} filters={filters} filter={{excludedIds: filteredUnStockableIds}}>
                                    <ReactAdminDataGrid optimized={true} bulkActionButtons={<ProductSelectInvoiceUnStockableBulkAction current={products} setOpen={setOpen} formData={formData} />} >
                                        <TextField source="id" label={'product.id'}/>
                                        <TextField source="name" label={'product.name'}/>
                                        <TextField source="euroCode" label={'product.euroCode'}/>
                                        <TextField source="glassManufacturer" label={'product.glassManufacturer'}/>
                                        <TextField source="vehicleManufacturer" label={'product.vehicleManufacturer'}/>
                                        <TextField source="vehicleModel" label={'product.vehicleModel'}/>
                                        <TextField source="yearFrom" label={'product.yearFrom'}/>
                                        <TextField source="yearTo" label={'product.yearTo'}/>
                                        <TextField source="price" label={'product.price'}/>
                                        <TextField source="qty" label={'incoming.qty'}/>
                                    </ReactAdminDataGrid>
                                </List>
                            </div>
                        </TabPanel>
                    </TabContext>
                </DialogContent>
            </Dialog>
            <ListItems />
        </Box>
    );
};