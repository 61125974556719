import * as React from "react";
import {
    Datagrid,
    TextField,
    DateField,
    List,
    FunctionField,
    ReferenceField,
    ShowButton,
    useTranslate
} from 'react-admin';

const IncomingList = () => {

    /*const ProductPanel = () => {

        const record = useRecordContext();
        const rows = record.products;


        const columns = [
            {
                field: 'id',
                headerName: 'id',
                visible: false
                //valueGetter: (params) => params.row.product.euroCode
            },
            {
                field: 'qty',
                headerName: 'Mennyiség',
                editable: true
                //valueGetter: (params) => params.row.product.manufacturer
            },
            {
                field: 'stand',
                headerName: 'Állvány',
                editable: true
                //valueGetter: (params) => params.row.product.manufacturer
            },
            {
                field: 'level',
                headerName: 'Szint',
                editable: true
                //valueGetter: (params) => params.row.product.manufacturer
            },
            {
                field: 'shelf',
                headerName: 'Polc',
                editable: true
                //valueGetter: (params) => params.row.product.manufacturer
            },

        ];




        return (
            <DataGrid
                rowsPerPageOptions={[5, 10, 20, 100]}
                rows={rows}
                columns={columns}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
            />
        );
    };*/

    const translate = useTranslate()

    const choices = [
        { id: 'R', name: 'outgoing.category.return' },
        { id: 'S', name: 'outgoing.category.storeCorrection' },
        { id: 'D', name: 'outgoing.category.disposal'},
        { id: 'O', name: 'outgoing.category.other' },
    ];

    const categoryRender = (data) => {
        return translate(choices.find(choice => choice.id === data.category).name)
    }

    return (
        <List>
            <Datagrid rowClick={"edit"} bulkActionButtons={false} /*expand={ProductPanel}*/>
                <TextField source="id" label={'outgoing.id'}/>
                <FunctionField source="category" label="outgoing.categoryName" render={categoryRender}/>
                <TextField source="note" label={'outgoing.note'}/>
                <ReferenceField label={'site.title'}  reference={"site"} link={false} source={"siteId"}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label={'store.title'}  reference={"storage"} link={false} source={"storeId"}>
                    <TextField source="name" />
                </ReferenceField>
                <DateField source="incomingDate" label="outgoing.incomingDate"/>
                <DateField source="orderDate" label="outgoing.orderDate"/>
                <DateField source="createdAt" label="outgoing.createdAt" showTime/>
                <ReferenceField label={'outgoing.createdBy'} reference={"employee/getAll"} link={false} source={"createdBy"}>
                    <TextField source="name"/>
                </ReferenceField>
                <ShowButton/>
            </Datagrid>
        </List>
    )
}

export default IncomingList