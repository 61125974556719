import React, {useState} from 'react';
import {
    Button,
    TextInput,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    FormDataConsumer,
    required,
    useRefresh,
    usePermissions,
    useTranslate, useDataProvider
} from "react-admin";
import AddIcon from "@mui/icons-material/Add";
import {Dialog, DialogContent, DialogTitle, TextField} from "@mui/material";
import {useFormContext} from "react-hook-form";

const ProductDetails = ({isEdit}) => {
    const translate = useTranslate()
    const refresh = useRefresh()
    const dataProvider = useDataProvider()
    const { setValue, getValues } = useFormContext();
    const { permissions } = usePermissions();
    const handleChange = (event, newValue) => {
        refresh()
    };
    const [openVehicleManufacturer, setOpenVehicleManufacturer] = useState(false);
    const [vehicleManufacturerName, setVehicleManufacturerName] = useState('')
    const openAddVehicleManufacturerDialog = () => {
        setOpenVehicleManufacturer(true);
    };
    const handleCloseVehicleManufacturer = () => {
        setOpenVehicleManufacturer(false)
    }
    const handleSaveVehicleManufacturer = () => {
        dataProvider.create('product/addVehicleManufacturer', {data:{name: vehicleManufacturerName}})
            .then(r => {
                setValue('vehicleManufacturer', r.data.id)
            refresh()})
        setOpenVehicleManufacturer(false)
    }

    const handleTextChangeVehicleManufacturer = (event) => {
        setVehicleManufacturerName(event.target.value)
    }

    const checkVehicleManufacturerName = () => {
        console.log(vehicleManufacturerName.length)
        return vehicleManufacturerName.length === 0;
    }
    const [openVehicleModel, setOpenVehicleModel] = useState(false);
    const [vehicleModelName, setVehicleModelName] = useState('')
    const openAddVehicleModelDialog = () => {
        setOpenVehicleModel(true);
    };
    const handleCloseVehicleModel = () => {
        setOpenVehicleModel(false)
    }
    const handleSaveVehicleModel = () => {
        dataProvider.create('product/addVehicleModel', {data:{name: vehicleModelName, vehicleManufacturerId: getValues('vehicleManufacturerId')}})
            .then(r => {
                setValue('vehicleModel', r.data.id)
                refresh()})
        setOpenVehicleModel(false)
    }

    const handleTextChangeVehicleModel = (event) => {
        setVehicleModelName(event.target.value)
    }

    const checkVehicleModelName = () => {
        console.log(vehicleModelName.length)
        return vehicleModelName.length === 0;
    }

    return (
        <>
            <BooleanInput source="stockable" label={"product.isStockable"} defaultValue={true} />
            <TextInput source="name" label={"product.name"} inputProps={{maxLength: 255}}  style = {{width: 400}} />
            <TextInput source="barcode" label={"product.barCode"} inputProps={{maxLength: 255}}  style = {{width: 400}} />
            <TextInput source="euroCode" label={"product.euroCode"} inputProps={{maxLength: 255}}  style = {{width: 400}} />
            <ReferenceInput source={"glassManufacturerId"}  reference={'product/glassManufacturer'} perPage={100}>
                <SelectInput optionText={"name"} label={"product.glassManufacturer"} style = {{width: 400}} />
            </ReferenceInput>
            <div style={{ display: 'flex' }}>
                <ReferenceInput source={"vehicleManufacturerId"}  reference={'product/vehicleManufacturer'} perPage={100}>
                    <SelectInput optionText={"name"} label={"product.vehicleManufacturer"} style = {{width: 400}} onChange={handleChange}/>
                </ReferenceInput>
                <Button style={{padding: "10px", marginBottom: "10px"}} variant={"text"} color={"primary"} onClick={openAddVehicleManufacturerDialog} disabled={isEdit}>
                    <AddIcon /> {translate('product.newVehicleManufacturer')}
                </Button>
            </div>
            <FormDataConsumer>
                {({ formData }) => (
                    formData.vehicleManufacturerId &&
                    <div style={{ display: 'flex' }}>
                        <ReferenceInput source={"vehicleModelId"}  reference={'product/vehicleModel'} filter={{vehicleManufacturerId : formData.vehicleManufacturerId}} key={formData.vehicleManufacturerId} perPage={100}>
                            <SelectInput label={"product.vehicleModel"} optionText={"name"} style = {{width: 400}} />
                        </ReferenceInput>
                        <Button style={{padding: "10px", marginBottom: "10px"}} variant={"text"} color={"primary"} onClick={openAddVehicleModelDialog} disabled={isEdit}>
                            <AddIcon /> {translate('product.newVehicleModel')}
                        </Button>
                    </div>
                )}
            </FormDataConsumer>
            <TextInput source="yearFrom" label={"product.yearFrom"} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
            <TextInput source="yearTo" label={"product.yearTo"} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
            {permissions === 'admin' && <TextInput source="manufacturerPrice" label={"product.manufacturerPrice"} type={"number"} inputProps={{maxLength: 255}}  style = {{width: 400}}/>}
            <TextInput source="wholesalePrice" label={"product.wholesalePrice"} type={"number"} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
            <TextInput source="price" label={"product.price"} type={"number"} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
            <TextInput source="discountPrice" label={"product.discountPrice"} type={"number"} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
            <TextInput source="wholesaleDiscountPrice" label={"product.wholesaleDiscountPrice"} type={"number"} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
            <ReferenceInput source={"vatId"} reference={'vat'}>
                <SelectInput optionText={"name"} label={"product.vat"} defaultValue={1} validate={required()} style = {{width: 400}}/>
            </ReferenceInput>
            <TextInput source="note" label={"product.note"} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
        <Dialog open={openVehicleManufacturer} scroll={"paper"} onClose={handleCloseVehicleManufacturer} >
            <DialogTitle id="alert-dialog-title">
                {translate('product.newVehicleManufacturer')}
            </DialogTitle>
            <DialogContent>
                <TextField label={translate("product.vehicleManufacturer")} type={"text"} autoFocus
                           defaultValue={""}  onWheel={(e) => e.target.blur()}
                    /*onKeyPress={handleKeypressQty} */ onChange={handleTextChangeVehicleManufacturer} fullWidth />
                <div style={{marginTop: "16px", display:"flex", justifyContent: "space-between"}}>
                    <Button style={{paddingRight: "8px", color: "white"}} variant={"contained"} color={"primary"} disabled={checkVehicleManufacturerName()} onClick={handleSaveVehicleManufacturer}>{translate("ra.action.save")}</Button>
                    <Button variant={"outlined"} color={"secondary"} onClick={handleCloseVehicleManufacturer}>{translate("outgoing.cancel")}</Button>
                </div>
            </DialogContent>
        </Dialog>
            <Dialog open={openVehicleModel} scroll={"paper"} onClose={handleCloseVehicleModel} >
                <DialogTitle id="alert-dialog-title">
                    {translate('product.newVehicleModel')}
                </DialogTitle>
                <DialogContent>
                    <TextField label={translate("product.vehicleModel")} type={"text"} autoFocus
                               defaultValue={""}  onWheel={(e) => e.target.blur()}
                        /*onKeyPress={handleKeypressQty} */ onChange={handleTextChangeVehicleModel} fullWidth />
                    <div style={{marginTop: "16px", display:"flex", justifyContent: "space-between"}}>
                        <Button style={{paddingRight: "8px", color: "white"}} variant={"contained"} color={"primary"} disabled={checkVehicleModelName()} onClick={handleSaveVehicleModel}>{translate("ra.action.save")}</Button>
                        <Button variant={"outlined"} color={"secondary"} onClick={handleCloseVehicleModel}>{translate("outgoing.cancel")}</Button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ProductDetails