import React from 'react';
import {TextField, List, DeleteButton, Datagrid, SelectField} from "react-admin";

const EmployeeList = () => {

    return (
        <List>
            <Datagrid rowClick={"edit"} bulkActionButtons={false}>
                <TextField source="id" label={'employee.uuid'}/>
                <TextField source="email" label={'employee.email'}/>
                <TextField source="name" label={'employee.name'}/>
                <SelectField source="permission" choices={[
                    { id: 'admin', name: 'Adminisztrátor' },
                    { id: 'manager', name: 'Aszisztens' },
                    { id: 'storekeeper', name: 'Raktáros' },
                    { id: 'worker', name: 'Szervízes' },
                ]} label={'employee.permission'} />
                <DeleteButton/>
            </Datagrid>
        </List>

    )
}

export default EmployeeList