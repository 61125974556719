import React from 'react';
import {
    TextInput,
    SimpleForm,
    required,
} from "react-admin";
import {Button} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const CustomerDetails = () => {
    return (
        <SimpleForm>
            <TextInput source="name" label={"customer.name"} validate={required()} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
            <TextInput source="zipCode" label={"customer.zipCode"} validate={required()} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
            <TextInput source="settlement" label={"customer.settlement"} validate={required()} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
            <TextInput source="address" label={"customer.address"} validate={required()} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
            <TextInput source="taxNumber" label={"customer.taxNumber"} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
            <TextInput source="discount" label={"customer.discount"} validate={required()} inputProps={{maxLength: 255}}  style = {{width: 400}}/>
        </SimpleForm>
    )
}

export default CustomerDetails