import {useParams} from "react-router";
import {
    DateField, ArrayField, FormDataConsumer,
    ImageField,
    List, Pagination,
    ReferenceField, required,
    Show,
    SimpleShowLayout,
    TextField, TextInput,
    useTranslate,
    Datagrid, FunctionField, useDataProvider, useNotify, useRecordContext
} from "react-admin";
import React, {useState} from "react";
import {Button} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";


const WorkflowShow = () => {
    const { id } = useParams(); // this component is rendered in the /books/:id path
    const record = useRecordContext();
    const translate = useTranslate()
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [file, setFile] = useState(undefined)
    const [isLoading, setLoading] = useState(false)

    const choices = [
        { id: 'R', name: 'workflow.category.replacement' },
        { id: 'F', name: 'workflow.category.fix' },
        { id: 'G', name: 'workflow.category.gluing'},
        { id: 'T', name: 'workflow.category.takeaway'},
        { id: 'D', name: 'workflow.category.delivery'},
    ];

    const categoryRender = (data) => {
        return translate(choices.find(choice => choice.id === data.category).name)
    }

    const bookingRender = () => (
        <span>
            <DateField source="bookingDate" />{' '}
            <TextField source="bookingTime" />
        </span>
    );

    function customExport() {
        dataProvider.get(`workflow/download/`+id)
            .then(response => {
                console.log("response: ", response)
                const contentType = "application/pdf"
                const base64Data = response.data.file
                const fileName = "Munkalap "+new Date().toISOString()+".pdf";
                const linkSource = `data:${contentType};base64,${base64Data}`
                setFile({file:linkSource, fileName: fileName})
                setLoading(false)
                let link = document.createElement('a');
                link.href = linkSource;
                link.download = fileName;
                link.click();
            })
            .catch(reason => {
                console.log(reason)
                notify("businessUnits.csrGenerateError", {type: "error"})
                setLoading(false)
            })

        //javascript goes here to retrieve data from server
    }

    const test = (event) =>  {
        //TODO onclick new tab image or modal
        console.log(event.target.currentSrc)
    }


    return (
        <Show>
            <SimpleShowLayout>
                <FunctionField source="bookingDate" label="Időpont" render={bookingRender} />
                <FunctionField source="category" label="workflow.categoryName" render={categoryRender}/>
                <TextField source="name" label="Név" />
                <TextField source="phone" label="Telefon"/>
                <TextField source="email" label="Email" />
                <TextField source="plateNumber" label="Rendszám"/>
                <TextField source="vin" label="Alvázszám"/>
                <TextField source="euroCode" label="Eurokód"/>
                <ReferenceField label={'product.vehicleManufacturer'}  reference={"product/vehicleManufacturer"} link={false} source={"vehicleManufacturerId"}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label={'product.vehicleModel'}  reference={"product/vehicleModel"} link={false} source={"vehicleModelId"}>
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="comment" label="Megjegyzés" multiline />
                <TextField source="odometer" label="KM óra állás" />
                <TextField source="damage" label="Sérülés"  fullWidth />
                <ImageField source="pictures" src="url" title="desc" label="Feltöltött képek" onClick={test} />
                <ArrayField source="products" label="Felhasznált termékek">
                    <Datagrid optimized={true} bulkActionButtons={false} >
                        <TextField source="id" label={'outgoing.id'} visibility={false}/>
                        <TextField source="name" label={'order.productName'}/>
                        <TextField source="euroCode" label={'product.euroCode'}/>
                        <TextField source="glassManufacturer" label={'product.glassManufacturer'}/>
                        <TextField source="vehicleManufacturer" label={'product.vehicleManufacturer'}/>
                        <TextField source="vehicleModel" label={'product.vehicleModel'}/>
                        <TextField source="price" label={'product.price'}/>
                        <TextField source="yearFrom" label={'product.yearFrom'}/>
                        <TextField source="yearTo" label={'product.yearTo'}/>
                        <TextField source="qty" label={'outgoing.qty'}/>
                        <TextField source="stand" label={'outgoing.stand'}/>
                        <TextField source="level" label={'outgoing.level'}/>
                        <TextField source="shelf" label={'outgoing.shelf'}/>
                    </Datagrid>
                </ArrayField>
                <TextField source="deliveryName" label="Kiszállítási név"/>
                <TextField source="deliveryPostcode" label="Kiszállítási írányítószám"/>
                <TextField source="deliverySettlement" label="Kiszállítási település"/>
                <TextField source="deliveryAddress" label="Kiszállítási cím"/>
                <Button startIcon={<FileDownloadIcon/>} onClick={() =>
                    customExport()}>Munkalap letöltés</Button>
            </SimpleShowLayout>
        </Show>
    )
}

export default WorkflowShow