import React from 'react';
import {Create} from 'react-admin';
import IncomingDetails from "./IncomingDetails";

const IncomingCreate = (props) => {
    return (
        <Create {...props} title={'incoming.create'}>
            <IncomingDetails />
        </Create>
    )
}

export default IncomingCreate