import hungarianMessages from 'ra-language-hungarian';
import {mergeDeep} from "../tools/mergeDeep";

const missingTranslations = {
    ra: {
        action: {
            confirm: 'Megerősítés',
            search: 'Keresés',
            next: 'Tovább',
            finish: 'Befejezés',
            edit: 'Szerkesztés'
        },
        page: {
            empty: 'Nincsenek még %{name}.',
            invite: 'Szeretne most hozzáadni egyet?',
        },
        navigation: {
            page_rows_per_page: 'Elemek száma oldalanként',
            skip_nav: "Navigáció elhagyása"
        },
        boolean: {
            null: ' '
        },
        configurable: {
            customize: 'Customize'
        }
    }
};
const data = {
    common: {
        columns: "Oszlopok",
        columnSelected: "oszlop kiválasztva"
    },
    partner : {
        title: "Partner",
        id: "ID",
        name: "Név",
        create: "Új partner hozzáadása",
        edit: "Partner szerkesztése",
        zipCode: "Irányító szám",
        settlement: "Település",
        address: "Cím",
        taxNumber: "Adószám",
        phone: "Telefon",
        email: "Email",
        discount: "Kedvezmény %",
        registeredBy: "Regisztrálta",
        webshopAccess: "Webshop hozzáférés",
        createdAt: "Létrehozva",
        partnerCode: "Partner kód",
        orderComment: "Megjegyzés"
    },
    customer : {
        title: "Törzsvásárló",
        id: "ID",
        name: "Név",
        create: "Új törzsvásárló hozzáadása",
        edit: "Törzsvásárló szerkesztése",
        zipCode: "Irányító szám",
        settlement: "Település",
        address: "Cím",
        taxNumber: "Adószám",
        phone: "Telefon",
        email: "Email",
        discount: "Kedvezmény % (termékre)",
        createdBy: "Regisztrálta",
        createdAt: "Létrehozva"
    },
    curiosity : {
        title: "Érdeklődő",
        id: "ID",
        offerNumber: "Ajánlatszám",
        name: "Név",
        postcode: "Irányítószám",
        settlement: "Település",
        address: "Cím",
        phone: "Telefon",
        email: "Email",
        vehicleManufacturer: "Jármű gyártó",
        vehicleModel: "Model",
        vehicleYear: "Évjárat",
        plateNumber: "Rendszám",
        vin: "Alvázszám",
        position: "Elhelyezkedés",
        service: "Szolgáltatás",
        location: "Helyszín",
        casco: "Casco",
        isCompany: "Céges",
        insurance: "Biztosító",
        create: "Érdeklődő hozzáadása",
        edit: "Érdeklődő szerkesztése",
        setOfferSentState: "Ajánlat elküldve státusz beállítva",
        setOfferSentStateError: "Státusz állítás sikertelen",
        createdBy: "Regisztrálta",
        createdAt: "Létrehozva"
    },

    product : {
        title: "Termékek",
        id: "ID",
        name: "Név",
        create: "Új termék hozzáadása",
        edit: "Termék szerkesztése",
        barCode: "Vonalkód",
        euroCode: "Eurokód",
        glassManufacturer: "Üveg gyártó",
        vehicleManufacturer: "Jármű gyártó",
        vehicleModel: "Model",
        manufacturerPrice: "Beszerzési ár",
        wholesalePrice: "Nagyker ár (nettó)",
        wholesaleDiscountPrice: "Kedvezményes nagyker ár (nettó)",
        price: "Kisker Ár (bruttó)",
        discountPrice: "Kedvezményes ár",
        productUnits: "Termék kiszerelései",
        yearTo: "Évig",
        yearFrom: "Évtől",
        quantity: "Mennyiség",
        unit: "Mértékegység",
        defaultPrice: "Alapértelmezett bruttó ár",
        greaterThanZero: "Az értéknek nagyobbnak kell lennie mint 0",
        extra: "Extra",
        vat: "Áfa",
        description: "Leírás",
        newGlassManufacturer: "Új üveg gyártó",
        newVehicleManufacturer: "Új jármű gyártó",
        newVehicleModel: "Új model",
        isStockable: "Készletezhető",
        type: "Típus",
        createdAt: "Időpont",
        category: "Kategória",
        sale: "Akció",
        note: "Megjegyzés"
    },
    site : {
        title: "Telephely",
        id: "ID",
        name: "Név",
        create: "Új telephely hozzáadása",
        edit: "Telephely szerkesztése",
        zipCode: "Irányító szám",
        settlement: "Település",
        address: "Cím",
        storage: "Raktár",
        active: "Aktív"
    },
    incoming : {
        title: "Bevételezés",
        id: "ID",
        name: "Név",
        create: "Új Bevételezés",
        edit: "Bevételezés szerkesztése",
        note: "Megjegyzés",
        categoryName: "Bevételezés típusa",
        category: {
            partner: "Partner",
            return: "Visszárú",
            storeCorrection: "Raktárkorrekció",
            other: "Egyéb"
        },
        incomingDate: "Bevételezés dátuma",
        orderDate: "Rendelés dátuma",
        createdAt: "Bizonylat dátuma",
        createdBy: "Készítette",
        qty: "Mennyiség",
        stand: "Állvány",
        level: "Szint",
        shelf: "Polc",
        modify: "Módosítás",
        cancel: "Mégse"
    },
    outgoing : {
        title: "Kivételezés",
        id: "ID",
        name: "Név",
        create: "Új kivételezés",
        edit: "Kivételezés szerkesztése",
        note: "Megjegyzés",
        categoryName: "Kivételezés típusa",
        category: {
            disposal: "Selejtezés",
            return: "Visszárú",
            storeCorrection: "Raktárkorrekció",
            other: "Egyéb"
        },
        incomingDate: "Kivételezés dátuma",
        orderDate: "Rendelés dátuma",
        createdAt: "Bizonylat dátuma",
        createdBy: "Készítette",
        qty: "Mennyiség",
        stand: "Állvány",
        level: "Szint",
        shelf: "Polc",
        modify: "Módosítás",
        cancel: "Mégse"
    },
    shuffle : {
        title: "Raktárközi mozgatás",
        id: "ID",
        name: "Név",
        create: "Új raktárközi mozgatás",
        note: "Megjegyzés",
        shuffleDate: "Bevételezés dátuma",
        createdBy: "Készítette",
        qty: "Mennyiség",
        stand: "Állvány",
        level: "Szint",
        shelf: "Polc",
        modify: "Módosítás",
        cancel: "Mégse"
    },
    inventory : {
        title: "Leltár",
        id: "ID",
        name: "Név",
        create: "Új kivételezés",
        edit: "Kivételezés szerkesztése",
        note: "Megjegyzés",
        categoryName: "Bevételezés típusa",
        category: {
            disposal: "Selejtezés",
            return: "Visszárú",
            storeCorrection: "Raktárkorrekció",
            other: "Egyéb"
        },
        incomingDate: "Bevételezés dátuma",
        orderDate: "Rendelés dátuma",
        createdAt: "Bizonylat dátuma",
        createdBy: "Készítette",
        qty: "Mennyiség",
        reservedQty: "Foglalt",
        stand: "Állvány",
        level: "Szint",
        shelf: "Polc"
    },
    storeRequest : {
        title: "Raktár kikérő",
        id: "ID",
        name: "Név",
        create: "Új kivételezés",
        edit: "Kivételezés szerkesztése",
        note: "Megjegyzés",
        categoryName: "Bevételezés típusa",
        category: {
            disposal: "Selejtezés",
            return: "Visszárú",
            storeCorrection: "Raktárkorrekció",
            other: "Egyéb"
        },
        incomingDate: "Bevételezés dátuma",
        orderDate: "Rendelés dátuma",
        createdAt: "Bizonylat dátuma",
        createdBy: "Készítette",
        qty: "Mennyiség",
        stand: "Állvány",
        level: "Szint",
        shelf: "Polc",
        comment: "Megjegyzés",
        deliveryDate: "Kiszállítási dátum"
    },
    search : {
        title: "Üveg kereső",
        id: "ID",
        euroCode: "Eurokód",
        partnerCode: "Partner kód",
        brand: "Jármű gyártó",
        model: "Model",
        endYear: "Évig",
        startYear: "Évtől",
        description: "Leírás",
        glass: "Üveg gyártó",
        price: "Kisker ár (bruttó)",
        wholesalePrice: "Nagyker ár (nettó)",
        wholesaleDiscountPrice: "Kedvezményes nagyker ár (nettó)",
        storage: "Raktár",
        stand: "Állvány",
        level: "Szint",
        shelf: "Polc",
        qty: "Mennyiség",
        reservedQty: "Foglalt",
        year: "Év",
        isStore: "Raktáron",
        type: "Típus",
        accessory: "Kiegészítő"
    },
    store: {
        title: "Raktár",
        add: "Új POS felvétele",
        name: "POS név",
        edit: "Módosítás",
        cancel: "Mégse"
    },
    sale: {
        title: "Értékesítés",
        create: "Új számla",
        name: "POS név",
        edit: "Módosítás",
        cancel: "Mégse",
        invoiceDownloadError: "Nem sikerült a számlát letölteni"
    },
    order: {
        title: "Rendelés",
        create: "Új rendelés",
        add: "Új rendelés",
        edit: "Módosítás",
        cancel: "Mégse",
        qty: "Mennyiség",
        total: "Össz. ár",
        productName: "Termék név",
        price: "Ár",
        state: "Rendelés állapota",
        createdAt: "Rendelés időpontja",
        addItemToOrder: "Termék hozzáadása a rendeléshez"
    },
    wsSearchLog: {
        title: "Webshop keresések",
        id: "ID",
        partnerName: "Partner",
        createdAt: "Időpont",
        euroCode: "Eurokód",
        brand: "Gyártó",
        model: "Model",
        type: "Típus",
        year: "Évjárat",
        isAccessory: "Kiegészítő",
        isStore: "Raktáron",
        isSale: "Akciós",
        resultCount: "Rekord szám"
    },
    workflow: {
        title: "Munkafolyamat",
        create: "Új Munkafolyat",
        edit: "Módosítás",
        cancel: "Mégse",
        onlyToday: "Csak a mai nap",
        saveAndPaid: "Fizetve",
        close: "Lezárás",
        categoryName: "Kategória",
        category: {
            replacement: "Beépítés",
            fix: "Javítás",
            gluing: "Újraragasztás",
            takeaway: "Elvitel",
            delivery: "Kiszállás"
        },
        statusName: "Státusz",
        status: {
            new: "Új",
            process: "Folyamatban",
            done: "Kész",
            paid: "Fizetve",
            close: "Lezárva"
        }
    },
    worksheet: {
        title: "Munkalap",
        create: "Új Munkafolyat",
        edit: "Módosítás",
        cancel: "Mégse",
        saveAndClose: "Mentés és munkalap lezárás",
        close: "Lezárás"
    },
    setting: {
        title: "Beállítások",
        add: "Új POS felvétele",
        name: "POS név",
        edit: "Módosítás",
        cancel: "Mégse"
    },
    employee: {
        title: "Munkatársak",
        add: "Új munkatárs felvétele",
        create: "Új munkatárs felvétele",
        edit: "Munkatárs szerkesztése",
        id: "ID",
        uuid: "UUID",
        name: "Munkatárs neve",
        email: "Munkatárs email címe",
        emailVerified: "Email cím ellenőrizve",
        permission: "Jog",
        cancel: "Mégse"
    }
}

const signUp  = {
    signUp : {
        login: "Bejelentkezés",
        forgotPassword: "Elfelejtett jelszó",
        changePassword: "Jelszó változtatás",
        registration: "Regisztráció",
        verification: "Ellenőrzés",
        email: "Email",
        password: "Jelszó",
        oldPassword: "Régi jelszó",
        newPassword: "Új jelszó",
        confirmPassword: "Új jelszó ismét",
        verificationCodeRequest: "Ellenőrző kód kérés",
        passwordChanged: "Sikeres jelszó változtatás",
        save: "Mentés",
        givenName: "Keresztnév",
        familyName: "Vezetéknév",
        phone: "Telefon",
        pin: "PIN",
        phoneHint: "Formátum: +xxxxxxxxxxx pl:+36201234567",
        pinHint: "Csak 4 darab számjegyet tartalmazhat",
        passwordHint: "Tartalmaznia kell legalább 1 kisbetűt, 1 nagybetűt, 1 számot és 1 speciális karaktert (^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ ` + = )",
        verificationCode: "Ellenörző kód",
        verificationCodeSent: "Ellenörző kód elküdve. A kódot az email fiókjában találja.",
        signIn: "Belépés",
        signUp: "Regisztráció",
        notAuthorizedException: "Rossz felhasználónév vagy jelszó.",
        passwordEmpty: "A jelszó nem lehet üres.",
        passwordPolicy: "A jelszó nem megfelelő. Min 8 hosszúnak kell lenni és tartalmaznia kell legalább 1 kisbetűt, 1 nagybetűt, 1 számot és 1 speciális karaktert(^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ ` + = ) ",
        accountExist: "Már létezik felhasználó ezzel az email címmel.",
        givenNameEmpty: "Nem lehet üres a keresztnév.",
        familyNameEmpty: "Nem lehet üres a vezetéknév.",
        phoneNotValid: "Telefonszám formátum nem megfelelő. Helyes formátum: +36xxxxxxxxx",
        pinEmpty: "PIN formátum nem megfelelő. Csak számokat tartamazhat.",
        invalidVerificationCode: "Hibás ellenörző kód, próbálja újra.",
        expiredVerificationCode: "Lejárt az ellenörző kód, próbálja újra.",
        expiredCodeException: "Lejárt az ellenörző kód, próbálja újra.",
        invalidCode: "Érvénytelen kódot adott meg, kérjen újra egy kódot.",
        limitExceededException: "Túllépte a kísérleti korlátot, próbálja meg egy idő után.",
        successfulRegistration: "Sikeres regisztráció. Ellenörző kódot az email fiókjában találja.",
        successfulVerification: "Ellenörzés sikeres. Most már be tud jelentkezni az oldalra."
    }
}

const hunMessages = mergeDeep(hungarianMessages, missingTranslations, data, signUp);


export default hunMessages;