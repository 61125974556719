import React from 'react';
import {
    TextField,
    List,
    DateField,
    ReferenceField,
    SimpleShowLayout,
    Show,
    Pagination,
    Datagrid as ReactAdminDataGrid,
    usePermissions,
    SelectField
} from "react-admin";
import {useParams} from "react-router";
import {EmptyPage} from "../../components/EmptyPage";
import {Typography} from "@mui/material";

const ProductShow = () => {
    const { id } = useParams();
    const { permissions } = usePermissions();

    const EmptyList = () => <Typography>Nem történt termék mozgás</Typography>;

    return (
        <Show>
            <SimpleShowLayout>
                <TextField source="id" label={'product.id'}/>
                <TextField source="name" label={'product.name'}/>
                <TextField source="barCode" label={'product.barCode'}/>
                <TextField source="euroCode" label={'product.euroCode'}/>
                <ReferenceField label={'product.glassManufacturer'}  reference={"product/glassManufacturer"} link={false} source={"glassManufacturerId"}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label={'product.vehicleManufacturer'}  reference={"product/vehicleManufacturer"} link={false} source={"vehicleManufacturerId"}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label={'product.vehicleModel'}  reference={"product/vehicleModel"} link={false} source={"vehicleModelId"}>
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="yearFrom" label={'product.yearFrom'}/>
                <TextField source="yearTo" label={'product.yearTo'}/>
                {permissions === 'admin' && <TextField source="manufacturerPrice" label={'product.manufacturerPrice'}/>}
                <TextField source="wholesalePrice" label={'product.wholesalePrice'}/>
                <TextField source="price" label={'product.price'}/>
                <TextField source="discountPrice" label={'product.discountPrice'}/>
                <TextField source="wholesaleDiscountPrice" label={'product.wholesaleDiscountPrice'}/>
                <TextField source="note" label={'product.note'}/>
                <Typography>Termék mozgás</Typography>
                <List perPage={200} pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />} resource={"product/changes/"+id} actions={false} empty={<EmptyList />}>
                    <ReactAdminDataGrid optimized={true} bulkActionButtons={false}>
                        <DateField source="createdAt" showTime label={'product.createdAt'} sortable={false}/>
                        <SelectField source="direction" label={'product.type'} choices={[
                            { id: 'I', name: 'Bevételezés' },
                            { id: 'O', name: 'Kivételezés' },
                            { id: 'SI', name: 'Raktárközi mozgatás (be)' },
                            { id: 'SO', name: 'Raktárközi mozgatás (ki)' },
                            { id: 'SA', name: 'Értékesítés' }
                        ]} sortable={false} />
                        <SelectField source="category" label={'product.category'} choices={[
                            { id: 'P', name: 'Partner' },
                            { id: 'R', name: 'Visszárú' },
                            { id: 'O', name: 'Egyéb' },
                            { id: 'D', name: 'Selejtezés' },
                            { id: 'S', name: 'Raktárkorrekció' }
                        ]} sortable={false} />
                        <TextField source="site" label={'site.title'} sortable={false}/>
                        <TextField source="storage" label={'site.storage'} sortable={false}/>
                        <TextField source="stand" label={'outgoing.stand'} sortable={false}/>
                        <TextField source="level" label={'outgoing.level'} sortable={false}/>
                        <TextField source="shelf" label={'outgoing.shelf'} sortable={false}/>
                        <TextField source="qty" label={'outgoing.qty'} sortable={false}/>
                        <ReferenceField label={'outgoing.createdBy'} reference={"employee/getAll"} link={false} source={"createdBy"} sortable={false}>
                            <TextField source="name"/>
                        </ReferenceField>
                    </ReactAdminDataGrid>
                </List>
            </SimpleShowLayout>
        </Show>
    )
}

export default ProductShow