import {useNavigate, useParams} from "react-router";
import {
    Create,
    DateInput, SaveButton,
    SimpleForm, TextInput,
    Title, Toolbar,
    useDataProvider,
    useGetOne,
    useNotify
} from "react-admin";
import { RichTextInput } from 'ra-input-rich-text';
import {useEffect, useState} from "react";
import SendIcon from '@mui/icons-material/Send';

const Proposal = (...props) => {
    const { id } = useParams();
    const notify = useNotify();
    const navigate = useNavigate();
    const dataProvider = useDataProvider();
    const [data, setData] = useState();
    //const [validUntil, setValidUntil] = useState(getValidUntilDate)
    const [proposalText, setProposalText] = useState()

    useEffect(() => {
        // Adatok lekérése az id alapján a dataProvider-rel
        dataProvider.getOne('b2ccuriosity', { id })
            .then(({ data }) => {
                console.log(data)
                setData(data);
                //setProposalText(`<p>teszt</p><p>másik sor</p><p>harmadik sor</p>`)
                setProposalText(`<h3>Tisztelt ${data?.customerName || ''}!</h3>
    
<p>Köszönjük megkeresését, mely alapján az alábbi árajánlatot nyújtjuk Önnek:</p>

<p>Típus: ${data?.vehicleManufacturer || ''} ${data?.vehicleModel || ''} <br/>Évjárat: ${data?.vehicleYear || ''} <br/>Szélvédő:   <br/>Extra:   </p>

<p>Az Ön által kért szélvédőcsere bruttó ...... Ft. </p>
<p>Az ár tartalmazza a ragasztó anyag és új gumi keret költségeit. A beszerelés nálunk DÍJTALAN!<br/>Előzetes bejelentkezés szükséges, amennyiben szeretne időpontot kérni kérem keresse ügyfélszolgálatunkat a +36 30 5814678-as telefonszámon.</p>
<p>Amennyiben elfogadja ajánlatunkat az alábbi linken tud időpontot foglalni hozzánk:</p><p><a target="_blank" rel="noopener noreferrer nofollow" href="http://glassmax.hu/booking/${id}">Időpontot foglalok</a></p>
<p>Üdvözlettel:<br/>A Glassmax csapata</p>
`)

            })
            .catch(error => {
                notify('Hiba történt az adatok lekérdezésekor!', { type: 'error' });
            });
    }, [id]);


    const getValidUntilDate = () => {
        const today = new Date();
        today.setDate(today.getDate() + 7); // 7 nappal hozzáadjuk az aktuális dátumhoz
        return today;
    };

    const [validUntil, setValidUntil] = useState(getValidUntilDate)

    const handleSubmit = async (data) => {
        try {
            await dataProvider.create('proposal', {
                data: {
                    id,
                    proposalText: data.proposalText,
                    validUntil: data.validUntil,
                }
            });
            notify('Ajánlat sikeresen elküldve!', { type: 'success' });
            navigate('/b2ccuriosity');
        } catch (error) {
            notify('Ajánlat küldése sikertelen!', { type: 'error' });
        }
    };

    const CustomToolbar = (props) => (
        <Toolbar {...props}>
            <SaveButton
                label="Mentés és küldés" // Itt megadjuk a szöveget
                icon={<SendIcon />}  // Itt megadjuk az egyedi ikont
            />
        </Toolbar>
    );

    return (
        <>
            {!proposalText ? (
                <div>Betöltés...</div> // Itt megjeleníthetsz egy betöltési üzenetet vagy egy spinnert
            ) : (
                <>
                    <Title title={`Ajánlat készítés ${data?.customerName || ''} részére`} />
                    <Create resource="proposal" title={<></>}>
                        <SimpleForm onSubmit={handleSubmit} toolbar={<CustomToolbar />}>
                            <RichTextInput source="proposalText" label="Ajánlat szövege" defaultValue={proposalText} />
                            <DateInput source="validUntil" label="Ajánlat érvényessége" defaultValue={validUntil} />
                        </SimpleForm>
                    </Create>
                </>
            )}
        </>
    );

}

export default Proposal