import React from 'react';
import {
    TextField,
    List,
    FunctionField,
    NumberField,
    DateField,
    SimpleShowLayout,
    useRedirect,
    Show,
    Pagination,
    Datagrid as ReactAdminDataGrid, Title, useRecordContext, BooleanField
} from "react-admin";
import {useParams} from "react-router";


const PostTitle = () => {
    const record = useRecordContext();
    // the record can be empty while loading
    if (!record) return null;
    return <span>Rendelés #{record.id}</span>;
};

const OrderShow = () => {
    const { id } = useParams(); // this component is rendered in the /books/:id path
    const redirect = useRedirect();

    const CustomCurrencyField = ({ qty, price }) => {
        const total = qty * price;
        const formattedCurrency = new Intl.NumberFormat("hu-HU", {
            style: 'currency',
            currency: 'HUF',
        }).format(total); // A currency az adattárolónkban található pénznem érték

        return <span>{formattedCurrency}</span>;
    };

    return (
       <Show title={<PostTitle/>}>
           <SimpleShowLayout>
               <TextField source="name" label={'partner.name'}/>
               <TextField source="state" label={'order.state'}/>
               <DateField source="createdAt" label="order.createdAt" showTime={true}/>
               <TextField source="zipCode" label={'partner.zipCode'}/>
               <TextField source="settlement" label={'partner.settlement'}/>
               <TextField source="address" label={'partner.address'}/>
               <TextField source="taxNumber" label={'partner.taxNumber'}/>
               <TextField source="orderComment" label={'partner.orderComment'}/>
           </SimpleShowLayout>
           <List perPage={200} pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />} resource={"order/item/"+id} actions={false} title={PostTitle} >
               <ReactAdminDataGrid optimized={true} bulkActionButtons={false} title="" >
                   <BooleanField source="sale" label={'product.sale'}/>
                   <TextField source="barcode" label={'product.barCode'}/>
                   <TextField source="euroCode" label={'product.euroCode'}/>
                   <TextField source="brand" label={'product.vehicleManufacturer'}/>
                   <TextField source="shortModel" label={'product.vehicleModel'}/>
                   <TextField source="glassManufacturerName" label={'product.glassManufacturer'}/>
                   <TextField source="productName" label={'product.name'}/>
                   <NumberField source="price" label={'product.wholesalePrice'} locales="hu-HU" options={{ style: 'currency', currency: 'HUF' }}/>
                   <TextField source="qty" label={'order.qty'}/>
                   <FunctionField label={'order.total'} render={record => <CustomCurrencyField qty={record.qty} price={record.price} />} />
               </ReactAdminDataGrid>
           </List>
           <List perPage={200} pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />} resource={"order/proglass-item/"+id} actions={false} title={PostTitle} >
               <ReactAdminDataGrid optimized={true} bulkActionButtons={false} title="" >
                   <BooleanField source="sale" label={'product.sale'}/>
                   <TextField source="barcode" label={'product.barCode'}/>
                   <TextField source="productCode" label={'product.productCode'}/>
                   <TextField source="nameHun" label={'product.name'}/>
                   <NumberField source="price" label={'product.wholesalePrice'} locales="hu-HU" options={{ style: 'currency', currency: 'HUF' }}/>
                   <TextField source="qty" label={'order.qty'}/>
                   <FunctionField label={'order.total'} render={record => <CustomCurrencyField qty={record.qty} price={record.price} />} />
               </ReactAdminDataGrid>
           </List>
           </Show>
    );
}

export default OrderShow