import React, {useState} from 'react';
import {
    TextField,
    BooleanInput,
    List,
    ListBase,
    Datagrid,
    ReferenceField,
    DateField, ShowButton, FunctionField, useTranslate, TextInput, AutocompleteArrayInput, SelectField
} from "react-admin";
import {Typography} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

const WorkflowList = () => {

    const [onlyToday, setOnlyToday] = useState(false)
    const translate = useTranslate()

    const postRowStyle1 = (record, index) => ({
        backgroundColor : record.status === 'NEW' ? '#fff' : record.status === 'PROCESS' ? '#ffd' : record.status === 'DONE' ? '#ddf' : record.status === 'PAID' ? '#dfd' : '#ddd'
    });

    const postRowStyle2 = (record, index) => ({
        backgroundColor : record.status === 'DONE' ? '#fff' : record.status === 'PAID' ? '#eee' : '#dff'
    });

    const onlyTodayChange = (e) => {
        setOnlyToday(e.value)
    }

    const choices = [
        { id: 'R', name: 'workflow.category.replacement' },
        { id: 'F', name: 'workflow.category.fix' },
        { id: 'G', name: 'workflow.category.gluing'},
        { id: 'T', name: 'workflow.category.takeaway'},
        { id: 'D', name: 'workflow.category.delivery'},
    ];

    const categoryRender = (data) => {
        return translate(choices.find(choice => choice.id === data.category).name)
    }

    const bookingRender = () => (
        <span>
            <DateField source="bookingDate" />{' '}
            <TextField source="bookingTime" />
        </span>
    );

    const [open, setOpen] = useState(false)
    const [deleteTagId, setDeleteTagId] = useState(null)
    const [selected, setSelected] = useState([])


    const status = [
        { id: 'NEW', name: 'Új' },
        { id: 'PROCESS', name: 'Folyamatban' },
        { id: 'DONE', name: 'Elkészült'},
        { id: 'PAID', name: 'Fizetve'},
        { id: 'CLOSE', name: 'Lezárva'}, //refactor -> CLOSED
    ];

    const handleDialogClose = toDelete => {
        setOpen(false)
        /*if (toDelete && deleteTagId != null) {
            dataProvider.delete(`warehouse-product/tag`, {id: deleteTagId})
                .finally(() => {
                    setTag({...tag, loaded: false})
                    refresh()
                });
        }*/
        setDeleteTagId(null)
    }

    const handleDialogOpen = () => {
        setOpen(true)
    }


    const handleDeleteButton = (removedId) => e => {
        handleDialogOpen()
        e.stopPropagation()
        setDeleteTagId(removedId)
    }

    const optionRenderer = choice => {
        return <span>
        {choice.name}
            {!(selected.some(e => e === choice.id)) /*&& (<ClearIcon className={"notDraggable"}
                                                                   onClick={handleDeleteButton(`${choice.id}`)}
                                                                   fontSize={"small"}
                                                                   sx={{
                                                                       marginBottom: "0px",
                                                                       color: "red",
                                                                       position: "absolute",
                                                                       right: "5px"
                                                                   }}
            />)*/}
    </span>
    }

    const matchSuggestion = (filter, choice) => {
        return (
            choice.name.toLowerCase().includes(filter.toLowerCase())
        );
    };




    const postFilters = [
        <BooleanInput source="onlyToday" label={'workflow.onlyToday'} alwaysOn />,
        <TextInput source="plateNumber" label={'Rendszám'} alwaysOn/>,
        <TextInput source="name" label={'product.name'} alwaysOn/>,
        <AutocompleteArrayInput label={'Státusz'} onChange={(e) => setSelected(e)} source="status"
                                choices={status} alwaysOn
            // sx={{width: "300px", position: "absolute", right: "10px", top: "80px"}}
                                optionText={optionRenderer} matchSuggestion={matchSuggestion}
        />,
    ];

    return (<>
            <Typography variant="h5" component="h5">
                Munkafolyamat
            </Typography>
        <List filters={postFilters}>
            <Datagrid rowClick={"edit"} bulkActionButtons={false} rowStyle={postRowStyle1}>
                <SelectField source="status" label='Státusz' choices={status} />
                <TextField source="id" label={'product.id'}/>
                <FunctionField source="bookingDate" label="Időpont" render={bookingRender}/>
                <FunctionField source="category" label="workflow.categoryName" render={categoryRender}/>
                <TextField source="name" label={'product.name'}/>
                <TextField source="plateNumber" label="Rendszám"/>
                <TextField source="vin" label="Alvázszám"/>
                <TextField source="phone" label="Telefon"/>
                <TextField source="email" label="Email"/>
                <TextField source="euroCode" label="Eurokód"/>
                <TextField source="vehicleManufacturer" label={'product.vehicleManufacturer'}/>
                <TextField source="vehicleModel" label={'product.vehicleModel'}/>
                <TextField source="vehicleYear" label="Évjárat"/>
                <TextField source="comment" label="Megjegyzés"/>
                <TextField source="odometer" label="KM óra állás"/>
                <TextField source="damage" label="Sérülés"/>
                <ShowButton/>
            </Datagrid>
        </List>
        </>
    )
}

export default WorkflowList