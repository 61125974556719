import React, {useState} from 'react';
import {
    TextInput,
    SimpleForm,
    DateInput,
    FormDataConsumer,
    required
} from "react-admin";
import {AutocompleteSimpleInput} from "../../components/AutocompleteSimpleInput";
import {Box, Grid } from "@mui/material";
import {ProductShuffleStoreList} from "../../components/ProductShuffleStoreList";

const ShuffleDetails = () => {

    const choices = [
        { id: 'R', name: 'outgoing.category.return' },
        { id: 'S', name: 'outgoing.category.storeCorrection' },
        { id: 'D', name: 'outgoing.category.disposal'},
        { id: 'O', name: 'outgoing.category.other' },
    ];

    return (
        <SimpleForm>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Box sx={{ width: '50%' }}>
                        <h2>Dátumok</h2>
                        <DateInput label="Bizonylat dátuma" source="createdAt" defaultValue={new Date().toISOString()} disabled={true} fullWidth />
                        <DateInput label="Mozgatás dátuma" source="incomingDate" defaultValue={new Date().toISOString().slice(0, 10)} validate={required()} fullWidth />
                        <TextInput source="note" multiline fullWidth label={"incoming.note"}/>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ width: '50%' }}>
                        <h2>Honnan</h2>
                        <FormDataConsumer>
                            {({ formData }) => (
                                <AutocompleteSimpleInput source={"siteIdFrom"} label={"site.title"}
                                                         multiple={false}
                                                         reference={'site'}
                                                         sortField={"name"}
                                                         optionText={"name"}
                                                         validate={required()}
                                                         disabled={formData.products != null && formData.products.length > 0}/>
                            )}
                        </FormDataConsumer>
                        <FormDataConsumer>
                            {({ formData }) => (
                                formData.siteIdFrom &&
                                <AutocompleteSimpleInput source={"storeIdFrom"} label={"store.title"}
                                                         multiple={false}
                                                         reference={'storage'}
                                                         sortField={"name"}
                                                         optionText={"name"} filter={{siteId : formData.siteIdFrom}}
                                                         validate={required()}
                                                         disabled={formData.products != null && formData.products.length > 0}/>
                            )}
                        </FormDataConsumer>
                        <h2>Hova</h2>
                        <FormDataConsumer>
                            {({ formData }) => (
                                <AutocompleteSimpleInput source={"siteIdTo"} label={"site.title"}
                                                         multiple={false}
                                                         reference={'site'}
                                                         sortField={"name"}
                                                         optionText={"name"}
                                                         validate={required()}
                                                         disabled={formData.products != null && formData.products.length > 0}/>
                                )}
                        </FormDataConsumer>
                        <FormDataConsumer>
                            {({ formData }) => (
                                formData.siteIdTo &&
                                <AutocompleteSimpleInput source={"storeIdTo"} label={"store.title"}
                                                         multiple={false}
                                                         reference={'storage'}
                                                         sortField={"name"}
                                                         optionText={"name"} filter={{siteId : formData.siteIdTo}}
                                                         validate={required()}
                                                         disabled={formData.products != null && formData.products.length > 0}/>
                            )}
                        </FormDataConsumer>
                    </Box>
                </Grid>
            </Grid>
            <h2>Termék hozzáadása a kivételezéshez</h2>
            <FormDataConsumer>
                {({ formData }) => (
                    formData.storeIdFrom && formData.storeIdTo &&
                    <ProductShuffleStoreList formData={formData} />
                )}
            </FormDataConsumer>
        </SimpleForm>
    )
}

export default ShuffleDetails