import React from 'react';
import {Edit} from "react-admin";
import EmployeeDetails from "./EmployeeDetails";

const EmployeeEdit = (props) => {
    return (
        <Edit {...props} title={'employee.edit'}>
            <EmployeeDetails />
        </Edit>
    )

}
export default EmployeeEdit