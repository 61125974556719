import React, {useState} from 'react';
import {
    useTranslate, ImageField, ImageInput, useDataProvider, useRefresh,
} from 'react-admin';
import { Button, Dialog, DialogContent, DialogTitle} from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import axios from "axios";

export const UploadImageToS3 = (formData) => {
    const translate = useTranslate()
    const dataProvider = useDataProvider();
    const refresh = useRefresh();

    const [open, setOpen] = useState(false);
    const [progress, setProgress] = useState(0);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [filePreviews, setFilePreviews] = useState([]); // Képek előnézete
    const [images, setImages] = useState([])

    const openDialog = () => {
        setProgress(0);
        setSelectedFiles([]);
        setOpen(true);
    };

    const handleClose = () => {
        setProgress(0);
        setSelectedFiles([]);
        setOpen(false)
        refresh()
    }

    const test = (event) =>  {
        //TODO onclick new tab image or modal
        console.log(event.target.currentSrc)
    }

    const handleFileSelect = (e) => {
        const files = Array.from(e.target.files);
        setSelectedFiles(files);
        // Generáljuk az előnézeti URL-eket
        const previews = files.map(file => URL.createObjectURL(file));
        setFilePreviews(previews);
    };

    const handleFileUpload = async (e) => {
        const totalFiles = selectedFiles.length;
        const totalSize = selectedFiles.reduce((acc, file) => acc + file.size, 0);
        let loadedSoFar = 0;
        //const totalSize = selectedFiles.reduce((acc, file) => acc + file.size, 0);
        console.log('totalsize: ', totalSize)
        let totalLoaded = 0;

        // Feltételezve, hogy van egy backend endpoint, ami előre aláírt URL-t ad
        for (const file of selectedFiles) {
            try {
                // Kérjünk egy előre aláírt URL-t a backendtől
                const response = await dataProvider.create('workflow/presigned-url', {data: {
                    fileName: formData.formData.id + '/' + file.name,
                    fileType: file.type
                }});
                const { url } = response.data.response;
                // Töltsük fel a fájlt az előre aláírt URL-re
                const s3response = await axios.put(url, file, {
                    onUploadProgress: (progressEvent) => {
                        const fileLoaded = progressEvent.loaded;

                        // Frissítsük az eddig feltöltött mennyiséget az összes fájlhoz képest
                        loadedSoFar += fileLoaded;
                        const overallProgress = Math.round((loadedSoFar / totalSize) * 100);
                        setProgress(overallProgress);
                        /*
                        2
                        totalLoaded += progressEvent.loaded;

                        // Százalékos haladás az összes fájlra
                        const overallProgress = Math.round((100 * totalLoaded) / (totalSize));
                        setProgress(overallProgress);*/

                        /*
                        1
                        const progress = Math.round(
                            (100 * progressEvent.loaded) / progressEvent.total
                        );
                        setProgress(progress);*/
                    },
                    headers: {
                        'Content-Type': file.type
                    }
                });
            } catch (error) {
                console.error("Kép feltöltési hiba", error);
            }
            console.log('totalLoaded: ',totalLoaded)
        }
        //handleClose()
    };

    return (
        <>
            <Button style={{marginBottom: "16px", color: "white"}} variant={"contained"} color={"primary"} onClick={openDialog}>
                <ImageIcon />  Képek feltöltése
            </Button>
            <Dialog open={open} fullWidth={true} scroll={"paper"} maxWidth={"xl"} onClose={handleClose} >
                <DialogTitle id="alert-dialog-title">
                    Fényképek feltöltése
                </DialogTitle>
                <DialogContent>
                    <input type="file" multiple accept="image/*" onChange={handleFileSelect} />
                    {/* Képek előnézete */}
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '20px' }}>
                        {filePreviews.map((preview, index) => (
                            <img
                                key={index}
                                src={preview}
                                alt={`preview-${index}`}
                                style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                            />
                        ))}
                    </div>
                    <Button
                        style={{ marginTop: "16px" }}
                        variant={"contained"}
                        color={"primary"}
                        onClick={handleFileUpload}
                    >
                        Fájlok feltöltése
                    </Button>
                    <div>Process: {progress}%</div>
                </DialogContent>
            </Dialog>
            <ImageField source="pictures" src="url" title="desc" label="Feltöltött képek" onClick={test} />
        </>
    );
};