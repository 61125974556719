import React from 'react';
import {Edit, SimpleForm, Toolbar, SaveButton, useRecordContext} from "react-admin";
import SaveIcon from '@mui/icons-material/Save';
import WorksheetDetails from "./WorksheetDetails";
import {useFormContext} from "react-hook-form";

const WorksheetEdit = (props) => {

    const AlwaysEnableToolbar = () => {
        let record = useRecordContext()
        if(!record){
            record = {}
        }
        const { setValue } = useFormContext()
        const handleSaveClick = (e) => {
            setValue("status", "PROCESS")
        }
        const handleSaveAndDoneClick = (e) => {
            setValue("status", "DONE")
        }
        const handleSaveAndCloseClick = (e) => {
            setValue("status", "CLOSE")
        }

        return (
            <Toolbar {...props} >
                <>
                    <SaveButton label={"ra.action.save"} icon={<SaveIcon />} variant={"contained"} color={"primary"} type={"button"} onClick={handleSaveClick}
                                    alwaysEnable={true}/>
                    {(record.status === "PROCESS") ?
                        <SaveButton variant={"outlined"} sx={{marginLeft: '8px'}} label={'worksheet.saveAndClose'} onClick={handleSaveAndDoneClick}
                                    alwaysEnable={true}></SaveButton>
                        :
                        null
                    }
                    {(record.status === "PAID") ?
                    <SaveButton variant={"outlined"} sx={{marginLeft: '8px'}} label={'worksheet.close'} onClick={handleSaveAndCloseClick}
                                alwaysEnable={true}></SaveButton>
                    :
                    null
                }
                </>
            </Toolbar>
        );
    }


    return (
        <Edit {...props} title={'workflow.edit'} redirect="list">
            <SimpleForm toolbar={<AlwaysEnableToolbar />}>
                <WorksheetDetails />
            </SimpleForm>
        </Edit>
    )

}
export default WorksheetEdit