import React, {useState} from 'react';
import {
    TextInput,
    SimpleForm,
    DateInput,
    RadioButtonGroupInput,
    FormDataConsumer,
    required, BooleanInput
} from "react-admin";
import {AutocompleteSimpleInput} from "../../components/AutocompleteSimpleInput";
import {Box, Grid } from "@mui/material";
import {ProductToStoreList} from "../../components/ProductToStoreList";

const IncomingDetails = () => {

    const choices = [
        { id: 'P', name: 'incoming.category.partner' },
        { id: 'R', name: 'incoming.category.return' },
        { id: 'S', name: 'incoming.category.storeCorrection' },
        { id: 'O', name: 'incoming.category.other' },
    ];

    return (
        <SimpleForm>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Box sx={{ width: '50%' }}>
                        <h2>Szállítói adatok</h2>
                        <RadioButtonGroupInput source="category" choices={choices} row={false} label={"incoming.categoryName"} validate={required()} />
                        <TextInput source="note" multiline fullWidth label={"incoming.note"}/>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ width: '50%' }}>
                        <h2>Dátumok</h2>
                        <DateInput label="Bizonylat dátuma" source="createdAt" defaultValue={new Date().toISOString()} disabled={true} fullWidth />
                        <DateInput label="Bevételezés dátuma" source="incomingDate" defaultValue={new Date().toISOString().slice(0, 10)} validate={required()} fullWidth />
                        <DateInput label="Megrendelés dátuma" source="orderDate" fullWidth />
                        <h2>Termék helye</h2>
                        <FormDataConsumer>
                            {({ formData }) => (
                                <AutocompleteSimpleInput source={"siteId"} label={"site.title"}
                                                         multiple={false}
                                                         reference={'site'}
                                                         sortField={"name"}
                                                         optionText={"name"}
                                                         validate={required()}
                                                         disabled={formData.products != null && formData.products.length > 0}/>
                            )}
                        </FormDataConsumer>
                        <FormDataConsumer>
                            {({ formData }) => (
                                formData.siteId &&
                                <AutocompleteSimpleInput source={"storeId"} label={"store.title"}
                                                         multiple={false}
                                                         reference={'storage'}
                                                         sortField={"name"}
                                                         optionText={"name"} filter={{siteId : formData.siteId}}
                                                         validate={required()}
                                                         disabled={formData.products != null && formData.products.length > 0}/>
                            )}
                        </FormDataConsumer>
                    </Box>
                </Grid>
            </Grid>
            <h2>Termék hozzáadása a bevételezéshez</h2>
            <FormDataConsumer>
                {({ formData }) => (
                    <ProductToStoreList formData={formData} />
                )
                }
            </FormDataConsumer>
        </SimpleForm>
    )
}

export default IncomingDetails